import React, { useState } from 'react';

import ListAdmin from '../../shared/components/admin/ListAdmin';

const Admin = () => {
  return (
    <div>
      <ListAdmin />
    </div>
  );
};

export default Admin;
