import React, { useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { middlewareSettings } from '../../../services/middlewares/middlewareSettings';

import DeleteIcon from '../../../assets/images/svg/delete-icon.svg';

const EditMaterialSettings = () => {
  //init store
  const setActivity = useStoreActions((action) => action.setActivity);

  const currentMaterial = useLocation().state;
  const newReference = currentMaterial.references[0].reference.split(',');
  const [values, setValues] = useState(newReference);
  const [material, setMaterial] = useState(currentMaterial.name);
  const navigate = useNavigate();

  // add input
  const handleAdd = () => {
    const allValues = [...values, []];
    setValues(allValues);
  };

  // change value on input
  const handleChange = (onChangeValue, index) => {
    const inputdata = [...values];
    inputdata[index] = onChangeValue.target.value;
    setValues(inputdata);
  };

  // delete input
  const handleDelete = (index) => {
    const deleteValues = [...values];
    deleteValues.splice(index, 1);
    setValues(deleteValues);
  };

  // form on back
  const handleSubmit = (e) => {
    e.preventDefault();
    setActivity(true);
    middlewareSettings.updateMaterial({ id: currentMaterial.id, material, values }).then((res) => {
      if (res.statut) {
        navigate('/settings/purchase-order');
      }
      setActivity(false);
    });
  };
  return (
    <section className="">
      <div className="pt-11 pb-4 border-b border-white-admin mb-3 mx-14">
        <h3 className="font-semibold">Modifier un matériel</h3>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex mx-14 space-x-4 items-center border-b border--white-admin py-5">
          <label htmlFor="name">Nom du matériel</label>
          <input
            className="w-[318px] py-3 pl-5 border border-white-admin rounded-lg text-sm input"
            type="text"
            placeholder="Nom du matériel"
            onChange={(e) => setMaterial(e.target.value)}
            value={material}
          />
        </div>
        <div className="mx-14">
          <p className="pt-7">Références</p>

          {values.map((value, index) => {
            return (
              <label
                key={index}
                className="inline-flex relative items-center cursor-pointer pt-7 pl-2"
              >
                <input
                  type="checkbox"
                  value=""
                  className="sr-only peer"
                />
                <div className="w-11 h-5 peer-focus:outline-none  rounded-full peer dark:bg-orange-clim peer-checked:after:translate-x-6  after:content-[''] after:absolute after:top-[45px] after:left-[10px] after:bg-white  after:border after:rounded-full after:h-4 after:w-4 after:transition-all  peer-checked:bg-blue-admin"></div>
                <div className="ml-4 flex">
                  <input
                    className="border border-white-admin w-[318px] py-3 rounded-xl pl-4 input"
                    placeholder="Référence"
                    type="text"
                    value={value}
                    onChange={(e) => handleChange(e, index)}
                    required
                  />
                  <button
                    className=" py-4 px-4 border border-white-admin p-2 rounded-xl ml-3"
                    onClick={() => handleDelete(index)}
                  >
                    <img
                      src={DeleteIcon}
                      alt="delete icon"
                    />
                  </button>
                </div>
              </label>
            );
          })}
        </div>
        <button
          onClick={() => handleAdd()}
          className="text-blue-admin text-sm mx-14 underline underline-offset-1 pt-4"
        >
          + Ajouter une option
        </button>
        <div className="mt-20 pt-4 flex justify-between items-center border-t border-white-admin">
          <Link
            to="/settings/purchase-order"
            className="cursor-pointer ml-14 border border-white-admin rounded-lg"
          >
            <button className="py-3 px-5 text-blue-admin">Annuler</button>
          </Link>
          <div className=" cursor-pointer mr-14 bg-blue-admin rounded-lg">
            <button
              type="submit"
              className="py-3 px-5 text-white font-semibold"
            >
              Enregister les Modifications
            </button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default EditMaterialSettings;
