import React from 'react';
import { Link } from 'react-router-dom';

// import svg icon
import AdminIcon from '../../assets/images/svg/admin.svg';
import CollaboratorsIcon from '../../assets/images/svg/collaborators.svg';
import WorksiteIcon from '../../assets/images/svg/worksite.svg';
import SuppliersIcon from '../../assets/images/svg/suppliers.svg';
import Settings from '../../assets/images/svg/settings.svg';

const DashboardHome = () => {
  return (
    <section className="pl-16 pt-12 h-full">
      {/* div main */}
      <div>
        {/* div title + paragraph */}
        <div className="max-w-[373px]">
          <h1 className="pb-8">Bienvenue sur l’admin Sacarabany SARL</h1>
          <h3 className="pb-12">Veuillez sélectionner une catégorie</h3>
        </div>
        {/* div navigation */}
        <div>
          <ul className="flex items-center space-x-9">
            <li>
              <Link
                to="admin"
                className="home-btn-hover"
              >
                <div className=" p-2 rounded-md">
                  <img
                    src={AdminIcon}
                    alt="admin icon"
                  />
                </div>
                <p className="text-blue-admin font-semibold">Administrateurs</p>
              </Link>
            </li>
            <li>
              <Link
                to="collaborators"
                className="home-btn-hover"
              >
                <div className="p-2 rounded-md">
                  <img
                    src={CollaboratorsIcon}
                    alt="collaborators icon"
                  />
                </div>
                <p className="text-blue-admin font-semibold">Collaborateurs</p>
              </Link>
            </li>
            <li>
              <Link
                to="customers"
                className="home-btn-hover"
              >
                <div className=" p-2 rounded-md">
                  <img
                    src={WorksiteIcon}
                    alt="worksite icon"
                  />
                </div>
                <p className="text-blue-admin font-semibold">Clients et chantiers</p>
              </Link>
            </li>
          </ul>
          <ul className="flex items-center space-x-9 pt-7">
            <li>
              <Link
                to="suppliers"
                className="home-btn-hover"
              >
                <div className=" p-2 rounded-md">
                  <img
                    src={SuppliersIcon}
                    alt="suppliers icon"
                  />
                </div>
                <p className="text-blue-admin font-semibold"> Fournisseurs</p>
              </Link>
            </li>
            <li>
              <Link
                to="/settings/purchase-order"
                className="home-btn-hover"
              >
                <div className=" p-2 rounded-md">
                  <img
                    src={Settings}
                    alt="settings icon"
                  />
                </div>
                <p className="text-blue-admin font-semibold">Paramètres</p>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default DashboardHome;
