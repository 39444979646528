import axios from 'axios';

const urlGraphQL = process.env.REACT_APP_URL_GRAPH_QL;

const options = {
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_API_KEY,
  },
};

const user = {
  getUser: async (id) => {
    const body = {
      query: `
            query MyQuery {

        getUser(id: "${id}") {

          first_name

          id

          mail

          role_user {

            id

            name

          }

          last_name

        }

      }   `,
    };
    try {
      const request = await axios.post(urlGraphQL, body, options);
      return { statut: true, data: request.data.data.getUser };
    } catch (error) {
      return { statut: false, error };
    }
  },
};

export default user;
