import axios from 'axios';

const urlGraphQL = process.env.REACT_APP_URL_GRAPH_QL;

const options = {
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_API_KEY,
  },
};

const MiddlewareClassifyOrder = {
  getListOrderCoupon: async () => {
    const body = {
      query: `query MyQuery {
        listOrderCoupons(filter: {classified: {eq: false}})
      {
        items 
        {
        classified
        id
        name
        nameClient
        nameWorksite
        numero
        orderCouponCollaboratorId
        signed
        updatedAt
        createdAt
        collaborator 
        {
            type {
                name
            }
        }
    }
}
}

`,
    };
    try {
      const request = await axios.post(urlGraphQL, body, options);
      return {
        statut: true,
        data: request.data.data.listOrderCoupons.items,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  getListInterventionCoupon: async () => {
    const body = {
      query: `query MyQuery {
  listInterventionCoupons(filter: {classified: {eq: false}}) {
    items {
      createdAt
      classified
      id
      ctm
      bcCtm
      interventionCouponOrderCouponId
      interventionCouponUserId
      nameClient
      name
      nameWorksite
      numero
      orderCoupon {
        collaborator {
          type {
            name
          }
        }
      }
    }
  }
}

`,
    };
    try {
      const request = await axios.post(urlGraphQL, body, options);
      return {
        statut: true,
        data: request.data.data.listInterventionCoupons.items,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  deleteOrderCoupon: async (id) => {
    const body = {
      query: `mutation MyMutation {
  deleteOrderCoupon(input: {id: "${id}"}, condition: {classified: {eq: false}}) {
    id
  } }

`,
    };
    try {
      const request = await axios.post(urlGraphQL, body, options);
      return {
        statut: true,
        data: request.data.data.deleteOrderCoupon,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  deleteInterventionCoupon: async (id) => {
    const body = {
      query: `mutation MyMutation {
  deleteInterventionCoupon(input: {id: "${id}"}, condition: {classified: {eq: false}}) {
    id
  } }

`,
    };
    try {
      const request = await axios.post(urlGraphQL, body, options);
      return {
        statut: true,
        data: request.data.data.deleteInterventionCoupon,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  moveOrderCouponToWorksite: async (id, idWorksite) => {
    const body = {
      query: `mutation MyMutation {
        updateOrderCoupon(input: 
          {id: "${id}", 
          worksiteOrder_couponId: "${idWorksite}", 
          classified: ${true}}) {
            id
          }
}
`,
    };
    try {
      const request = await axios.post(urlGraphQL, body, options);
      return {
        statut: true,
        data: request.data.data.updateOrderCoupon,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  moveInterventionCouponToWorksite: async (id, idWorksite) => {
    const body = {
      query: `mutation MyMutation {
  updateInterventionCoupon(input: 
    {id: "${id}", 
    worksiteIntervention_couponId: "${idWorksite}", 
    classified: true}) {
    id
  }
}
`,
    };
    try {
      const request = await axios.post(urlGraphQL, body, options);
      return {
        statut: true,
        data: request.data.data.updateInterventionCoupon,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  getListWorksitesClassify: async () => {
    const body = {
      query: `query MyQuery {
        listWorksites {
          items {
            id
            name
          }
        }
}

`,
    };
    try {
      const request = await axios.post(urlGraphQL, body, options);
      return {
        statut: true,
        data: request.data.data.listWorksites.items,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  getListCompanyClassify: async () => {
    const body = {
      query: `
      query MyQuery {
        listTypeCompanies(filter: {name: {eq: "client"}}) {
          items {
            id
            name
            companies {
              items {
                id
                name
                worksites {
                  items {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
      
    `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);
      return {
        statut: true,
        data: request.data.data.listTypeCompanies.items[0].companies.items,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },
};

export { MiddlewareClassifyOrder };
