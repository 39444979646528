import AWS from 'aws-sdk';

const configIam = {
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
};
const poolIdCollaborator = process.env.REACT_APP_POOL_ID_COLLABORATOR;
const poolIdAdmin = process.env.REACT_APP_POOL_ID_ADMIN;

const cognitoServices = {
  // func set password user cognito
  // si pas admin il passe à collaborateur id
  setUserPassword: (prop, admin = false) => {
    //config key
    const awsCognito = new AWS.CognitoIdentityServiceProvider(configIam);

    // props func Aws
    const data = {
      Password: prop.password,
      Permanent: true,
      Username: prop.id,
      UserPoolId: admin ? poolIdAdmin : poolIdCollaborator,
    };

    // exe aws function
    const myPromesse = new Promise((resolve, reject) => {
      awsCognito.adminSetUserPassword(data, (err) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });

    return myPromesse;
  },

  // func set username Attribute cognito
  setUsername: (prop, admin = false) => {
    //config key
    const awsCognito = new AWS.CognitoIdentityServiceProvider(configIam);

    // props func Aws
    const data = {
      UserAttributes: [
        {
          Name: 'preferred_username',
          Value: prop.fullName,
        },
      ],
      UserPoolId: admin ? poolIdAdmin : poolIdCollaborator,
      Username: prop.id,
    };

    // exe aws function
    const myPromesse = new Promise((resolve, reject) => {
      awsCognito.adminUpdateUserAttributes(data, (err) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });

    return myPromesse;
  },

  // func set username Attribute cognito
  setMail: (prop, admin = false) => {
    //config key
    const awsCognito = new AWS.CognitoIdentityServiceProvider(configIam);

    // props func Aws
    const data = {
      UserAttributes: [
        {
          Name: 'email',
          Value: prop.mail,
        },
      ],
      UserPoolId: admin ? poolIdAdmin : poolIdCollaborator,
      Username: prop.id,
    };

    // exe aws function
    const myPromesse = new Promise((resolve, reject) => {
      awsCognito.adminUpdateUserAttributes(data, (err) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });

    return myPromesse;
  },

  // func delete cognito user
  deleteUser: (idUser, admin = false) => {
    //config key
    const awsCognito = new AWS.CognitoIdentityServiceProvider(configIam);

    // props func Aws
    const data = {
      Username: idUser,
      UserPoolId: admin ? poolIdAdmin : poolIdCollaborator,
    };

    // exe aws function
    const myPromesse = new Promise((resolve, reject) => {
      awsCognito.adminDeleteUser(data, (err) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });

    return myPromesse;
  },
};

export default cognitoServices;
