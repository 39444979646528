import React from 'react';
import { useStoreState } from 'easy-peasy';
import { useNavigate } from 'react-router-dom';
import { accountService } from '../../services/account_service';
import Breadcrumbs from './Breadcrumbs';

const Header = () => {
  //global store
  const currentUser = useStoreState((state) => state.currentUser);

  let navigate = useNavigate();

  const logout = () => {
    accountService.logout();
    navigate('/login');
  };

  return (
    <section className="border-b border-gray-separate">
      <div className="flex justify-between pt-10 pr-6 pl-10 pb-2 ">
        <div>
          <h2>Bonjour {currentUser?.first_name}</h2>
        </div>
        <button
          onClick={logout}
          className="text-blue-admin font-medium py-2 px-4 border border-gray-separate rounded-lg"
        >
          Déconnexion
        </button>
      </div>
      <div className="pl-10 pb-5">
        <Breadcrumbs />
      </div>
    </section>
  );
};

export default Header;
