import { createStore, action } from 'easy-peasy';
// Pour créer un store
// create store est un objet
// roleUsers correspond à la donnée
// setRoleUsers c'est une fonction qui permet de set todo
// payload c'est donnée que l'on reçoit
// action c'est le setRoleUsers

const store = createStore({
  //RoleUsers correspond à la liste des rôles avec leur id et nom de la base de données
  roleUsers: [],
  // C'est la fonction qui permet d'assigner des données à RoleUsers
  setRoleUsers: action((state, payload) => {
    state.roleUsers = payload;
  }),

  //Categories corespond à la liste des catégories avec leur id et nom de la base de données
  categories: [],
  setCategories: action((state, payload) => {
    state.categories = payload;
  }),

  //Type correspond à la liste des types avec leur id et nom de la base de données
  typeCompanies: [],
  setTypeCompanies: action((state, payload) => {
    state.typeCompanies = payload;
  }),

  currentUser: null,
  setcurrentUser: action((state, payload) => {
    state.currentUser = payload;
  }),

  activity: false,
  setActivity: action((state, payload) => {
    state.activity = payload;
  }),
});

export default store;
