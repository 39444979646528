import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import middlewareCoupon from '../../../services/middlewares/middlewareCoupon';
import middlewareCouponPreInter from '../../../services/middlewares/middlewareCouponPreInter';

// import icons
import DeleteIcon from '../../../assets/images/svg/delete-icon.svg';
import VectorIcon from '../../../assets/images/svg/vector.svg';

// import tool
import { filterMaterialData } from '../../tools/manager';
import Modal_2 from '../../components/Modal_2';

//model data Material
const refMaterial = {
  // ---------------
  idMaterial: '',
  nature: '',
  material: '',
  refName: '',
  quantity: '',
  pose: '',
  depot: '',
  surface: '',
  statut: false,
};

const PrefillOrder = () => {
  // navigate
  let navigate = useNavigate();
  const { idCustomer, idWorksite } = useParams();

  // init store
  const setActivity = useStoreActions((action) => action.setActivity);

  // init state
  const [customer, setCustomer] = useState();
  const [typeInterventions, setTypeInterventions] = useState([]);
  const [bcCtm, setBcCtm] = useState('');
  const [listeMaterialCreate, setListeMaterialCreate] = useState([refMaterial]);
  const [formError, setformError] = useState('');
  const [materials, setMaterials] = useState([]);
  const [referencesMaterials, setReferencesMaterials] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [numeroBon, setnumeroBon] = useState(null);

  // call list nature intervention
  const callListInterventionSettings = () => {
    middlewareCoupon.getListInterventionSettings().then((result) => {
      if (result.statut) {
        setTypeInterventions(result.data.items);
      } else {
        console.log('Error: ', result.error);
        window.location.href = '/customers';
      }
    });
  };

  // call list material and ref
  const callListMaterialSettings = () => {
    middlewareCoupon.getListMaterialSettings().then((result) => {
      if (result.statut) {
        const data = result.data.items.filter((item) => item.name !== 'Climatiseur');
        setReferencesMaterials(data);
        setMaterials(data);
      } else {
        console.log('Error: ', result.error);
        window.location.href = '/customers';
      }
    });
  };

  // call customer and data worksite in coupon
  const callGetCompagny = () => {
    setActivity(true);
    middlewareCoupon.getCompagny(idCustomer).then((result) => {
      if (result.statut) {
        setCustomer(result.data);
      } else {
        console.log('Error: ', result.error);
        window.location.href = '/customers';
      }
      setActivity(false);
    });
  };

  // call last number coupon
  const CallLastNumberCoupon = async (data) => {
    setActivity(true);
    try {
      const result = await middlewareCouponPreInter.lastNumberPreInterv();
      const newData = { ...data, numero: result };
      setnumeroBon(result);
      callCreatePreInterventionCoupon(newData);
    } catch (error) {
      toast.error('❌ #1 Une erreur est survenue lors de la création du bon');
    } finally {
      setActivity(false);
    }
  };

  //call create pre intervention coupon
  const callCreatePreInterventionCoupon = async (data) => {
    setActivity(true);
    try {
      await middlewareCouponPreInter.createPreInterv(data);
      setshowModal(true);
    } catch (error) {
      toast.error('❌ #2 Une erreur est survenue lors de la création du bon');
    } finally {
      setActivity(false);
    }
  };

  //add line Material form
  const addMaterial = (e) => {
    e.preventDefault();
    setListeMaterialCreate([...listeMaterialCreate, refMaterial]);
  };

  //set value Material
  const changeValueMaterial = (index, key, value) => {
    let tempData = JSON.parse(JSON.stringify(listeMaterialCreate));

    tempData[index][key] = value;
    if (key === 'material') {
      tempData[index].idMaterial = referencesMaterials.find((el) => el.name === value).id;
      tempData[index].refName = '';
    }
    setListeMaterialCreate(tempData);
  };

  // Delete line Material
  const deleteMaterial = (e, index) => {
    e.preventDefault();
    const temps = JSON.parse(JSON.stringify(listeMaterialCreate));
    temps.splice(index, 1);
    setListeMaterialCreate(temps);
  };

  // Verif is all form not empty
  const isOkfrom = () => {
    if (!bcCtm) {
      setformError('Entrer un numéro de bon CTM');
      return false;
    }
    if (!listeMaterialCreate.length) {
      setformError('Ajouter une intervention!');
      return false;
    }
    for (const element of listeMaterialCreate) {
      if (!element.nature) {
        setformError("Défaut d'une nature d'intervention");
        return false;
      }
      if (!element.material) {
        setformError("Défaut d'un nom de matériel");
        return false;
      }
      if (!element.refName) {
        setformError("Défaut d'une référence");
        return false;
      }
      if (element.quantity == '' || element.quantity < 0) {
        setformError("Défaut d'une quantité");
        return false;
      }
    }
    return true;
  };

  // Action btn save Pre intervention
  const submit = (e) => {
    e.preventDefault();
    const newdata = {
      ctm: bcCtm,
      couponPreInterWorksitesId: idWorksite,
      materials: filterMaterialData(listeMaterialCreate),
    };

    if (isOkfrom()) {
      CallLastNumberCoupon(newdata);
    }
  };

  // return list order
  const goBack = () => {
    setshowModal(false);
    navigate(-1);
  };

  // Effect disable msg error form
  useEffect(() => {
    setformError('');
  }, [bcCtm, listeMaterialCreate]);

  // First effect
  useEffect(() => {
    callGetCompagny();
    callListInterventionSettings();
    callListMaterialSettings();
  }, []);

  //Render
  return (
    <section className="pt-14">
      <form>
        {/* fisrt div form */}
        <div className="flex space-x-8 mx-14">
          <div className="flex flex-col space-y-4">
            <label htmlFor="client">Nom du client</label>
            <p className="w-[174px] rounded-lg placeholder:text-blue-admin py-4 pl-5 border border-white-admin">
              {customer?.name}
            </p>
          </div>
          <div className="flex flex-col space-y-4">
            <label htmlFor="client">*BC CTM</label>
            <input
              className="w-[214px] rounded-lg placeholder:text-blue-admin py-4 border border-white-admin input"
              type="text"
              placeholder="N°"
              onInput={(e) => setBcCtm(e.target.value)}
            />
          </div>
        </div>
        <div className="mt-10 mx-14 overflow-x-auto">
          <h5 className="font-semibold mb-7">Etat des lieux de l'intervention</h5>
          {!!listeMaterialCreate.length && (
            <table>
              <thead>
                <tr>
                  <th>*Nature de l'intervention</th>
                  <th>*Nom du matériel</th>
                  <th>*Référence</th>
                  <th>Surface</th>
                  <th>Lieu de pose</th>
                  <th>Lieu dépôt</th>
                  <th>*Quantité</th>
                </tr>
              </thead>
              <tbody>
                {listeMaterialCreate.map((material, index) => {
                  const idMaterial = listeMaterialCreate[index].idMaterial;
                  const reference = referencesMaterials
                    .find((el) => el.id === idMaterial)
                    ?.references[0]?.reference?.split(',');
                  return (
                    <tr key={index}>
                      <td className="border border-white border-r-8">
                        <select
                          className="mt-3 w-[240px] rounded-lg placeholder:text-blue-admin py-4 border border-white-admin text-blue-admin border-none inputFocus bg-blue-dropdown"
                          type="text"
                          onChange={(e) => changeValueMaterial(index, 'nature', e.target.value)}
                          value={listeMaterialCreate[index].nature}
                        >
                          <option
                            value=""
                            disabled
                          >
                            - Nature de l'intervention -
                          </option>
                          {typeInterventions?.map((typeIntervention) => {
                            return (
                              <option
                                key={typeIntervention.id}
                                value={typeIntervention.name}
                              >
                                {typeIntervention.name}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                      <td className="border border-white border-x-8 ">
                        <select
                          className="rounded-lg py-4 border border-white-admin text-blue-admin input mt-3 w-[234px]"
                          type="text"
                          placeholder="-"
                          value={listeMaterialCreate[index]?.material}
                          onInput={(e) => changeValueMaterial(index, 'material', e.target.value)}
                        >
                          <option
                            disabled
                            value=""
                          >
                            {' '}
                            - Nom du matériel -{' '}
                          </option>
                          {materials?.map((material) => {
                            return (
                              <option
                                key={material.id}
                                value={material.name}
                              >
                                {material.name}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                      <td className="border border-white border-x-8">
                        <select
                          className="rounded-lg py-4 border border-white-admin text-blue-admin input mt-3 w-[234px]"
                          type="text"
                          placeholder="-"
                          value={listeMaterialCreate[index]?.refName}
                          onInput={(e) => changeValueMaterial(index, 'refName', e.target.value)}
                        >
                          <option
                            disabled
                            value={''}
                          >
                            -
                          </option>
                          {reference?.map((referenceMaterial, index) => {
                            return (
                              <option
                                key={index}
                                value={referenceMaterial}
                              >
                                {referenceMaterial}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                      <td className="border border-x-8 border-white">
                        <input
                          className="rounded-lg py-4 border border-white-admin input mt-3"
                          type="number"
                          placeholder="-"
                          value={listeMaterialCreate[index]?.surface}
                          onInput={(e) => changeValueMaterial(index, 'surface', e.target.value)}
                        />
                      </td>
                      <td className="border border-x-8 border-white">
                        <input
                          className="rounded-lg py-4 border border-white-admin input mt-3"
                          type="text"
                          placeholder="-"
                          value={listeMaterialCreate[index]?.pose}
                          onInput={(e) => changeValueMaterial(index, 'pose', e.target.value)}
                        />
                      </td>
                      <td className="border border-x-8 border-white">
                        <input
                          className="rounded-lg py-4 border border-white-admin input mt-3"
                          type="text"
                          placeholder="-"
                          value={listeMaterialCreate[index]?.depot}
                          onInput={(e) => changeValueMaterial(index, 'depot', e.target.value)}
                        />
                      </td>
                      <td className="border border-x-8 border-white">
                        <input
                          className="rounded-lg py-4 border border-white-admin input mt-3"
                          type="number"
                          placeholder="-"
                          value={listeMaterialCreate[index]?.quantity}
                          onInput={(e) => changeValueMaterial(index, 'quantity', e.target.value)}
                        />
                      </td>
                      <td className="text-center border border-white border-x-8">
                        <button
                          className="w-8 p-2 border rounded-lg border-white-admin"
                          onClick={(e) => deleteMaterial(e, index)}
                        >
                          <img
                            src={DeleteIcon}
                            alt="delete icon"
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          <div className="mt-5">
            <button
              className="w-[280px] py-3  text-sm text-blue-admin rounded-lg border border-white-admin mb-2"
              onClick={addMaterial}
            >
              + Ajouter une nouvelle intervention
            </button>
          </div>
        </div>
        {!!formError && <p className="text-red-error text-end mt-5 mr-20 italic">{formError}</p>}
        <div className="mt-16 py-4 flex justify-between items-center border-t border-white-admin">
          <button
            onClick={() => navigate(-1)}
            className="border rounded-lg cursor-pointer ml-14 border-white-admin px-5 py-3 text-blue-admin"
          >
            Annuler
          </button>
          <div className=" cursor-pointer mr-14 bg-blue-admin rounded-lg">
            <button
              type="submit"
              className="py-3 px-5 text-white font-semibold"
              onClick={submit}
            >
              Enregistrer ce bon pré-rempli
            </button>
          </div>
        </div>
      </form>
      <Modal_2
        showModal={showModal}
        title={`Bon d'intervention n°${numeroBon}`}
        text="Le bon d'intervention pré rempli est bien enregister!"
        image={VectorIcon}
      >
        <button
          className="px-4 py-3 text-white rounded-lg cursor-pointer bg-blue-admin w-full"
          onClick={goBack}
        >
          J'ai compris
        </button>
      </Modal_2>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
      />
    </section>
  );
};

export default PrefillOrder;
