import React, { useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { MiddlewareAdmin } from '../../../services/middlewares/middlewareAdmin';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfigServices from '../../../services/awsSdk/cognitoServices';
import PrimaryButton from '../PrimaryButton';

// import icons
import DeleteIcon from '../../../assets/images/svg/delete-icon.svg';
import EditIcon from '../../../assets/images/svg/edit-icon.svg';
import AttentionIcon from '../../../assets/images/svg/attention.svg';
import Modal from '../Modal';

// .Env value
const collaboratorName = process.env.REACT_APP_COLLABORATOR_NAME;

const ListAdmin = () => {
  //init store
  const setActivity = useStoreActions((action) => action.setActivity);
  const currentUser = useStoreState((state) => state.currentUser);

  //Etats (données)
  const [admins, setAdmins] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [idUser, setIdUser] = useState(null);

  useEffect(() => {
    // si la réponse res est différente de error alors je set la réponse
    setActivity(true);
    MiddlewareAdmin.getListAdmins(collaboratorName).then((res) => {
      if (res.statut) {
        setAdmins(res.data);
      }
      setActivity(false);
    });
  }, []);
  const deleteAction = (id) => {
    setShowModal(true);
    setIdUser(id);
  };

  const deleteUser = () => {
    // Delete user cognito
    ConfigServices.deleteUser(idUser, true)
      .then((response) => {
        //Delete user base de données
        setActivity(true);
        MiddlewareAdmin.deleteUser(idUser).then((res) => {
          if (res.statut) {
            setShowModal(false);
            const newArrayAdmins = admins.filter((admin) => admin.id !== res.data.id);
            setAdmins(newArrayAdmins);
          } else {
            toast.error("❌ Une erreur est survenue lors de la supppression d'un administrateur");
          }
          setActivity(false);
        });
      })
      .catch((error) => {
        toast.error("❌ Une erreur est survenue lors de la supppression d'un administrateur");
      });
  };
  //Affichage
  return (
    <section className="mx-14">
      {/* div main */}
      <div>
        {/* div title + add admin */}
        <div className="flex items-center justify-between pb-4 border-b pt-11 border-white-admin">
          <h3 className="font-semibold">Liste des administrateurs</h3>
          <PrimaryButton
            title="+ Ajouter un nouveau admin"
            link={'add'}
          />
        </div>
        {/* table list */}
        <div className="overflow-auto pt-14">
          <table className="w-full">
            <thead className="h-10">
              <tr>
                <th>Prénom</th>
                <th className="pl-10 xl:pl-24">Nom</th>
                <th className="pl-10 xl:pl-24">Adresse mail</th>
                <th className="pl-10 xl:pl-24">Statut</th>
                <th className="pl-10 xl:pl-24">Actions</th>
              </tr>
            </thead>
            <tbody>
              {admins &&
                admins.map((admin) => {
                  return (
                    <tr
                      key={admin.id}
                      className="h-16 border-b border-white-admin hover:bg-blue-50 transition-all"
                    >
                      <td>{admin.first_name}</td>
                      <td className="pl-10 xl:pl-24">{admin.last_name}</td>
                      <td className="pl-10 xl:pl-24">{admin.mail}</td>
                      <td className="pl-10 xl:pl-24">{admin.role_user.name}</td>
                      <td className="flex items-center pt-4 pl-10 space-x-4 xl:pl-24 ">
                        <Link
                          to={`./edit/${admin.id}`}
                          state={admin}
                        >
                          <button className="w-8 p-2 border rounded-lg border-white-admin">
                            <img
                              src={EditIcon}
                              alt="edit icon"
                            />
                          </button>
                        </Link>
                        {currentUser?.id != admin?.id && (
                          <button
                            className="w-8 p-2 border rounded-lg border-white-admin"
                            onClick={() => deleteAction(admin.id)}
                          >
                            <img
                              src={DeleteIcon}
                              alt="delete icon"
                            />
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      <Modal isVisible={showModal}>
        <div>
          <div className="pt-5 pl-5">
            <img
              src={AttentionIcon}
              alt="attention icon"
            />
            <p className="pl-5 pt-7">Supression admin</p>
            <p className="px-5 pt-2 text-gray-admin">
              {' '}
              Êtes-vous sûre de vouloir supprimer cet admin ? Cette action est définitive.
            </p>
            <div className="text-center pt-7">
              <button
                className="px-12 py-3 mr-4 font-semibold border rounded-xl text-red-error"
                onClick={() => setShowModal(false)}
              >
                Annuler
              </button>
              <button
                className="px-10 py-3 text-white bg-red-error rounded-xl"
                onClick={deleteUser}
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
      />
    </section>
  );
};

export default ListAdmin;
