import { useNavigate } from 'react-router-dom';
import { Amplify, Auth } from 'aws-amplify';
import { awsAuthCollab } from '../../../aws-config';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { MiddlewareCollab } from '../../../services/middlewares/middlewareCollab';
import { v4 as uuidv4 } from 'uuid';
import ConfigServices from '../../../services/awsSdk/cognitoServices';
import { useStoreState, useStoreActions } from 'easy-peasy';
import ShowPwd from '../../../assets/images/png/eye.png';
import HidePwd from '../../../assets/images/png/eye-slash.png';
import { useState } from 'react';

const collaboratorName = process.env.REACT_APP_COLLABORATOR_NAME; // permet de récupérer l'id role user

const AddCollaborators = () => {
  //init store
  const setActivity = useStoreActions((action) => action.setActivity);

  // generator uid
  const generateNewId = uuidv4();

  // Global store
  const roleUsers = useStoreState((state) => state.roleUsers);

  const collaboratorId = roleUsers.length && roleUsers.find((role) => role.name === collaboratorName).id;

  const categories = useStoreState((state) => state.categories);

  const navigate = useNavigate();

  const [showPwd, setShowPwd] = useState(false);

  //Schéma avec le Regex téléphone et password
  const phoneRegExp = /^[0-9]{10}$|([+])([0-9]{11,12})$/;
  const passwordRegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*-_]).{8,}$/;

  const schema = yup.object().shape({
    firstName: yup.string().required('Ce champ est requis.'),
    lastName: yup.string().required('Ce champ est requis.'),
    password: yup
      .string()
      .matches(
        passwordRegExp,
        'Votre mot de passe doit comporter au moins 8 caractères, une lettre majuscule, un chiffre et un caractère spécial.'
      ),
    phone: yup.string().matches(phoneRegExp, 'Format du numéro est incorrect'),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  // à faire rajouter une modal
  const signUp = async (data) => {
    // Create user in Cognito
    setActivity(true);
    Amplify.configure(awsAuthCollab);

    try {
      const user = await Auth.signUp({
        username: data.id,
        password: data.password,
      });
      if (user.userSub) {
        // Confirm password
        ConfigServices.setUserPassword(data).then((res) => {
          //Add preferred_username attribute
          ConfigServices.setUsername(data).then((response) => {
            // Create user table with role collaborator
            MiddlewareCollab.createCollab(data).then((result) => {
              if (result.statut) {
                navigate('/collaborators');
              }
              setActivity(false);
            });
          });
        });
      }
    } catch (error) {
      console.log('error signing up:', error);
      setActivity(false);
    }
  };

  const onSubmit = (data) => {
    const newData = {
      ...data,
      id_role_user: collaboratorId,
      firstName: data.firstName.trim(),
      lastName: data.lastName.trim(),
      fullName: `${data.firstName.trim()}${data.lastName.trim()}`.toLowerCase(),
      id: generateNewId,
    };
    signUp(newData);
  };

  const toggleShowPwd = () => {
    setShowPwd(!showPwd);
  };

  return (
    <section>
      <div>
        {/* div main */}
        <div className="pb-4 border-b mx-14 border-white-admin pt-14">
          <h3 className="font-semibold">Créer un nouveau collaborateur</h3>
        </div>
        {/* div form */}
        <div className="pt-11">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex items-center pb-4 space-x-4 border-b mx-14 border-white-admin">
              <label htmlFor="name">Prénom</label>
              <input
                id="name"
                className={errors.firstName ? 'w-[318px] input_error' : 'w-[318px] input'}
                type="text"
                placeholder="Prénom"
                autoComplete="none"
                {...register('firstName')}
              />
              <p className="text-xs text-red-500">{errors.firstName?.message}</p>
            </div>
            <div className="flex items-center py-5 space-x-4 border-b mx-14 border-white-admin">
              <label htmlFor="last-name">Nom</label>
              <input
                className={errors.lastName ? 'w-[318px] input_error' : 'w-[318px] input'}
                type="text"
                id="last-name"
                placeholder="Nom"
                {...register('lastName')}
              />
              <p className="text-xs text-red-error">{errors.lastName?.message}</p>
            </div>
            <div className="flex items-center py-5 space-x-4 border-b mx-14 border-white-admin">
              <label htmlFor="phone">Téléphone</label>
              <input
                className={errors.phone ? 'w-[458px] input_error' : 'w-[458px] input'}
                type="phone"
                id="phone"
                placeholder="Téléphone"
                {...register('phone')}
              />
              <p className="text-xs text-red-error">{errors.phone?.message}</p>
            </div>
            <div className="flex items-center py-5 space-x-4 border-b mx-14 border-white-admin">
              <label htmlFor="password">Mot de passe</label>
              <div className="flex items-center space-x-2">
                <input
                  className={errors.password ? 'w-[318px] input_error' : 'w-[318px] input'}
                  type={showPwd ? 'text' : 'password'}
                  id="password"
                  placeholder="Mot de passe"
                  {...register('password')}
                />
                {showPwd ? (
                  <img
                    src={HidePwd}
                    alt="hide password"
                    className="w-6 h-6 cursor-pointer"
                    onClick={toggleShowPwd}
                  />
                ) : (
                  <img
                    src={ShowPwd}
                    alt="show password"
                    className="w-6 h-6 cursor-pointer"
                    onClick={toggleShowPwd}
                  />
                )}
              </div>
              <p className="text-xs text-red-error">{errors.password?.message}</p>
            </div>
            <div className="flex items-center py-5 space-x-4 mx-14">
              <label>Compétences</label>
              {categories.length &&
                categories.map((categorie, index) => (
                  <div key={categorie.id}>
                    <input
                      type="radio"
                      id={categorie.name}
                      className="w-4 h-4 form-radio checked:bg-orange-clim text-orange-clim focus:ring-orange-clim"
                      value={categorie.id}
                      {...register('id_type')}
                      defaultChecked={index === 0}
                    />
                    <label
                      htmlFor={categorie.name}
                      className="ml-2 text-sm font-medium text-blue-admin"
                    >
                      {categorie.name}
                    </label>
                  </div>
                ))}
            </div>

            <div className="flex items-center justify-between pt-4 mt-16 border-t border-white-admin ">
              <div
                onClick={() => navigate(-1)}
                className="px-5 py-3 border rounded-lg cursor-pointer ml-14 border-white-admin text-blue-admin"
              >
                Annuler
              </div>
              <div className="rounded-lg cursor-pointer mr-14 bg-blue-admin">
                <button
                  type="submit"
                  className="px-5 py-3 font-semibold text-white"
                >
                  Créer un nouveau collaborateur
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default AddCollaborators;
