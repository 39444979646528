import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

const NavigationClassifyOrder = () => {
  //init state
  const [toggleActive, setToggleActive] = useState(false);

  //init url params
  const urlParams = useParams();

  //init effect
  useEffect(() => {
    const currentPath = urlParams['*'].split('/');
    if (currentPath.includes('ordercoupon')) {
      setToggleActive(false);
    } else {
      setToggleActive(true);
    }
  }, [urlParams]);
  return (
    <section className="mx-14">
      <div className="pb-4 border-b pt-11 border-white-admin">
        <h3 className="font-semibold">Liste des bons à classer</h3>
      </div>
      <div className="pb-4 border-b pt-11 border-white-admin">
        <ul className="flex space-x-8">
          <li className="text-blue-admin">
            <Link
              to="ordercoupon"
              className={toggleActive ? null : `borderBottomActive`}
            >
              Bons de commande
            </Link>
          </li>
          <li className="text-blue-admin">
            <Link
              to="interventioncoupon"
              className={toggleActive ? `borderBottomActive` : null}
            >
              Bons d’intervention
            </Link>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default NavigationClassifyOrder;
