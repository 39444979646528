const awsAuthAdmin = {
  region: process.env.REACT_APP_REGION,
  userPoolId: process.env.REACT_APP_POOL_ID_ADMIN,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID_ADMIN,
};
const awsAuthCollab = {
  region: process.env.REACT_APP_REGION,
  userPoolId: process.env.REACT_APP_POOL_ID_COLLABORATOR,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID_COLLABORATOR,
};
export { awsAuthAdmin, awsAuthCollab };
// Irl de axios
// La key permet de savoir à quel groupe ça appartient
