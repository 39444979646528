import { useStoreActions } from 'easy-peasy';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import middlewareCoupon from '../../../services/middlewares/middlewareCoupon';
import { useReactToPrint } from 'react-to-print';
import base64 from 'react-native-base64';
import './css/pdf.css';
import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale'; // Importez la locale française si nécessaire

// data format
const dateFormat = (date) => {
  const temps = new Date(date);
  return {
    year: temps.getFullYear().toString(),
    month: ('0' + (temps.getMonth() + 1)).slice(-2),
    day: ('0' + temps.getDate()).slice(-2),
  };
};

const PdfWorkOrder = () => {
  const { idworkorder } = useParams();
  const setActivity = useStoreActions((action) => action.setActivity);
  const [dataPdf, setDataPdf] = useState([]);

  const [mySvg, setMySvg] = useState('');
  const [materialsInd, setMaterialsInd] = useState([]);
  const [materialsComp, setMaterialsComp] = useState([]);
  const dateWorksiteStart = dateFormat(dataPdf?.worksite?.date_planned);

  // navigate
  let navigate = useNavigate();

  useEffect(() => {
    callInterventionCoupon();
  }, []);

  const componentRef = useRef();
  const handlePdf = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'purchase-data',
    onAfterPrint: () => AudioListener('Print success'),
  });

  const callInterventionCoupon = () => {
    setActivity(true);
    middlewareCoupon.getInterventionCoupon(idworkorder).then((result) => {
      if (result.statut) {
        const materialData = result.data.materialData.items;
        const tableInd = materialData.filter((material) => material.indexRef != null);
        const tableComp = materialData.filter((material) => material.indexRef == null && material.referenceQr == null);
        setDataPdf(result.data);
        setMaterialsInd(tableInd);
        setMaterialsComp(tableComp);
        setMySvg(base64.decode(result.data.signed));
      } else {
        console.log('Error: ', result.error);
        window.location.href = '/customers';
      }
      setActivity(false);
    });
  };

  const formattedDate = (updatedAt) => {
    const dateA = updatedAt ? parseISO(updatedAt) : new Date();
    return format(dateA, "EEEE dd MMMM yyyy 'à' HH:mm", {
      locale: fr,
    });
  };

  return (
    <div>
      <section className="ml-32 mt-14 mb-10 flex space-x-7">
        <div
          ref={componentRef}
          className="w-[827px] h-[1170px] shadow-2xl rounded-lg"
        >
          <div className="bg-orange-clim rounded-lg text-sm text-orange-clim h-4" />
          <div className="flex flex-col mt-10 px-10 h-[95%]">
            <h3>Bon d'intervention - N° {dataPdf?.numero}</h3>
            {/* div infos main */}
            <div className="flex justify-between mt-5">
              {/* div infos rigth */}
              <div className="mr-28 flex flex-col space-y-2">
                <h4>SACARABANY SARL</h4>
                <div className="text-sm text-gray-admin flex flex-col space-y-2">
                  <p>279 rue solitude la mulatresse</p>
                  <p>97200 Fort de France</p>
                  <p>SIRET : 495 262 131 00016</p>
                  <p>contact@sacarabany-sarl.fr</p>
                  <p>contact@sacarabany-sarl.fr</p>
                  <p>Le : {formattedDate(dataPdf?.updatedAt)}</p>
                </div>
              </div>
              {/* div infos left */}
              <div className="flex flex-col space-y-2">
                <h4>{dataPdf?.worksite?.company?.name?.toUpperCase() || dataPdf?.nameClient}</h4>
                <div className="text-sm text-gray-admin flex flex-col space-y-2">
                  <p>{dataPdf?.worksite?.company?.address}</p>
                  {dataPdf?.worksite?.company?.siret && <p>SIRET : {dataPdf?.worksite?.company?.siret}</p>}
                  {dataPdf?.newMailClient || dataPdf?.worksite?.company?.mail?.split(',')[0] || ''}
                  <p>{dataPdf?.worksite?.company?.phone || ''}</p>
                </div>
              </div>
            </div>
            {/* div name worksite */}
            <div className="mt-16 flex flex-col space-y-2">
              <h4>
                {dataPdf?.worksite?.name ? dataPdf?.worksite?.name : dataPdf?.nameWorksite}{' '}
                {dataPdf?.worksite &&
                  `- ${dateWorksiteStart?.day}/
                ${dateWorksiteStart?.month}/${dateWorksiteStart?.year}`}
              </h4>
              {dataPdf?.orderCoupon?.numero ? <h4>N° du bon de commande : {dataPdf?.orderCoupon?.numero}</h4> : ''}
              <h4 className="py-8">Etat des lieux de l’intervention</h4>
              <table className="tableContainer">
                <thead>
                  <tr>
                    <th>Dénomination du matériel</th>
                    <th>Référence</th>
                    <th>Code Qr</th>
                    <th>Lieu de dépôt</th>
                    <th>Lieu de pose</th>
                    <th>Surface</th>
                    <th>Quantité</th>
                  </tr>
                </thead>
                <tbody>
                  {materialsInd.length ? (
                    materialsInd?.map((materials, index) => {
                      const listRefMaterial = materials?.materialSettings?.references[0]?.reference.split(',');
                      const myReference = listRefMaterial[materials.indexRef];
                      return (
                        <tr
                          key={index}
                          className="text-sm  text-gray-admin"
                        >
                          <td>{materials?.materialSettings?.name || '-'}</td>

                          <td>{myReference}</td>

                          <td>{materials?.referenceQr || '-'}</td>
                          <td>{materials?.address || '-'}</td>

                          <td>{materials?.poseAddress || '-'}</td>

                          <td>{materials?.surface || '-'}</td>
                          <td>{materials?.quantity || '-'}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="mt-14"></div>
            </div>

            <div className="mt-16 pb-10 flex flex-col space-y-4">
              <h4>Intervention.s complémentaire.s</h4>
              <table className="tableContainer">
                <thead>
                  <tr>
                    <th>Dénomination du matériel</th>
                    <th>Référence</th>
                    <th>Quantité</th>
                  </tr>
                </thead>
                <tbody>
                  {materialsComp?.length ? (
                    materialsComp.map((material, index) => {
                      return (
                        <tr
                          key={index}
                          className=" text-sm text-gray-admin "
                        >
                          <td>{material?.name}</td>
                          <td>{material?.description}</td>
                          <td>{material?.quantity}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="grow"></div>

            <div>
              <p className="container">signature :</p>
              <div
                className="bg-cover container"
                dangerouslySetInnerHTML={{ __html: mySvg }}
              />
            </div>
            {/* footer */}
            <div className=" text-center text-gray-admin text-xs border-t border-gray-separate pt-5 pb-7">
              <h6>SACARABANY SARL SIRET : 495 262 131 00016 - APE : 453 A</h6>
              <p>279 rue solitude la mulatresse - 97200 Fort de France</p>
              <p>TEL : 0596 70 75 50 - 0696 45 02 08</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-between text-blue-admin ">
          <div className="border border-blue-admin py-4 px-3 rounded-xl">
            <button onClick={() => navigate(-1)}>Voir tous les bons de commandes</button>
          </div>
          <button
            className="flex-end bg-blue-admin text-white py-4 rounded-xl"
            onClick={handlePdf}
          >
            Générer un PDF
          </button>
        </div>
      </section>
    </div>
  );
};

export default PdfWorkOrder;
