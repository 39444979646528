import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Link, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { MiddlewareCollab } from '../../../services/middlewares/middlewareCollab';
import ConfigServices from '../../../services/awsSdk/cognitoServices';
import ShowPwd from '../../../assets/images/png/eye.png';
import HidePwd from '../../../assets/images/png/eye-slash.png';
import { useState } from 'react';

const EditCollaborators = () => {
  //init store
  const setActivity = useStoreActions((action) => action.setActivity);

  // Global store
  const categories = useStoreState((state) => state.categories);

  //init navigation
  const navigate = useNavigate();

  //init default values User
  const currentUser = useLocation().state;

  const [showPwd, setShowPwd] = useState(false);

  //no data navigate to Marie©
  if (!currentUser) {
    window.location.href = '/collaborators';
  }

  const onSubmit = (data) => {
    const newData = {
      ...data,
      id: currentUser.id,
      firstName: data.firstName.trim(),
      lastName: data.lastName.trim(),
      fullName: `${data.firstName.trim()}${data.lastName.trim()}`.toLowerCase(),
    };
    setActivity(true);
    MiddlewareCollab.updateCollab(newData).then((res) => {
      if (res.statut) {
        ConfigServices.setUsername(newData)
          .then((response) => {
            if (newData.password !== '') {
              ConfigServices.setUserPassword(newData).then((response) => {
                setActivity(false);
                navigate('/collaborators');
              });
            } else {
              setActivity(false);
              navigate('/collaborators');
            }
          })
          .catch((err) => {
            setActivity(false);
          });
      } else {
        setActivity(false);
      }
    });
  };
  //Schéma avec le Regex téléphone et password
  const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  const passwordRegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*-_]).{8,}$|^$/;
  const schema = yup.object().shape({
    firstName: yup.string().required('Ce champ est requis.'),
    lastName: yup.string().required('Ce champ est requis.'),
    password: yup
      .string()
      .notRequired()
      .matches(
        passwordRegExp,
        'Votre mot de passe doit comporter au moins 8 caractères, une lettre majuscule, un chiffre et un caractère spécial.'
      ),
    phone: yup.string().matches(phoneRegExp, 'Format du numéro est incorrect'),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const toggleShowPwd = () => {
    setShowPwd(!showPwd);
  };

  return (
    <section>
      <div>
        {/* div main */}
        <div className="pb-4 border-b mx-14 border-white-admin pt-14">
          <h3 className="font-semibold">Modifier un collaborateur</h3>
        </div>
        {/* div form */}
        <div className="pt-11">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex items-center pb-4 space-x-4 border-b mx-14 border-white-admin">
              <label htmlFor="name">Prénom</label>
              <input
                defaultValue={currentUser.first_name}
                className={errors.firstName ? 'w-[318px] input_error' : 'w-[318px] input'}
                type="text"
                id="name"
                placeholder="Prénom"
                autoComplete="none"
                {...register('firstName')}
              />
              <p className="text-xs text-red-error">{errors.firstName?.message}</p>
            </div>
            <div className="flex items-center py-5 space-x-4 border-b mx-14 border-white-admin">
              <label htmlFor="last-name">Nom</label>
              <input
                defaultValue={currentUser.last_name}
                className={errors.lastName ? 'w-[318px] input_error' : 'w-[318px] input'}
                type="text"
                placeholder="Nom"
                id="last-name"
                {...register('lastName')}
              />
              <p className="text-xs text-red-error">{errors.lastName?.message}</p>
            </div>
            <div className="flex items-center py-5 space-x-4 border-b mx-14 border-white-admin">
              <label htmlFor="phone">Téléphone</label>
              <input
                defaultValue={currentUser.phone}
                className={errors.phone ? 'w-[458px] input_error' : 'w-[458px] input'}
                type="text"
                placeholder="Téléphone"
                id="phone"
                {...register('phone')}
              />
              <p className="text-xs text-red-error">{errors.phone?.message}</p>
            </div>
            <div className="flex items-center py-5 space-x-4 border-b mx-14 border-white-admin">
              <label htmlFor="password">Mot de passe</label>
              <div className="flex items-center space-x-2">
                <input
                  className={errors.password ? 'w-[318px] input_error' : 'w-[318px] input'}
                  type={showPwd ? 'text' : 'password'}
                  id="password"
                  placeholder="Mot de passe"
                  {...register('password', { required: false })}
                />
                {showPwd ? (
                  <img
                    src={HidePwd}
                    alt="hide password"
                    className="w-6 h-6 cursor-pointer"
                    onClick={toggleShowPwd}
                  />
                ) : (
                  <img
                    src={ShowPwd}
                    alt="show password"
                    className="w-6 h-6 cursor-pointer"
                    onClick={toggleShowPwd}
                  />
                )}
              </div>
              <p className="text-xs text-red-error">{errors.password?.message}</p>
            </div>
            <div className="flex items-center py-5 space-x-4 mx-14">
              <label>Compétences</label>
              {categories.length &&
                categories.map((categorie, index) => (
                  <div key={categorie.id}>
                    <input
                      type="radio"
                      id={categorie.name}
                      className="w-4 h-4 form-radio checked:bg-orange-clim text-orange-clim focus:ring-orange-clim"
                      value={categorie.id}
                      {...register('id_type')}
                      defaultChecked={categorie.id === currentUser.type.id}
                    />
                    <label
                      htmlFor={categorie.name}
                      className="ml-2 text-sm font-medium text-blue-admin"
                    >
                      {categorie.name}
                    </label>
                  </div>
                ))}
            </div>

            <div className="flex items-center justify-between pt-4 mt-16 border-t border-white-admin">
              <Link
                to="/collaborators"
                className="border rounded-lg cursor-pointer ml-14 border-white-admin"
              >
                <button className="px-5 py-3 text-blue-admin">Annuler</button>
              </Link>
              <div className="rounded-lg cursor-pointer mr-14 bg-blue-admin">
                <button
                  type="submit"
                  className="px-5 py-3 font-semibold text-white"
                >
                  Enregistrer les modifications
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default EditCollaborators;
