import { useStoreActions } from 'easy-peasy';
import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import middlewareCoupon from '../../../services/middlewares/middlewareCoupon';
import base64 from 'react-native-base64';
import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale'; // Importez la locale française si nécessaire

const maxText = (text) => {
  if (text.length > 21) {
    return text.slice(0, 18) + '...';
  } else {
    return text;
  }
};

const PdfPurchaseOrder = () => {
  const { idpurchaseorder } = useParams();
  const setActivity = useStoreActions((action) => action.setActivity);
  const [dataPdf, setDataPdf] = useState(null);
  const [materialsInd, setMaterialsInd] = useState([]);
  const [materialsComp, setMaterialsComp] = useState([]);
  const [mySvg, setMySvg] = useState('');

  // navigate
  let navigate = useNavigate();

  const componentRef = useRef();
  const handlePdf = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'purchase-data',
    onAfterPrint: () => AudioListener('Print success'),
  });

  useEffect(() => {
    callOrderCoupon();
  }, []);

  const callOrderCoupon = () => {
    setActivity(true);
    middlewareCoupon.getOrderCoupon(idpurchaseorder).then((result) => {
      if (result.statut) {
        setMySvg(base64.decode(result.data.signed));
        setDataPdf(result.data);
        const materialData = result.data.materialData.items;
        const tableInd = materialData.filter((material) => material.indexRef != null);
        const tableComp = materialData.filter((material) => material.indexRef == null);
        setMaterialsInd(tableInd);
        setMaterialsComp(tableComp);
        console.log('dataPdf', result.data);
      } else {
        console.log('Error: ', result.error);
        window.location.href = '/customers';
      }
      setActivity(false);
    });
  };

  const formattedDate = (updatedAt) => {
    const dateA = updatedAt ? parseISO(updatedAt) : new Date();
    return format(dateA, "EEEE dd MMMM yyyy 'à' HH:mm", {
      locale: fr,
    });
  };

  return (
    <section
      id="pagetodonwload"
      className="ml-8 mt-14 mb-10 flex space-x-7"
    >
      <div
        ref={componentRef}
        className="w-[800px] h-fit rounded-lg"
      >
        <div className="bg-orange-clim rounded-lg text-sm text-orange-clim">ok</div>
        <div className="mt-10 px-10">
          <h3>Bon de commande - N° {dataPdf?.numero}</h3>
          {/* div infos main */}
          <div className="flex justify-between mt-5">
            {/* div infos rigth */}
            <div className="mr-28 flex flex-col space-y-2">
              <h4>SACARABANY SARL</h4>
              <div className="text-sm text-gray-admin flex flex-col space-y-2">
                <p>279 rue solitude la mulatresse</p>
                <p>97200 Fort de France</p>
                <p>SIRET : 495 262 131 00016</p>
                <p>contact@sacarabany-sarl.fr</p>
                <div className="text-sm text-gray-admin flex flex-col space-y-2">
                  {dataPdf?.collaborator ? (
                    <p>
                      Fait par :
                      <span className="ml-1">
                        {dataPdf?.collaborator?.first_name} {dataPdf?.collaborator?.last_name}
                      </span>
                    </p>
                  ) : null}
                </div>
                <p>Le : {formattedDate(dataPdf?.updatedAt)}</p>
              </div>
            </div>
            {/* div infos left */}
            <div className="flex flex-col space-y-2">
              <h4>{dataPdf?.company?.name?.toUpperCase()}</h4>
              <div className="text-sm text-gray-admin flex flex-col space-y-2">
                <p>{dataPdf?.company?.address}</p>
                <p>SIRET : {dataPdf?.company?.siret}</p>
                <p>{dataPdf?.company?.mail?.split(',')[0]}</p>
                {dataPdf?.nameClient ? (
                  <div>
                    <p>
                      Client :{' '}
                      <span className="ml-1">
                        {dataPdf?.nameClient ? dataPdf?.nameClient : dataPdf?.worksite?.company?.name}
                      </span>
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* div name worksite */}

          <div className="mt-16 flex flex-col space-y-4">
            <h4>
              {dataPdf?.worksite?.name ? dataPdf?.worksite?.name?.toUpperCase() : dataPdf?.nameWorksite?.toUpperCase()}
            </h4>
            <h4>Matériel.s indispensable.s</h4>

            <div className="mt-16 pb-10 flex flex-col space-y-4">
              <table>
                <thead>
                  <tr>
                    <th>Dénomination du matériel</th>
                    <th>Référence</th>
                    <th>Quantité</th>
                  </tr>
                </thead>
                <tbody>
                  {materialsInd.length ? (
                    materialsInd?.map((materials, index) => {
                      const listRefMaterial = materials?.materialSettings?.references[0]?.reference.split(',');
                      const myReference = listRefMaterial[materials.indexRef];
                      return (
                        <tr
                          key={index}
                          className="text-sm  text-gray-admin"
                        >
                          <td>{materials?.materialSettings?.name}</td>

                          <td>{myReference}</td>

                          <td>{materials?.quantity}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <h4>Matériel.s complémentaire.s</h4>
            <div className="mt-16 pb-10 flex flex-col space-y-4">
              <table>
                <thead>
                  <tr>
                    <th>Dénomination du matériel</th>
                    <th>Référence</th>
                    <th>Quantité</th>
                  </tr>
                </thead>
                <tbody>
                  {materialsComp.length ? (
                    materialsComp?.map((materials, index) => {
                      return (
                        <tr
                          key={index}
                          className="text-sm  text-gray-admin"
                        >
                          <td>{materials?.materialSettings?.name}</td>

                          <td>{materials?.description}</td>

                          <td>{materials?.quantity}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <p className="container">signature :</p>
            {dataPdf?.name ? <p className="container">{dataPdf?.name}</p> : null}
            <div
              className="bg-cover container"
              dangerouslySetInnerHTML={{ __html: mySvg }}
            />
          </div>
          {/* footer */}
          <div className="text-center text-gray-admin text-xs border-t border-gray-separate pt-5 pb-7">
            <h6>SACARABANY SARL SIRET : 495 262 131 00016 - APE : 453 A</h6>
            <p>279 rue solitude la mulatresse - 97200 Fort de France</p>
            <p>TEL : 0596 70 75 50 - 0696 45 02 08</p>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-between text-blue-admin ">
        <div className="border border-blue-admin py-4 px-3 rounded-xl">
          <button onClick={() => navigate(-1)}>voir les bons de commandes</button>
        </div>
        <button
          className="flex-end bg-blue-admin text-white py-4 rounded-xl"
          onClick={handlePdf}
        >
          Générer un PDF
        </button>
      </div>
    </section>
  );
};

export default PdfPurchaseOrder;
