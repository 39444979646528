import { useEffect, useState } from 'react';
import middleworksite from '../../services/middlewares/middlewareWorksite';
import middlewareCompany from '../../services/middlewares/middlewareCompany';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import Modal from '../../shared/components/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrimaryButton from '../../shared/components/PrimaryButton';
import { useStoreState, useStoreActions } from 'easy-peasy';

// import icons
import DeleteIcon from '../../assets/images/svg/delete-icon.svg';
import EditIcon from '../../assets/images/svg/edit-icon.svg';
import FolderIcon from '../../assets/images/svg/folder.svg';
import Search from '../../assets/images/svg/search.svg';
import AttentionIcon from '../../assets/images/svg/attention.svg';

// data format
import { dateFormat } from '../../shared/tools/manager';

const WorkSite = () => {
  //init navigation
  const navigate = useNavigate();

  //fun navigation
  const navigation = (id, state) => {
    navigate(`./order/${id}/purchaseorder`, { state: state });
  };

  //init store
  const setActivity = useStoreActions((action) => action.setActivity);
  const activity = useStoreState((state) => state.activity);

  //url params
  const { idCustomer } = useParams();

  //init state
  const [listeWorkSites, setListeWorkSites] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [idWorkSiteSelected, setIdWorkSiteSelected] = useState(null);
  const [currentCustomer, setCurrentCustomer] = useState(useLocation().state);
  const [worksitesList, setWorksitesList] = useState(listeWorkSites);
  const [textSearch, setTextSearch] = useState('');

  // func filter search, Listen
  useEffect(() => {
    if (textSearch === '') {
      setWorksitesList(listeWorkSites);
    } else {
      const tabFilter = listeWorkSites.filter((element) =>
        element.name.toLowerCase().includes(textSearch.toLowerCase())
      );
      setWorksitesList(tabFilter);
    }
  }, [textSearch, listeWorkSites]);

  // fun call customer
  const callCustomer = () => {
    setActivity(true);
    middlewareCompany.getCompanie(idCustomer).then((result) => {
      if (result.statut) {
        setCurrentCustomer(result.data);
      } else {
        toast.error('❌ Une erreur est survenue lors de la récupération des données client 🤯');
        console.log('Error: ', result.error);
        window.location.href = '/customers';
      }
      setActivity(false);
    });
  };

  // fun call list
  const callWorksites = () => {
    setActivity(true);
    middleworksite.getlistWorkSite(currentCustomer?.id).then((result) => {
      if (result.statut) {
        setListeWorkSites(result.data);
      } else {
        toast.error('❌ Une erreur est survenue lors de la récupération de la liste des chantiers 🤯');
        console.log('Error: ', result.error);
      }
      setActivity(false);
    });
  };

  //udate status
  const updateStatus = (data) => {
    setActivity(true);
    middleworksite.updateStatusWorkSite(data).then((result) => {
      if (!result.statut) {
        toast.error('❌ Une erreur est survenue lors du changement de statut 🤯');
      }
      setActivity(false);
    });
  };

  //Effect
  useEffect(() => {
    if (!currentCustomer) {
      callCustomer();
    } else if (!listeWorkSites.length && currentCustomer) {
      callWorksites();
    }
  }, [currentCustomer]);

  // fun click
  const deleteAction = (workSite) => {
    setShowModal(true);
    setIdWorkSiteSelected(workSite);
  };

  // fun delete
  const deleteWorksite = () => {
    setActivity(true);

    middleworksite.getlistWorksiteUsers(idWorkSiteSelected.id).then((result) => {
      if (result.statut) {
        middleworksite.DeleteMultipleWorksiteUsers(result.data).then((resultDelete) => {
          if (resultDelete.statut) {
            middleworksite.deleteWorksite(idWorkSiteSelected.id).then((resultDeletWorksite) => {
              if (resultDeletWorksite.statut) {
                setShowModal(false);
                callWorksites();
              }
              setActivity(false);
            });
          }
        });
      }
    });
  };

  //Render
  return (
    <>
      <section className="px-14">
        <div>
          <div className="flex items-center justify-between pb-4 border-b pt-11 border-white-admin ">
            <h3 className="font-semibold">
              Liste des chantiers: <span className="font-bold text-blue-admin">{currentCustomer?.name}</span>
            </h3>
            <PrimaryButton
              title="+ Ajouter un nouveau chantier"
              link="add"
              state={currentCustomer}
            />
          </div>
          <div className="relative flex mt-6 border border-white-admin rounded-xl">
            <input
              type="text"
              placeholder="Recherche chantier"
              className="w-full py-3 pl-10 border-none rounded-xl placeholder:text-blue-admin inputFocus"
              onInput={(e) => setTextSearch(e.target.value)}
            />
            <img
              src={Search}
              alt="search icon"
              className="absolute left-0 flex items-center w-8 h-5 pl-3 top-4"
            />
          </div>
          <div className="overflow-auto pt-14">
            <table className="w-full">
              <thead className="h-10">
                <tr>
                  <th>Mise à jour le:</th>
                  <th className="pl-10 xl:pl-24">Type chantier</th>
                  <th className="pl-10 xl:pl-24">Nom chantier</th>
                  <th className="pl-10 xl:pl-24">Statut</th>
                  <th className="pl-10 xl:pl-24">Débute le:</th>
                  <th className="pl-10 xl:pl-24">Actions</th>
                </tr>
              </thead>
              <tbody>
                {worksitesList.length ? (
                  worksitesList.map((WorkSite) => {
                    const dateWorksiteUpdate = dateFormat(WorkSite.updatedAt, '/').full;
                    const dateWorksiteStart = dateFormat(WorkSite.date_planned, '/').full;
                    return (
                      <tr
                        key={WorkSite.id}
                        className="h-16 border-b border-white-admin hover:bg-blue-50 transition-all cursor-pointer"
                      >
                        <td onClick={() => navigation(WorkSite.id, WorkSite)}>{dateWorksiteUpdate}</td>
                        <td
                          className="pl-10 xl:pl-24"
                          onClick={() => navigation(WorkSite.id, WorkSite)}
                        >
                          {WorkSite.type.name}
                        </td>
                        <td
                          className="pl-10 xl:pl-24"
                          onClick={() => navigation(WorkSite.id, WorkSite)}
                        >
                          {WorkSite.name}
                        </td>
                        <td className="pl-10 xl:pl-24">
                          <select
                            name="marie"
                            className="cursor-pointer text-blue-admin text-sm bg-blue-dropdown py-3 pl-5 pr-10 rounded-lg border-none inputFocus"
                            onChange={(e) =>
                              updateStatus({
                                status: e.target.value,
                                id: WorkSite.id,
                              })
                            }
                          >
                            <option
                              value="En cours"
                              selected={WorkSite.status === 'En cours' ? true : false}
                            >
                              En cours
                            </option>
                            <option
                              value="Passe"
                              selected={WorkSite.status === 'Passe' ? true : false}
                            >
                              Passé
                            </option>
                            <option
                              value="Futur"
                              selected={WorkSite.status === 'Futur' ? true : false}
                            >
                              Futur
                            </option>
                          </select>
                        </td>
                        <td
                          className="pl-10 xl:pl-24"
                          onClick={() => navigation(WorkSite.id, WorkSite)}
                        >
                          {dateWorksiteStart}
                        </td>
                        <td className="flex items-center pt-4 pl-10 space-x-4 xl:pl-24 ">
                          <Link
                            to={`./order/${WorkSite.id}/purchaseorder`}
                            state={WorkSite}
                          >
                            <button className="w-8 p-2 border rounded-lg border-gray-separate">
                              <img
                                src={FolderIcon}
                                alt="Worksite icon"
                              />
                            </button>
                          </Link>
                          <Link
                            to={`./edit/${WorkSite.id}`}
                            state={WorkSite}
                          >
                            <button className="w-8 p-2 border rounded-lg border-gray-separate">
                              <img
                                src={EditIcon}
                                alt="edit icon"
                              />
                            </button>
                          </Link>
                          <button
                            className="w-8 p-2 border rounded-lg border-gray-separate"
                            onClick={() => deleteAction(WorkSite)}
                          >
                            <img
                              src={DeleteIcon}
                              alt="delete icon"
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      className="text-center text-lg font-semibold pt-20"
                      colSpan={6}
                    >
                      {activity ? <></> : textSearch ? "Oups, ce chantier n'existe pas 🤭" : 'Ajouter un Chantier'}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <Modal isVisible={showModal}>
        <div>
          <div className="pt-5 pl-5">
            <img
              src={AttentionIcon}
              alt="attention icon"
            />
            <p className="pl-5 pt-7">Supression du chantier: {idWorkSiteSelected?.name}</p>
            <p className="px-5 pt-2 text-gray-admin">
              Êtes-vous sûre de vouloir supprimer ce chantier ? Cette action est définitive.
            </p>
            <div className="text-center pt-7">
              <button
                className="px-12 py-3 mr-4 font-semibold border rounded-xl text-red-error"
                onClick={() => setShowModal(false)}
              >
                Annuler
              </button>
              <button
                className="px-10 py-3 text-white bg-red-error rounded-xl"
                onClick={deleteWorksite}
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
      />
    </>
  );
};

export default WorkSite;
