import React from 'react';
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { middlewareSettings } from '../../../services/middlewares/middlewareSettings';
import { useStoreActions } from 'easy-peasy';

const EditTypeInterventionSettings = () => {
  //init store
  const setActivity = useStoreActions((action) => action.setActivity);

  const currentTypeIntervention = useLocation().state;
  const [typeIntervention, setTypeIntervention] = useState(currentTypeIntervention.name);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setActivity(true);
    middlewareSettings
      .updateTypeIntervention({
        id: currentTypeIntervention.id,
        typeIntervention,
      })
      .then((res) => {
        if (res.statut) {
          navigate(-1);
        }
        setActivity(false);
      });
  };
  return (
    <section>
      <div className="pt-11 pb-4 border-b border-white-admin mb-3 mx-14">
        <h3 className="font-semibold">Modifier la nature de l'intervention</h3>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex mx-14 space-x-4 items-center  py-5">
          <label htmlFor="name">Nature de l'intervention</label>
          <input
            className="w-[318px] py-3 pl-5 border border-white-admin rounded-lg text-sm input"
            type="text"
            placeholder="Nom du matériel"
            onChange={(e) => setTypeIntervention(e.target.value)}
            value={typeIntervention}
          />
        </div>
        <div className="mt-64 pt-4 flex justify-between items-center border-t border-white-admin">
          <Link
            to="/settings/purchase-order"
            className="cursor-pointer ml-14 border border-white-admin rounded-lg"
          >
            <button className="py-3 px-5 text-blue-admin">Annuler</button>
          </Link>
          <div className=" cursor-pointer mr-14 bg-blue-admin rounded-lg">
            <button
              type="submit"
              className="py-3 px-5 text-white font-semibold"
            >
              Enregister les Modifications
            </button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default EditTypeInterventionSettings;
