import { useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useParams } from 'react-router-dom';

// import imgs and logo
import LogoSarl from '../../assets/images/svg/logo-flex-sacarabany-sarl.svg';
import ImgGroupSide from '../../assets/images/png/imgs-group-sidebar.png';

const SideMenu = () => {
  // init location
  let location = useLocation();

  //init state
  const [toggleActive, setToggleActive] = useState(false);

  //init url params
  const urlParams = useParams();

  //init effect
  useEffect(() => {
    const currentPath = urlParams['*'].split('/');
    if (currentPath.includes('classify-order')) {
      setToggleActive(true);
    } else {
      setToggleActive(false);
    }
  }, [urlParams]);

  //Render
  return (
    <section className="border-r border-gray-[#E2E9EF] min-w-[250px] max-w-[300px] justify-between h-screen overflow-hidden">
      {/* div main */}
      <div className="flex flex-col justify-between h-full overflow-hidden">
        {/* logo sarl */}
        <div>
          <div className="pt-10 pl-3">
            <Link to="/">
              <img
                src={LogoSarl}
                alt="logo sacarabany sarl"
              />
            </Link>
          </div>
          {/* div links navigation */}

          <div className={location.pathname === '/' ? 'hidden' : 'block'}>
            <ul className="space-y-8 pl-9 pt-16 pb-5">
              <li>
                <NavLink
                  to="/admin"
                  className={({ isActive }) => (isActive ? 'active' : 'nav-link')}
                >
                  Administrateurs
                </NavLink>
              </li>
              <li>
                <NavLink to="/collaborators">Collaborateurs</NavLink>
              </li>
              <li>
                <NavLink to="/customers">Clients et chantiers</NavLink>
              </li>
              <li>
                <NavLink
                  to="/classify-order/ordercoupon"
                  className={toggleActive ? 'active' : 'nav-link'}
                >
                  Bons à classer
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/all-coupons/purchaseorder"
                  className={location.pathname.includes('/all-coupons/intervention') ? 'active' : 'nav-link'}
                >
                  Liste des bons
                </NavLink>
              </li>
              <li>
                <NavLink to="/suppliers">Fournisseurs</NavLink>
              </li>

              <li>
                <NavLink
                  to="/settings/purchase-order"
                  className={location.pathname == '/settings/work-order' ? 'active' : 'nav-link'}
                >
                  Paramètres
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        {/* div group img */}
        <div>
          <img
            src={ImgGroupSide}
            alt="imgs group sidebar"
          />
        </div>
      </div>
    </section>
  );
};

export default SideMenu;
