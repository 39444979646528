import axios from 'axios';

const urlGraphQL = process.env.REACT_APP_URL_GRAPH_QL;

const options = {
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_API_KEY,
  },
};

const MiddlewareType = {
  getListRoleUser: async () => {
    const body = {
      query: `query MyQuery {
            listRoleUsers {
              items {
                id
                name
              }
            }
          }
          `,
    };
    try {
      const request = await axios.post(urlGraphQL, body, options);
      return { statut: true, data: request.data.data.listRoleUsers.items };
    } catch (error) {
      console.log('error', error);
      return { statut: false, error };
    }
  },
  getListCategory: async () => {
    const body = {
      query: `query MyQuery {
        listTypes {
          items {
            id
            name
          }
        }
      }      
          `,
    };
    try {
      const request = await axios.post(urlGraphQL, body, options);
      return { statut: true, data: request.data.data.listTypes.items };
    } catch (error) {
      console.log('error', error);
      return { statut: false, error };
    }
  },
  getListTypeCompanies: async () => {
    const body = {
      query: `query MyQuery {
        listTypeCompanies {
          items {
            name
            id
          }
        }
      }`,
    };
    try {
      const request = await axios.post(urlGraphQL, body, options);
      return { statut: true, data: request.data.data.listTypeCompanies.items };
    } catch (error) {
      console.log('error', error);
      return { statut: false, error };
    }
  },
};

export { MiddlewareType };
