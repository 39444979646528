import { useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { MiddlewareAdmin } from '../../../services/middlewares/middlewareAdmin';
import ConfigServices from '../../../services/awsSdk/cognitoServices';

import ShowPwd from '../../../assets/images/png/eye.png';
import HidePwd from '../../../assets/images/png/eye-slash.png';

// .Env value
const collaboratorName = process.env.REACT_APP_COLLABORATOR_NAME;

const EditAdmin = () => {
  //init store
  const setActivity = useStoreActions((action) => action.setActivity);

  // Global store
  const roleUsers = useStoreState((state) => state.roleUsers);
  const roleAdmin = roleUsers.filter((adminRole) => adminRole.name !== collaboratorName);

  //init state
  const [statut, setStatut] = useState('');
  const [showPwd, setShowPwd] = useState(false);

  //init navigation
  const navigate = useNavigate();
  const currentUser = useLocation().state;

  //no data navigate to Marie©
  if (!currentUser) {
    window.location.href = '/admin';
  }

  useEffect(() => {
    setStatut(currentUser.role_user.id);
  }, []);

  const onSubmit = (data) => {
    const newData = {
      ...data,
      id: currentUser.id,
      id_role_user: statut,
      firstName: data.firstName.trim(),
      lastName: data.lastName.trim().toLowerCase(),
    };
    setActivity(true);
    ConfigServices.setMail(newData, true)
      .then((response) => {
        MiddlewareAdmin.updateAdmin(newData).then((res) => {
          if (res.statut) {
            if (newData.password !== '') {
              ConfigServices.setUserPassword(newData, true).then((response) => {
                navigate('/admin');
              });
            } else {
              navigate('/admin');
            }
          }
          setActivity(false);
        });
      })
      .catch((err) => {
        setActivity(false);
      });
  };

  const toggleShowPwd = () => {
    setShowPwd(!showPwd);
  };

  const passwordRegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*-_]).{8,}$|^$/;
  const schema = yup.object().shape({
    firstName: yup.string().required('Ce champ est requis.'),
    lastName: yup.string().required('Ce champ est requis.'),
    mail: yup.string().required('Entrez une adresse email valide.'),
    password: yup
      .string()
      .notRequired()
      .matches(
        passwordRegExp,
        'Votre mot de passe doit comporter au moins 8 caractères, une lettre majuscule, un chiffre et un caractère spécial.'
      ),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <section>
      <div>
        {/* div main */}
        <div className="pb-4 border-b mx-14 border-white-admin pt-14">
          <h3 className="font-semibold">Modifier un administrateur</h3>
        </div>
        {/* div form */}
        <div className="pt-11">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex items-center pb-4 space-x-4 border-b mx-14 border-white-admin">
              <label htmlFor="name">Prénom</label>
              <input
                defaultValue={currentUser.first_name}
                className={errors.firstName ? 'w-[318px] input_error' : 'w-[318px] input'}
                type="text"
                placeholder="Prénom"
                autoComplete="none"
                {...register('firstName')}
              />
              <p className="text-xs text-red-error">{errors.firstName?.message}</p>
            </div>
            <div className="flex items-center py-5 space-x-4 border-b mx-14 border-white-admin">
              <label htmlFor="last-name">Nom</label>
              <input
                defaultValue={currentUser.last_name}
                className={errors.lastName ? 'w-[318px] input_error' : 'w-[318px] input'}
                type="text"
                id="last-name"
                placeholder="Nom"
                {...register('lastName')}
              />
              <p className="text-xs text-red-error">{errors.lastName?.message}</p>
            </div>
            <div className="flex items-center py-5 space-x-4 border-b mx-14 border-white-admin">
              <label htmlFor="mail">Adresse mail</label>
              <input
                defaultValue={currentUser.mail}
                className={errors.mail ? 'w-[458px] input_error' : 'w-[458px] input'}
                type="text"
                id="mail"
                placeholder="Adresse mail"
                {...register('mail')}
              />
              <p className="text-xs text-red-error">{errors.mail?.message}</p>
            </div>
            <div className="flex items-center py-5 space-x-4 border-b mx-14 border-white-admin">
              <label htmlFor="password">Mot de passe</label>
              <div className="flex items-center space-x-2">
                <input
                  className={errors.password ? 'w-[318px] input_error' : 'w-[318px] input'}
                  type={showPwd ? 'text' : 'password'}
                  id="password"
                  placeholder="Mot de passe"
                  {...register('password', { required: false })}
                />
                {showPwd ? (
                  <img
                    src={HidePwd}
                    alt="hide password"
                    className="w-6 h-6 cursor-pointer"
                    onClick={toggleShowPwd}
                  />
                ) : (
                  <img
                    src={ShowPwd}
                    alt="show password"
                    className="w-6 h-6 cursor-pointer"
                    onClick={toggleShowPwd}
                  />
                )}
              </div>
              <p className="text-xs text-red-error">{errors.password?.message}</p>
            </div>
            <div className="flex items-center py-5 space-x-4 mx-14">
              <label htmlFor="status-select">Statut</label>
              <select
                className="cursor-pointer text-blue-admin text-sm bg-blue-dropdown w-[330px] py-3 px-5 rounded-lg border-none inputFocus"
                name="status"
                id="status"
                onChange={(event) => setStatut(event.target.value)}
                value={statut}
              >
                {roleAdmin.length &&
                  roleAdmin.map((role) => (
                    <option
                      key={role.id}
                      value={role.id}
                      selected={currentUser.role_user.id === role.id && true}
                    >
                      {role.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="flex items-center justify-between pt-4 mt-16 border-t border-white-admin">
              <div
                onClick={() => navigate(-1)}
                className="px-5 py-3 border rounded-lg cursor-pointer ml-14 border-white-admin text-blue-admin"
              >
                Annuler
              </div>
              <div className="rounded-lg cursor-pointer mr-14 bg-blue-admin">
                <button
                  type="submit"
                  className="px-5 py-3 font-semibold text-white"
                >
                  Enregistrer les modifications
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default EditAdmin;
