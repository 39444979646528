import { useEffect, useState } from 'react';
import middlewareCompany from '../../services/middlewares/middlewareCompany';
import { Link, useNavigate } from 'react-router-dom';
import Modal from '../../shared/components/Modal';
import { ToastContainer, toast } from 'react-toastify';
import PrimaryButton from '../../shared/components/PrimaryButton';
import { useStoreState, useStoreActions } from 'easy-peasy';
import 'react-toastify/dist/ReactToastify.css';

// import icons
import DeleteIcon from '../../assets/images/svg/delete-icon.svg';
import EditIcon from '../../assets/images/svg/edit-icon.svg';
import FolderIcon from '../../assets/images/svg/folder.svg';
import Search from '../../assets/images/svg/search.svg';
import AttentionIcon from '../../assets/images/svg/attention.svg';

//env value
const customerName = process.env.REACT_APP_CUSTOMER_NAME;

const Customers = () => {
  //init navigation
  const navigate = useNavigate();

  //fun navigation
  const navigation = (id, state) => {
    navigate(`./worksite/${id}`, { state: state });
  };

  //init store
  const setActivity = useStoreActions((action) => action.setActivity);

  //init state
  const [ListeCustomers, setListeCustomers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [idCustomerSelected, setIdCustomerSelected] = useState(null);
  const [customerList, setCustomerList] = useState(ListeCustomers);
  const [textSearch, setTextSearch] = useState('');

  // func filter search, Listen
  useEffect(() => {
    if (textSearch === '') {
      setCustomerList(ListeCustomers);
    } else {
      const tabFilter = ListeCustomers.filter((element) =>
        element.name.toLowerCase().includes(textSearch.toLowerCase())
      );
      setCustomerList(tabFilter);
    }
  }, [textSearch, ListeCustomers]);

  //Effect
  useEffect(() => {
    callCustomers();
  }, []);

  // fun call list Customers
  const callCustomers = () => {
    setActivity(true);
    middlewareCompany.getlistCompanies(customerName).then((result) => {
      if (result.statut) {
        setListeCustomers(result.data);
      } else {
        toast.error('❌ Une erreur est survenue lors de la récupération de la liste des Clients 🤯');
        console.log('Error: ', result.error);
      }
      setActivity(false);
    });
  };

  // fun click Customer
  const deleteAction = (id) => {
    setShowModal(true);
    setIdCustomerSelected(id);
  };

  // fun delete Customer
  const deleteCustomer = () => {
    middlewareCompany.deleteCompany(idCustomerSelected.id).then((result) => {
      if (result.statut) {
        setShowModal(false);
        callCustomers();
      }
    });
  };

  //Render
  return (
    <>
      <section className="px-14">
        <div>
          <div className="flex items-center justify-between pb-4 border-b pt-11 border-white-admin">
            <h3 className="font-semibold">Liste des clients</h3>
            <PrimaryButton
              title="+ Ajouter un nouveau client"
              link="add"
            />
          </div>
          <div className="relative flex mt-6 border border-white-admin rounded-xl ">
            <input
              type="text"
              placeholder="Recherche client"
              className="w-full py-3 pl-10 border-none rounded-xl placeholder:text-blue-admin inputFocus"
              onInput={(e) => setTextSearch(e.target.value)}
            />
            <img
              src={Search}
              alt="search icon"
              className="absolute left-0 flex items-center w-8 h-5 pl-3 top-4"
            />
          </div>
          <div className="overflow-auto pt-14">
            <table className="w-full">
              <thead className="h-10">
                <tr>
                  <th>Nom du client</th>
                  <th className="pl-10 xl:pl-24">N° de siret</th>
                  <th className="pl-10 xl:pl-24">Adresse de facturation</th>
                  <th className="pl-10 xl:pl-24">Téléphone</th>
                  <th className="pl-10 xl:pl-24">Actions</th>
                </tr>
              </thead>
              <tbody>
                {customerList.length ? (
                  customerList.map((Customer) => {
                    return (
                      <tr
                        key={Customer.id}
                        className="h-16 border-b border-white-admin hover:bg-blue-50 transition-all cursor-pointer"
                      >
                        <td onClick={() => navigation(Customer.id, Customer)}>{Customer.name}</td>
                        <td
                          className="pl-10 xl:pl-24"
                          onClick={() => navigation(Customer.id, Customer)}
                        >
                          {Customer?.siret || ''}
                        </td>
                        <td
                          className="pl-10 xl:pl-24"
                          onClick={() => navigation(Customer.id, Customer)}
                        >
                          {Customer.address}
                        </td>
                        <td
                          className="pl-10 xl:pl-24"
                          onClick={() => navigation(Customer.id, Customer)}
                        >
                          {Customer.phone}
                        </td>
                        <td className="flex items-center pt-4 pl-10 space-x-4 xl:pl-24 ">
                          <Link
                            to={`./worksite/${Customer.id}`}
                            state={Customer}
                          >
                            <button className="w-8 p-2 border rounded-lg border-white-admin">
                              <img
                                src={FolderIcon}
                                alt="Worksite icon"
                              />
                            </button>
                          </Link>
                          <Link
                            to={`./edit/${Customer.id}`}
                            state={Customer}
                          >
                            <button className="w-8 p-2 border rounded-lg border-white-admin">
                              <img
                                src={EditIcon}
                                alt="edit icon"
                              />
                            </button>
                          </Link>
                          <button
                            className="w-8 p-2 border rounded-lg border-white-admin"
                            onClick={() => deleteAction(Customer)}
                          >
                            <img
                              src={DeleteIcon}
                              alt="delete icon"
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      className="text-center text-lg font-semibold pt-20"
                      colSpan={6}
                    >
                      {textSearch ? "Oups, ce client n'existe pas 🤭" : 'Ajouter un client'}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <Modal isVisible={showModal}>
        <div>
          <div className="pt-5 pl-5">
            <img
              src={AttentionIcon}
              alt="attention icon"
            />
            <p className="pl-5 pt-7">Supression du client: {idCustomerSelected?.name}</p>
            {idCustomerSelected?.worksites?.items?.length ? (
              <>
                <p className="px-5 pt-2 text-gray-admin">
                  Merci de veiller à supprimer tous les chantiers de votre client avant de le retirer de votre liste
                </p>
                <div className="text-center pt-7">
                  <button
                    className="px-12 py-3 mr-4 font-semibold border rounded-xl text-red-error"
                    onClick={() => setShowModal(false)}
                  >
                    ok
                  </button>
                </div>
              </>
            ) : (
              <>
                <p className="px-5 pt-2 text-gray-admin">
                  Êtes-vous sûre de vouloir supprimer ce client ? Cette action est définitive.
                </p>
                <div className="text-center pt-7">
                  <button
                    className="px-12 py-3 mr-4 font-semibold border rounded-xl text-red-error"
                    onClick={() => setShowModal(false)}
                  >
                    Annuler
                  </button>
                  <button
                    className="px-10 py-3 text-white bg-red-error rounded-xl"
                    onClick={deleteCustomer}
                  >
                    Supprimer
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
      />
    </>
  );
};

export default Customers;
