import { useRef } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import middleworksite from '../../../services/middlewares/middlewareWorksite';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import icons
import closeIcon from '../../../assets/images/svg/icon_close.svg';

// data format
import { dateFormat } from '../../tools/manager';

const EditWorksite = () => {
  //init store
  const setActivity = useStoreActions((action) => action.setActivity);
  //init navigation
  const navigate = useNavigate();

  //url params
  const { idCustomer } = useParams();

  //Global store
  const categories = useStoreState((state) => state.categories);

  //init default values chantier
  const currentWorkSite = useLocation().state;

  //no data navigate to Marie©
  if (!currentWorkSite) {
    if (idCustomer) {
      window.location.href = `/customers/worksite/${idCustomer}`;
    } else {
      window.location.href = '/customers';
    }
  }

  //init ref
  const currentDate = useRef(dateFormat(currentWorkSite.date_planned));

  //Schema form
  const schema = yup.object().shape({
    name: yup.string().required('Ce champ est requis.'),
    address: yup.string().required('Ce champ est requis.'),
    date: yup.date().required('Ce champ est requis.'),
  });

  //config form verifications
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  //Submit fun
  const onSubmit = (data) => {
    const newDatas = { ...data, id: currentWorkSite.id };
    setActivity(true);
    middleworksite.updateWorksite(newDatas).then((result) => {
      if (result.statut) {
        navigate(-1);
      } else {
        toast.error('❌ Une erreur est survenue lors de la modification du chantier 🤯');
        console.log('Error: ', result.error);
      }
      setActivity(false);
    });
  };

  //Render
  return (
    <>
      <section>
        <div>
          <div className="pb-4 border-b mx-14 border-white-admin pt-14">
            <h3 className="font-semibold">Modifier un chantier</h3>
          </div>

          <div className="pt-11">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex items-center pb-4 mb-4 space-x-4 border-b mx-14 border-white-admin">
                <label htmlFor="name">Nom du chantier</label>
                <input
                  defaultValue={currentWorkSite.name}
                  id="name"
                  className="w-[318px] py-3 pl-5 border border-white-admin rounded-lg text-sm input"
                  placeholder="Nom du chantier"
                  {...register('name')}
                />
                <p className="text-xs text-red-500">{errors.name?.message}</p>
              </div>

              <div className="flex items-center pb-4 mb-4 space-x-4 border-b mx-14 border-white-admin">
                <label>Type de client</label>
                {categories.length &&
                  categories.map((cat) => (
                    <div key={cat.id}>
                      <input
                        className="w-4 h-4 checkedFocus"
                        type="radio"
                        id={cat.name}
                        {...register('typeClient')}
                        value={cat.id}
                        defaultChecked={currentWorkSite.type.id === cat.id}
                      />
                      <label
                        className="ml-2"
                        htmlFor={cat.name}
                      >
                        {cat.name}
                      </label>
                    </div>
                  ))}
              </div>

              <div className="flex items-center pb-4 mb-4 space-x-4 border-b mx-14 border-white-admin">
                <label htmlFor="address">Adresse postale du chantier</label>
                <input
                  defaultValue={currentWorkSite.address}
                  id="address"
                  className="w-[318px] py-3 pl-5 border border-white-admin rounded-lg text-sm input"
                  placeholder="22 rue du général de Gaulle 97236 Lamentin"
                  {...register('address')}
                />
                <p className="text-xs text-red-500">{errors.address?.message}</p>
              </div>

              <div className="flex items-center pb-4 mb-4 space-x-4 border-b mx-14 border-white-admin">
                <label htmlFor="date">Date de début du chantier</label>
                <input
                  defaultValue={`${currentDate.current.year}-${currentDate.current.month}-${currentDate.current.day}`}
                  id="date"
                  type="date"
                  className="w-[318px] py-3 pl-5 border border-white-admin rounded-lg text-sm input"
                  placeholder="22 rue du général de Gaulle 97236 Lamentin"
                  {...register('date')}
                />
                <p className="text-xs text-red-500">{errors.address?.message}</p>
              </div>

              <div className="flex items-center justify-between pt-4 mt-16 border-t border-white-admin">
                <div
                  onClick={() => navigate(-1)}
                  className="px-5 py-3 border rounded-lg cursor-pointer text-blue-admin ml-14 border-white-admin"
                >
                  Annuler
                </div>
                <div className="rounded-lg cursor-pointer mr-14 bg-blue-admin">
                  <button
                    type="submit"
                    className="px-5 py-3 font-semibold text-white"
                  >
                    Enregistrer les modifications
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={10000}
          hideProgressBar={false}
        />
      </section>
    </>
  );
};

export default EditWorksite;
