import React, { useEffect } from 'react';
import { useStoreActions } from 'easy-peasy';
import { MiddlewareType } from '../services/middlewares/middlewareType';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import Admin from '../pages/admin/Admin';
import Suppliers from '../pages/suppliers/Suppliers';
import WorkSite from '../pages/worksite/WorkSite';
import Customers from '../pages/Customers/Customers';
import Collaborators from '../pages/collaborators/Collaborator';
import NotFound from '../pages/NotFound';
import CDG from '../pages/CDG';
import EditAdmin from '../shared/components/admin/EditAdmin';
import AddAdmin from '../shared/components/admin/AddAdmin';
import EditCollaborators from '../shared/components/collaborators/EditCollaborators';
import AddCollaborators from '../shared/components/collaborators/AddCollaborators';
import DashboardHome from '../shared/layout/DashboardHome';
import AddSuppliers from '../shared/components/suppliers/AddSuppliers';
import EditSuppliers from '../shared/components/suppliers/EditSuppliers';
import AddCustomer from '../shared/components/Customers/AddCustomer';
import EditCustomer from '../shared/components/Customers/EditCustomer';
import AddWorksite from '../shared/components/worksite/AddWorksite';
import EditWorksite from '../shared/components/worksite/EditWorksite';
import Settings from '../pages/Settings';
import Order from '../pages/Order';
import PurchaseOrder from '../shared/components/order/PurchaseOrder';
import WorkOrder from '../shared/components/order/WorkOrder';
import Login from '../pages/Login';
import RequireAuth from '../shared/components/RequireAuth';
import PurchaseOrderSettings from '../shared/components/settings/PurchaseOrderSettings';
import WorkOrderSettings from '../shared/components/settings/WorkOrderSettings';
import AddNewMaterialSettings from '../shared/components/settings/AddNewMaterialSettings';
import EditMaterialSettings from '../shared/components/settings/EditMaterialSettings';
import userMidelware from '../services/middlewares/user';
import { Auth } from 'aws-amplify';
import AddTypeInterventionSettings from '../shared/components/settings/AddTypeInterventionSettings';
import EditTypeInterventionSettings from '../shared/components/settings/EditTypeInterventionSettings';
import PrefillOrder from '../shared/components/order/PrefillOrder';
import PdfPurchaseOrder from '../shared/components/order/PdfPurchaseOrder';
import PdfWorkOrder from '../shared/components/order/PdfWorkOrder';
import PdfPreIntervCoupon from '../shared/components/order/PdfPreIntervCoupon';
import ClassifyOrder from '../pages/ClassifyOrder';
import PrefillPurchaseOrder from '../shared/components/order/PrefillPurchaseOrder';
import ListClassifyOrderCoupon from '../shared/components/classifyOrder/ListClassifyOrderCoupon';
import ListClassifyInterventionCoupon from '../shared/components/classifyOrder/ListClassifyInterventionCoupon';
import EditPrefillPurshaseOrder from '../shared/components/order/EditPrefillPurshaseOrder';
import EditPrefillWorkOrder from '../shared/components/order/EditPrefillWorkOrder';
import AllCoupons from '../pages/AllCoupons';
import AllCouponsOrders from '../shared/components/allCoupons/Orders';
import AllCouponsInterventions from '../shared/components/allCoupons/Interventions';
import AllCtmCouponsInterventions from '../shared/components/allCoupons/intercentionCtm';
import PdfPreview from '../pages/PdfPreview';

const AdminRouter = () => {
  //Global store
  const setRoleUsers = useStoreActions((actions) => actions.setRoleUsers);
  const setCategories = useStoreActions((actions) => actions.setCategories);
  const setTypeCompanies = useStoreActions((actions) => actions.setTypeCompanies);
  const setcurrentUser = useStoreActions((actions) => actions.setcurrentUser);

  useEffect(() => {
    MiddlewareType.getListRoleUser().then((res) => {
      res.statut && setRoleUsers(res.data);
    });
    MiddlewareType.getListCategory().then((res) => {
      res.statut && setCategories(res.data);
    });
    MiddlewareType.getListTypeCompanies().then((res) => {
      res.statut && setTypeCompanies(res.data);
    });
    Auth.currentAuthenticatedUser().then((res) => {
      userMidelware.getUser(res.username).then((res) => {
        if (res.statut) {
          setcurrentUser(res.data);
        }
      });
    });
  }, []);

  return (
    <Routes>
      <Route
        path="/login"
        element={<Login />}
      />
      <Route element={<Home />}>
        <Route element={<RequireAuth />}>
          <Route
            path="/"
            element={<DashboardHome />}
          />
          {/* Protected routes */}
          <Route path="admin">
            <Route
              index
              element={<Admin />}
            />
            <Route
              path="edit/:id"
              element={<EditAdmin />}
            />
            <Route
              path="add"
              element={<AddAdmin />}
            />
          </Route>
          <Route path="pdf-preview">
            <Route
              index
              element={<PdfPreview />}
            />
            <Route
              path="order/:idpurchaseorder"
              element={<PdfPurchaseOrder />}
            />
            <Route
              path="intervention/:idworkorder"
              element={<PdfWorkOrder />}
            />

            <Route
              path="Pre-intervention/:idworkorder"
              element={<PdfPreIntervCoupon />}
            />
          </Route>
          <Route path="suppliers">
            <Route
              index
              element={<Suppliers />}
            />
            <Route
              path="edit/:id"
              element={<EditSuppliers />}
            />
            <Route
              path="add"
              element={<AddSuppliers />}
            />
          </Route>
          <Route
            path="/classify-order"
            element={<ClassifyOrder />}
          >
            <Route
              path="ordercoupon"
              element={<ListClassifyOrderCoupon />}
            />
            <Route
              path="interventioncoupon"
              element={<ListClassifyInterventionCoupon />}
            />
          </Route>
          <Route
            path="/all-coupons"
            element={<AllCoupons />}
          >
            <Route
              path="purchaseorder"
              element={<AllCouponsOrders />}
            />
            <Route
              path="intervention"
              element={<AllCouponsInterventions />}
            />
            <Route
              path="interventionCtm"
              element={<AllCtmCouponsInterventions />}
            />
          </Route>
          <Route path="customers">
            <Route
              index
              element={<Customers />}
            />
            <Route
              path="edit/:id"
              element={<EditCustomer />}
            />
            <Route
              path="add"
              element={<AddCustomer />}
            />
            <Route path="worksite/:idCustomer">
              <Route
                index
                element={<WorkSite />}
              />
              <Route
                path="add"
                element={<AddWorksite />}
              />
              <Route
                path="edit/:idWorksite"
                element={<EditWorksite />}
              />
              <Route
                path="order/:idWorksite"
                element={<Order />}
              >
                <Route
                  path="purchaseorder"
                  element={<PurchaseOrder />}
                />
                <Route
                  path="purchaseorder/add"
                  element={<PrefillPurchaseOrder />}
                />
                <Route
                  path="purchaseorder/edit/:id"
                  element={<EditPrefillPurshaseOrder />}
                />
                <Route
                  path="purchaseorder/:idpurchaseorder"
                  element={<PdfPurchaseOrder />}
                />

                <Route
                  path="workorder"
                  element={<WorkOrder />}
                />
                <Route
                  path="workorder/add"
                  element={<PrefillOrder />}
                />
                <Route
                  path="workorder/edit/:idworkorder"
                  element={<EditPrefillWorkOrder />}
                />
                <Route
                  path="workorder/:idworkorder"
                  element={<PdfWorkOrder />}
                />
                <Route
                  path="workorder/pre/:idworkorder"
                  element={<PdfPreIntervCoupon />}
                />
              </Route>
            </Route>
          </Route>
          <Route path="collaborators">
            <Route
              index
              element={<Collaborators />}
            />
            <Route
              path="edit/:id"
              element={<EditCollaborators />}
            />
            <Route
              path="add"
              element={<AddCollaborators />}
            />
          </Route>
          <Route
            path="/settings"
            element={<Settings />}
          >
            <Route
              path="purchase-order"
              element={<PurchaseOrderSettings />}
            />
            <Route
              path="purchase-order/add"
              element={<AddNewMaterialSettings />}
            />
            <Route
              path="purchase-order/edit/:id"
              element={<EditMaterialSettings />}
            />
            <Route
              path="work-order"
              element={<WorkOrderSettings />}
            />
            <Route
              path="work-order/add"
              element={<AddTypeInterventionSettings />}
            />
            <Route
              path="work-order/edit/:id"
              element={<EditTypeInterventionSettings />}
            />
          </Route>
        </Route>
      </Route>
      <Route
        path="CDG"
        element={<CDG />}
      />
      <Route
        path="*"
        element={<NotFound />}
      />
    </Routes>
  );
};

export default AdminRouter;
