import axios from 'axios';

// * * * * config axios * * * * * //
const urlGraphQL = process.env.REACT_APP_URL_GRAPH_QL;
const options = {
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_API_KEY,
  },
};
// * * * * * * * * * * * * * * //

const middlewareWorksite = {
  // request graphQL get list Worksite  ###################
  getlistWorkSite: async (id_customer) => {
    const body = {
      query: `
        query MyQuery {
          getCompany(id: "${id_customer}") {
            id
            name
            worksites {
              items {
                users {
                  items {
                    id
                    user {
                      id
                      first_name
                      last_name
                    }
                  }
                }
                address
                date_planned
                id
                type {
                  name
                  id
                }
                name
                updatedAt
                status
              }
            }
          }
        }
        `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);
      return {
        statut: true,
        data: request.data.data.getCompany.worksites.items,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  // request graphQL update Status Worksite  ###################
  updateStatusWorkSite: async (data) => {
    const body = {
      query: `
        mutation MyMutation {
          updateWorksite(input: {id: "${data.id}", status: "${data.status}"}) {
            id
          }
        }      
        `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);
      return {
        statut: true,
        data: request.data.data.updateWorksite,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  //request graphQL create Worksite ###################
  createWorksite: async (data) => {
    const body = {
      query: `
        mutation MyMutation {
            createWorksite(input: {
              name: "${data.name}",
              address: "${data.address}",
              date_planned: "${data.date}",
              typeWorksitesId: "${data.typeClient}",
              companyWorksitesId: "${data.id_customer}",
              status: "En cours",
            })
            {
              id
            }
          } 
        `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);
      return { statut: true, data: request.data.data.createWorksite };
    } catch (error) {
      return { statut: false, error };
    }
  },

  //request graphQL update Worksite ###################
  updateWorksite: async (data) => {
    const body = {
      query: `
        mutation MyMutation {
          updateWorksite(input: {
            id: "${data.id}", 
            name: "${data.name}", 
            address: "${data.address}", 
            date_planned: "${data.date}", 
            typeWorksitesId: "${data.typeClient}",
          })
          {
            id
          }
        } 
        `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);
      return { statut: true, data: request.data.data.updateWorksite.items };
    } catch (error) {
      return { statut: false, error };
    }
  },

  //request graphQL delete Worksite ###################
  deleteWorksite: async (id) => {
    const body = {
      query: `
        mutation MyMutation {
            deleteWorksite(input: {id: "${id}"}) {
              id
            }
          }
        `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);
      return { statut: true, data: request.data.data.deleteWorksite.items };
    } catch (error) {
      return { statut: false, error };
    }
  },

  //request graphQL create user relation Worksite ###################
  createWorksiteUsers: async (data) => {
    const body = {
      query: `
        mutation MyMutation {
          createWorksiteUsers(input: {userID: "${data.id_user}", worksiteID: "${data.id_worksite}"}) {
            id
          }
        }        
        `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);
      return {
        statut: true,
        data: request.data.data.createWorksiteUsers.items,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  //request graphQL delete user relation Worksite ###################
  deleteWorksiteUsers: async (id) => {
    const body = {
      query: `
        mutation MyMutation {
          deleteWorksiteUsers(input: {id: "${id}"}) {
            id
          }
        }                
        `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);
      return {
        statut: true,
        data: request.data.data.deleteWorksiteUsers.items,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  getWorkSiteListOrderCoupon: async (id_worksite) => {
    const body = {
      query: `
        query MyQuery {
          getWorksite(id: "${id_worksite}") {
          order_coupon {
        items {
          id
          createdAt
          name
          numero
          updatedAt
          ctm
        }
      }
    }
        }
        `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);
      return {
        statut: true,
        data: request.data.data.getWorksite.order_coupon.items,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  getWorkSiteListInterventionCoupon: async (id_worksite) => {
    const body = {
      query: `
        query MyQuery {
         getWorksite(id: "${id_worksite}") {
    intervention_coupon {
      items {
        createdAt
        id
        name
        numero
        ctm
        updatedAt
      }
    }
  }
        }
        `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);
      return {
        statut: true,
        data: request.data.data.getWorksite.intervention_coupon.items,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  getlistWorksiteUsers: async (id_worksite) => {
    const body = {
      query: `
      query MyQuery {
        listWorksiteUsers(filter: {worksiteID: {eq: "${id_worksite}"}}) {
          items {
            id
          }
        }
      }
        `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);
      return {
        statut: true,
        data: request.data.data.listWorksiteUsers.items,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  DeleteMultipleWorksiteUsers: async (listWorksiteUsers) => {
    const body = {
      query: `
        mutation MyMutation {
          ${listWorksiteUsers.map(
            (WorksiteUser, index) => `
            task${index}: deleteWorksiteUsers(input: {id: "${WorksiteUser.id}"}) {
              id
            }
          `
          )}
        }      
      `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);
      return {
        statut: true,
        data: request.data.data,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },
};

export default middlewareWorksite;
