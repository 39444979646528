import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PrimaryButton from '../PrimaryButton';
import { useStoreActions } from 'easy-peasy';

import DeleteIcon from '../../../assets/images/svg/delete-icon.svg';
import EditIcon from '../../../assets/images/svg/edit-icon.svg';
import WarningModal from '../../../assets/images/svg/warning-modal.svg';
import { middlewareSettings } from '../../../services/middlewares/middlewareSettings';
import Modal from '../Modal';

const ListMaterials = () => {
  //init store
  const setActivity = useStoreActions((action) => action.setActivity);

  const [materials, setMaterials] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [idMaterial, setIdMaterial] = useState(null);

  useEffect(() => {
    getMateriel();
  }, []);

  const getMateriel = () => {
    setActivity(true);
    middlewareSettings.getListMaterial().then((res) => {
      if (res.statut) {
        setMaterials(res.data);
      }
      setActivity(false);
    });
  };

  const handleDelete = (id) => {
    setShowModal(true);
    setIdMaterial(id);
  };

  const deleteMaterial = () => {
    setActivity(true);
    middlewareSettings.deleteMaterial(idMaterial).then((result) => {
      if (result.statut) {
        setShowModal(false);
        getMateriel();
      }
      setActivity(false);
    });
  };

  return (
    <>
      <section className="mx-14">
        <div>
          <div className=" pb-4 pt-11 border-b border-white-admin flex items-center justify-between ">
            <h3 className="font-semibold">Matériel</h3>
            <PrimaryButton
              title="+ Ajouter un nouveau matériel"
              link="add"
            />
          </div>
        </div>
        <div className="relative overflow-auto pt-14">
          <table className="w-full">
            <thead className="h-10">
              <tr>
                <th>Nom du matériel</th>
                <th className="pl-40 xl:pl-80">Nb de référence</th>
                <th className="float-right pr-5 ">Actions</th>
              </tr>
            </thead>
            <tbody>
              {materials?.map((material) => {
                return (
                  <tr
                    key={material.id}
                    className="h-16 border-b border-white-admin hover:bg-blue-50 transition-all"
                  >
                    <td className="pr-14 xl:pr-20 ">{material.name}</td>
                    <td className="pl-40 xl:pl-80 ">
                      {material.references.length ? (
                        <div className="flex space-x-2">
                          <span>{material.references[0].reference.split(',').length}</span>

                          <span>
                            {material.references[0].reference.split(',').length > 1 ? 'références' : 'référence'}
                          </span>
                        </div>
                      ) : (
                        <>
                          <span> 0 </span>
                          <span>référence</span>
                        </>
                      )}
                    </td>
                    {material.name !== 'Climatiseur' && (
                      <td className="flex items-center float-right pt-4 space-x-4 ">
                        <Link
                          to={`./edit/${material.id}`}
                          state={material}
                        >
                          <button className="w-8 p-2 border rounded-lg border-white-admin">
                            <img
                              src={EditIcon}
                              alt="edit icon"
                            />
                          </button>
                        </Link>
                        <button
                          className="w-8 p-2 border rounded-lg border-white-admin"
                          onClick={() => handleDelete(material.id)}
                        >
                          <img
                            src={DeleteIcon}
                            alt="delete icon"
                          />
                        </button>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Modal isVisible={showModal}>
          <div>
            <div className="pt-5 pl-5">
              <img
                src={WarningModal}
                alt="warning icon"
              />
            </div>
            <h4 className="pl-5 pt-7">Suppresion du Matériel</h4>
            <p className="px-5 pt-2 text-sm text-gray-admin">
              Voulez-vous vraiment supprimer ce matériel de façon permanente ?
            </p>
            <div className="text-center pt-7">
              <button
                className="px-12 py-3 mr-4 font-semibold border rounded-xl text-red-error"
                onClick={() => setShowModal(false)}
              >
                Annuler
              </button>
              <button
                className="px-10 py-3 text-white bg-red-error rounded-xl"
                onClick={deleteMaterial}
              >
                Supprimer
              </button>
            </div>
          </div>
        </Modal>
      </section>
    </>
  );
};

export default ListMaterials;
