import React from 'react';
import { Link } from 'react-router-dom';

const PrimaryButton = ({ title, link = '', onclick, style, state }) => {
  return (
    <>
      {!!link ? (
        <Link
          className={`px-4 py-3 text-white rounded-lg cursor-pointer bg-blue-admin ${style}`}
          to={link}
          state={state}
        >
          {title}
        </Link>
      ) : (
        <button
          className={`px-4 py-3 text-white rounded-lg cursor-pointer bg-blue-admin`}
          onclick={onclick}
        >
          {title}
        </button>
      )}
    </>
  );
};

export default PrimaryButton;
