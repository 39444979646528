import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

const NavigationOrder = ({ currentWorkSite }) => {
  //init state
  const [toggleActive, setToggleActive] = useState(false);

  //init url params
  const urlParams = useParams();

  //init effect
  useEffect(() => {
    const currentPath = urlParams['*'].split('/');
    if (currentPath.includes('purchaseorder')) {
      setToggleActive(false);
    } else {
      setToggleActive(true);
    }
  }, [urlParams]);

  //Render
  return (
    <section className="mx-14">
      <div className="pb-4 border-b pt-11 border-white-admin">
        <h3 className="font-semibold">{currentWorkSite?.name}</h3>
      </div>
      <div className="pb-4 border-b pt-11 border-white-admin">
        <ul className="flex space-x-8">
          <li className="text-blue-admin">
            <Link
              to="purchaseorder"
              state={currentWorkSite}
              className={toggleActive ? null : `borderBottomActive`}
            >
              Bons de commande
            </Link>
          </li>
          <li className="text-blue-admin">
            <Link
              to="workorder"
              state={currentWorkSite}
              className={toggleActive ? `borderBottomActive` : null}
            >
              Bons d’intervention
            </Link>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default NavigationOrder;
