import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { useReactToPrint } from 'react-to-print';
import base64 from 'react-native-base64';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/pdf.css';
import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale'; // Importez la locale française si nécessaire

//import middleware
import middlewareCouponPreInter from '../../../services/middlewares/middlewareCouponPreInter';

//import tool
import { dateFormat } from '../../tools/manager';

const PdfPreIntervCoupon = () => {
  // init navigation
  const navigate = useNavigate();
  const { idworkorder } = useParams();

  //init store
  const setActivity = useStoreActions((action) => action.setActivity);

  //init statw
  const [dataPdf, setDataPdf] = useState(null);
  const [mySvg, setMySvg] = useState('');

  const componentRef = useRef();
  const handlePdf = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'purchase-data',
    onAfterPrint: () => AudioListener('Print success'),
  });

  // call bon pre-rempli
  const CallGetPreCoupon = async (data) => {
    setActivity(true);
    try {
      const result = await middlewareCouponPreInter.getPreCoupon(idworkorder);
      setDataPdf(result);
      setMySvg(result?.signed ? base64.decode(result?.signed) : '');
    } catch (error) {
      toast.error('❌ #1 Une erreur est survenue durant le chargement des données du bon');
    } finally {
      setActivity(false);
    }
  };

  // first effect
  useEffect(() => {
    CallGetPreCoupon();
  }, []);

  
  const formattedDate = (updatedAt) => {
    const dateA = updatedAt ? parseISO(updatedAt) : new Date();
    return format(dateA, "EEEE dd MMMM yyyy 'à' HH:mm", {
      locale: fr,
    });
  };

  return (
    <section className="ml-32 mt-14 mb-10 flex space-x-7">
      {!!dataPdf && (
        <div className="shadow-2xl h-[1170px]">
          <div
            ref={componentRef}
            className="w-[827px] h-[1170px] rounded-lg"
          >
            <div className="bg-orange-clim rounded-lg text-sm text-orange-clim h-4" />
            <div className="flex flex-col pt-10 px-10 h-[98%]">
              <h3 className="text-xl my-0 font-semibold">Bon d'intervention pré rempli - N° {dataPdf?.numero || ''}</h3>
              <h3 className="text-base my-0 font-semibold">Bon d’intervention CTM - N° {dataPdf?.ctm || ''}</h3>
              {/* div infos main */}
              <div className="flex justify-between mt-5">
                {/* div infos rigth */}
                <div className="mr-28 flex flex-col space-y-2">
                  <h4 className="font-bold">SACARABANY SARL</h4>
                  <div className="text-sm text-gray-admin flex flex-col space-y-2">
                    <p>279 rue solitude la mulatresse</p>
                    <p>97200 Fort de France</p>
                    <p>SIRET : 495 262 131 00016</p>
                    <p>contact@sacarabany-sarl.fr</p>
                    <p>Créé le : {formattedDate(dataPdf?.createdAt)}</p>
                  </div>
                </div>
                {/* div infos left */}
                <div className="flex flex-col space-y-2">
                  <h4>{dataPdf?.worksites?.company?.name?.toUpperCase() || ''}</h4>
                  <div className="text-sm text-gray-admin flex flex-col space-y-2">
                    <p>{dataPdf?.worksites?.company?.address || ''}</p>
                    {dataPdf?.worksites?.company?.siret ? <p>SIRET : {dataPdf?.worksites?.company?.siret}</p> : ''}
                    <p>{dataPdf?.newMailClient || dataPdf?.worksites?.company?.mail?.split(',')[0] || ''}</p>
                    <p>{dataPdf?.worksites?.company?.phone || ''}</p>
                  </div>
                </div>
              </div>
              {/* div name worksite */}
              <div className="my-10 flex flex-col space-y-2">
                <h3 className="font-bold">Chantier : {dataPdf?.worksites?.name || ''}</h3>
                <p>Date de réalisation : {dataPdf?.dateSigned ? dateFormat(dataPdf?.dateSigned).full : '-'}</p>
                {dataPdf?.orderCoupon ? (
                  <p className="font-bold ">Lier au bon de commande N° {dataPdf?.orderCoupon}</p>
                ) : (
                  <></>
                )}
              </div>

              <table className="tableContainer">
                <thead>
                  <tr>
                    <th>Nature</th>
                    <th>Matériel</th>
                    <th>Référence</th>
                    <th>Quantité</th>
                    <th>Lieu Pose</th>
                    <th>Lieu dépôt</th>
                    <th>Surface</th>
                    <th>Commentaire</th>
                    <th>Statut</th>
                  </tr>
                </thead>
                <tbody>
                  {dataPdf?.materials.map((material) => (
                    <tr>
                      <td>{material?.nature || '-'}</td>
                      <td>{material?.material || '-'}</td>
                      <td>{material?.refName || '-'}</td>
                      <td>{material?.quantity || '-'}</td>
                      <td>{material?.pose || '-'}</td>
                      <td>{material?.depot || '-'}</td>
                      <td>{material?.surface || '-'}</td>
                      <td>{material?.comments || '-'}</td>
                      <td>{material?.statut ? '✔️' : ''}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="grow"></div>
              <div>
                <p className="container mt-10">signature :</p>
                {!!mySvg && (
                  <div
                    className="bg-cover container"
                    dangerouslySetInnerHTML={{ __html: mySvg }}
                  />
                )}
              </div>
              {/* footer */}
              <div className=" text-center text-gray-admin text-xs border-t border-gray-separate pt-5 pb-7">
                <h6>SACARABANY SARL SIRET : 495 262 131 00016 - APE : 453 A</h6>
                <p>279 rue solitude la mulatresse - 97200 Fort de France</p>
                <p>TEL : 0596 70 75 50 - 0696 45 02 08</p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col justify-between text-blue-admin h-[1170px]">
        <div className="border border-blue-admin py-4 px-3 rounded-xl">
          <button onClick={() => navigate(-1)}>Voir tous les bons d'intervention</button>
        </div>
        <button
          className="flex-end bg-blue-admin text-white py-4 rounded-xl"
          onClick={handlePdf}
        >
          Générer un PDF
        </button>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
      />
    </section>
  );
};

export default PdfPreIntervCoupon;
