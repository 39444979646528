import React, { useState, useEffect, useRef } from 'react';
import { useStoreActions } from 'easy-peasy';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

// import icons
import Search from '../../../assets/images/svg/search.svg';

// import middleware
import middlewareCouponPreInter from '../../../services/middlewares/middlewareCouponPreInter';

// format date
import { dateFormat, sorted } from '../../tools/manager';

const InterventionsCtm = () => {
  // Navigation
  const navigation = useNavigate();

  // services
  const setActivity = useStoreActions((action) => action.setActivity);

  // init ref
  const refIntereventionlist = useRef([]);

  // init state
  const [interventions, setInterventions] = useState([]);
  const [textSearch, setTextSearch] = useState('');

  // call list bon pre-rempli
  const CallPreInterCouponDone = async () => {
    setActivity(true);
    try {
      const result = await middlewareCouponPreInter.listPreCouponDone();
      const myList = sorted(result, 'numero', true);
      setInterventions(myList);
      refIntereventionlist.current = myList;
    } catch (error) {
      toast.error('❌ #1 Une erreur est survenue durant le chargement des bons');
    } finally {
      setActivity(false);
    }
  };

  // funcion filter search
  useEffect(() => {
    if (textSearch === '') {
      setInterventions(refIntereventionlist.current);
    } else {
      const tabFilter = refIntereventionlist.current.filter(
        (element) =>
          element?.worksites?.company?.name.toLowerCase().includes(textSearch.toLowerCase()) ||
          element?.worksites?.name?.toLowerCase().includes(textSearch.toLowerCase()) ||
          element?.numero.includes(textSearch)
      );
      setInterventions(tabFilter);
    }
  }, [textSearch]);

  // first effect
  useEffect(() => {
    CallPreInterCouponDone();
  }, []);

  // render
  return (
    <section className="mx-14">
      {/* div main */}
      <div className="h-[500px]">
        {/* div search bar */}
        <div className="relative flex mt-6 border border-white-admin rounded-xl">
          <input
            onInput={(e) => setTextSearch(e.target.value)}
            type="text"
            placeholder="Recherche (client ou chantier ou numéro)"
            className="w-full py-3 pl-10 border-none rounded-xl placeholder:text-blue-admin inputFocus"
          />

          <img
            src={Search}
            alt="search icon"
            className="absolute left-0 flex items-center w-8 h-5 pl-3 top-4"
          />
        </div>
        {/* table list */}
        <div className="overflow-auto pt-14 w-full h-full">
          <table className="w-full">
            <thead className="h-10">
              <tr>
                <th>Réalisé le : </th>
                <th className="pl-10 xl:pl-20">Numéro</th>
                <th className="pl-10 xl:pl-20">Type de chantier</th>
                <th className="pl-10 xl:pl-20">Nom du client</th>
                <th className="pl-10 xl:pl-20">Nom du chantier</th>
              </tr>
            </thead>
            <tbody>
              {interventions?.length ? (
                interventions?.map((intervCoupon) => {
                  const dateCouponCreate = dateFormat(intervCoupon?.dateSigned, '.').full;
                  return (
                    <tr
                      key={intervCoupon.id}
                      className={`h-16 border-b border-white-admin hover:bg-blue-50 transition-all cursor-pointer`}
                      onClick={() => {
                        navigation(`/pdf-preview/Pre-intervention/${intervCoupon.id}`);
                      }}
                    >
                      <td>{dateCouponCreate}</td>
                      <td className="pl-10 xl:pl-20">{intervCoupon?.numero}</td>
                      <td className="pl-10 xl:pl-20">{intervCoupon?.worksites?.type?.name}</td>
                      <td className="pl-10 xl:pl-20">{intervCoupon?.worksites?.company?.name}</td>
                      <td className="pl-10 xl:pl-20">{intervCoupon?.worksites?.name}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    className="text-center text-lg font-semibold pt-20"
                    colSpan={6}
                  >
                    {textSearch ? "Oups, ce chantier ou client n'existe pas 🤭" : "Ajouter un bon d'intervention"}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
      />
    </section>
  );
};

export default InterventionsCtm;

