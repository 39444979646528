import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { MiddlewareCollab } from '../../../services/middlewares/middlewareCollab';
import ConfigServices from '../../../services/awsSdk/cognitoServices';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useStoreState, useStoreActions } from 'easy-peasy';
import PrimaryButton from '../PrimaryButton';

// import icons
import DeleteIcon from '../../../assets/images/svg/delete-icon.svg';
import EditIcon from '../../../assets/images/svg/edit-icon.svg';
import AttentionIcon from '../../../assets/images/svg/attention.svg';
import Modal from '../Modal';
import Search from '../../../assets/images/svg/search.svg';

// .Env value
const collaboratorName = process.env.REACT_APP_COLLABORATOR_NAME;

const ListCollaborators = () => {
  //init store
  const setActivity = useStoreActions((action) => action.setActivity);

  //Etats (données)
  const [collabs, setCollabs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [idUser, setIdUser] = useState(null);
  const [listCollabs, setListCollabs] = useState(collabs);
  const [textSearch, setTextSearch] = useState('');

  // func filter search, Listen
  useEffect(() => {
    if (textSearch === '') {
      setListCollabs(collabs);
    } else {
      const tabFilter = collabs.filter(
        (element) =>
          element.first_name.toLowerCase().includes(textSearch.toLowerCase()) ||
          element.last_name.toLowerCase().includes(textSearch.toLowerCase())
      );
      setListCollabs(tabFilter);
    }
  }, [textSearch, collabs]);

  useEffect(() => {
    // si la réponse res est différente de error alors je set la réponse
    setActivity(true);
    MiddlewareCollab.getListCollabs(collaboratorName).then((res) => {
      // if (res.statut) console.log(res);
      if (res.statut) {
        setCollabs(res.data);
      } else {
        toast.error("❌ Une erreur est survenue lors de l'affichage des collaborateurs");
      }
      setActivity(false);
    });
  }, []);

  const deleteAction = (id) => {
    setShowModal(true);
    setIdUser(id);
  };
  const deleteUser = () => {
    // Delete user cognito
    ConfigServices.deleteUser(idUser)
      .then((res) => {
        //Delete user base de données
        setActivity(true);
        MiddlewareCollab.deleteUser(idUser).then((res) => {
          if (res.statut) {
            setShowModal(false);
            const newArrayCollabs = collabs.filter((collab) => collab.id !== res.data.id);
            setCollabs(newArrayCollabs);
          } else {
            toast.error('❌ Une erreur est survenue lors de la supppression du collaborateur');
          }
          setActivity(false);
        });
      })
      .catch((error) => {
        toast.error('❌ Une erreur est survenue lors de la supppression du collaborateur');
      });
  };
  //Affichage
  return (
    <section className="mx-14">
      {/* div main */}
      <div>
        {/* div title + add admin */}
        <div className="flex items-center justify-between pb-4 border-b pt-11 border-white-admin ">
          <h3 className="font-semibold">Liste des collaborateurs</h3>
          <PrimaryButton
            title="+ Ajouter un nouveau collaborateur"
            link="add"
          />
        </div>
        {/* div search bar */}
        <div className="relative flex mt-6 border border-white-admin rounded-xl">
          <input
            type="text"
            placeholder="Recherche collaborateur"
            className="w-full py-3 pl-10 border-none rounded-xl placeholder:text-blue-admin inputFocus"
            onInput={(e) => setTextSearch(e.target.value)}
          />

          <img
            src={Search}
            alt="search icon"
            className="absolute left-0 flex items-center w-8 h-5 pl-3 top-4"
          />
        </div>
        {/* table list */}
        <div className="overflow-auto pt-14">
          <table className="w-full">
            <thead className="h-10">
              <tr>
                <th>Prénom</th>
                <th className="pl-10 xl:pl-24">Nom</th>
                <th className="pl-10 xl:pl-24">Téléphone</th>
                <th className="pl-10 xl:pl-24">Catégorie</th>
                <th className="pl-10 xl:pl-24">Actions</th>
              </tr>
            </thead>
            <tbody>
              {listCollabs.length ? (
                listCollabs.map((collab) => {
                  return (
                    <tr
                      key={collab.id}
                      className="h-16 border-b border-white-admin hover:bg-blue-50 transition-all"
                    >
                      <td>{collab.first_name}</td>
                      <td className="pl-10 xl:pl-24">{collab.last_name}</td>
                      <td className="pl-10 xl:pl-24">{collab.phone}</td>
                      <td className="pl-10 xl:pl-24">{collab.type?.name}</td>
                      <td className="flex items-center pt-4 pl-10 space-x-4 xl:pl-24 ">
                        <Link
                          to={`./edit/${collab.id}`}
                          state={collab}
                        >
                          <button className="w-8 p-2 border rounded-lg border-white-admin">
                            <img
                              src={EditIcon}
                              alt="edit icon"
                            />
                          </button>
                        </Link>
                        <button
                          className="w-8 p-2 border rounded-lg border-white-admin"
                          onClick={() => deleteAction(collab.id)}
                        >
                          <img
                            src={DeleteIcon}
                            alt="delete icon"
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    className="text-center text-lg font-semibold pt-20"
                    colSpan={6}
                  >
                    {textSearch ? "Oups, ce collaborateur n'existe pas 🤭" : 'Ajouter un collaborateur'}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Modal isVisible={showModal}>
        <div>
          <div className="pt-5 pl-5">
            <img
              src={AttentionIcon}
              alt="attention icon"
            />
            <p className="pl-5 pt-7">Supression admin</p>
            <p className="px-5 pt-2 text-gray-admin">
              {' '}
              Êtes-vous sûre de vouloir supprimer ce collaborateur ? Cette action est définitive.
            </p>
            <div className="text-center pt-7">
              <button
                className="px-12 py-3 mr-4 font-semibold border rounded-xl text-red-error"
                onClick={() => setShowModal(false)}
              >
                Annuler
              </button>
              <button
                className="px-10 py-3 text-white bg-red-error rounded-xl"
                onClick={deleteUser}
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
      />
    </section>
  );
};

export default ListCollaborators;
