import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

const NavigationSettings = () => {
  //init state
  const [toggleActive, setToggleActive] = useState(false);

  //init url params
  const urlParams = useParams();

  //init effect
  useEffect(() => {
    const currentPath = urlParams['*'].split('/');
    if (currentPath.includes('purchase-order')) {
      setToggleActive(false);
    } else {
      setToggleActive(true);
    }
  }, [urlParams]);

  //Render
  return (
    <section className="mx-14">
      <div className="pt-11 pb-4 border-b border-white-admin">
        <h3 className="font-semibold">Paramètres</h3>
      </div>
      <div className="pt-11 pb-4 border-b border-white-admin">
        <ul className="flex space-x-8">
          <li className="text-blue-admin">
            <Link
              to="purchase-order"
              className={toggleActive ? null : `borderBottomActive`}
            >
              Bons de commande climatisation
            </Link>
          </li>
          <li className="text-blue-admin">
            <Link
              to="work-order"
              className={toggleActive ? `borderBottomActive` : null}
            >
              Bons d'intervention climatisation
            </Link>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default NavigationSettings;
