import React from 'react';
import { useStoreState } from 'easy-peasy';

const LoaderActivity = () => {
  // init store
  const activity = useStoreState((state) => state.activity);
  //Render
  return (
    <>
      {activity && (
        <section className="absolute w-full h-screen z-10 flex items-center justify-center">
          <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </section>
      )}
    </>
  );
};

export default LoaderActivity;
