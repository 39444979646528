// import image et icon
import ImgsGroup from '../assets/images/png/imgs-group.png';
import LogoSacarabany from '../assets/images/svg/logo-sacarabany-sarl.svg';
import ShowPwd from '../assets/images/png/eye.png';
import HidePwd from '../assets/images/png/eye-slash.png';

// import you need
import { useEffect, useRef, useState } from 'react';
import { useStoreActions } from 'easy-peasy';
import { Amplify, Auth } from 'aws-amplify';
import { awsAuthAdmin } from '../aws-config';
import { useLocation, useNavigate } from 'react-router-dom';

// import { accountService } from "../services/account_service";
import userMidelware from '../services/middlewares/user';

// customize hook
import useAuth from '../hooks/useAuth';

// aws configure for connexion
Amplify.configure(awsAuthAdmin);

// component Login
const Login = () => {
  //Global store
  const setcurrentUser = useStoreActions((actions) => actions.setcurrentUser);
  const { setAuth } = useAuth();

  // ref hook
  const userRef = useRef(null);
  const passwordRef = useRef(null);

  // State login
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [showPwd, setShowPwd] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  // hook for navigate
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from.pathname || '/';

  // useEffect
  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg('');
  }, [user, password]);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await Auth.signIn(user, password);
      userMidelware.getUser(response.username).then((res) => {
        if (res.statut) {
          setcurrentUser(res.data);
        }
      });
      const accessToken = response.signInUserSession.accessToken.jwtToken;
      localStorage.setItem('isLogged', true);
      setAuth({ user, password, accessToken });
      setUser('');
      setPassword('');
      navigate(from, { replace: true });
    } catch (error) {
      if (!error?.response) {
        setErrMsg('Mot de passe ou Email incorrect');
      } else if (error.response?.status === 400) {
        setErrMsg('Missing Email or Password');
      } else if (error.response?.status === 401) {
        setErrMsg('Unauthorized');
      } else {
        setErrMsg('Login failed');
      }
      passwordRef.current.focus();
    }
  };

  const toggleShowPwd = () => {
    setShowPwd(!showPwd);
  };

  // Function to handle the onClick event and update focus state
  const handleClick = () => {
    setIsFocused(true);
  };

  return (
    <>
      <section className="h-screen">
        <header className="pt-8 px-14 pb-16 ">
          <div className="pb-7 border-b border-gray-separate">
            <img
              src={LogoSacarabany}
              alt="logo sacarabany sarl"
            />
          </div>
        </header>
        <main className="flex space-x-24 mr-3">
          <div className="max-w-[752px]">
            <img
              src={ImgsGroup}
              alt="imgs group"
            />
          </div>
          <div className="pt-40 max-w-[400px]">
            <h1 className="font-semibold pb-5">Bienvenue sur l’admin Sacarabany SARL</h1>

            <h3 className="">Veuillez renseigner vos identifiants</h3>
            <form onSubmit={onSubmit}>
              <div className="flex flex-col pt-16">
                <div className="flex flex-col space-y-5 mb-10">
                  <input
                    value={user}
                    ref={userRef}
                    required
                    onChange={(e) => setUser(e.target.value)}
                    type="text"
                    name="email"
                    placeholder="exemple@sacarabanysarl.fr"
                    className="border border-gray-separate rounded-lg py-3 text-center placeholder:text-blue-admin"
                  />
                  <p
                    className={errMsg ? 'text-red-error text-sm' : ''}
                    aria-live="assertive"
                  >
                    {errMsg}
                  </p>
                  <div
                    onClick={handleClick}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    tabIndex={0}
                    className={`flex items-center justify-center space-x-2 rounded-lg px-2 ${isFocused ? 'border-[#003fe9] border-2' : 'border-gray-separate border' }`}
                  >
                    <input
                      value={password}
                      ref={passwordRef}
                      required
                      onChange={(e) => setPassword(e.target.value)}
                      type={showPwd ? 'text' : 'password'}
                      name="password"
                      id="password"
                      placeholder="********"
                      className="py-3 text-center placeholder:text-blue-admin w-full border-none focus:outline-none focus:ring-0 focus:shado"
                    />
                    {showPwd ? (
                      <img
                        src={HidePwd}
                        alt="hide password"
                        className="w-6 h-6 cursor-pointer"
                        onClick={toggleShowPwd}
                      />
                    ) : (
                      <img
                        src={ShowPwd}
                        alt="show password"
                        className="w-6 h-6 cursor-pointer"
                        onClick={toggleShowPwd}
                      />
                    )}
                  </div>
                  <p
                    className={errMsg ? 'text-red-error text-sm' : ''}
                    aria-live="assertive"
                  >
                    {errMsg}
                  </p>
                </div>
                <button
                  type="sumbit"
                  className="bg-blue-admin py-3 text-white rounded-lg cursor-pointer"
                >
                  Se connecter
                </button>
              </div>
            </form>
          </div>
        </main>
      </section>
    </>
  );
};

export default Login;
