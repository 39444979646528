import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Amplify, Auth } from 'aws-amplify';
import { awsAuthAdmin } from '../../../aws-config';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { MiddlewareAdmin } from '../../../services/middlewares/middlewareAdmin';
import ConfigServices from '../../../services/awsSdk/cognitoServices';
import { useStoreState, useStoreActions } from 'easy-peasy';

import ShowPwd from '../../../assets/images/png/eye.png';
import HidePwd from '../../../assets/images/png/eye-slash.png';

Amplify.configure(awsAuthAdmin);

// .Env value
const collaboratorName = process.env.REACT_APP_COLLABORATOR_NAME;

const AddAmin = () => {
  //init store
  const setActivity = useStoreActions((action) => action.setActivity);
  const roleUsers = useStoreState((state) => state.roleUsers);

  const roleAdmin = roleUsers.filter((adminRole) => adminRole.name !== collaboratorName);

  const navigate = useNavigate();
  const [statut, setStatut] = useState('');
  const [showPwd, setShowPwd] = useState(false);

  useEffect(() => {
    roleUsers.length && setStatut(roleAdmin[1].id);
  }, []);

  const passwordRegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*-_]).{8,}$/;
  const schema = yup.object().shape({
    firstName: yup.string().required('Ce champ est requis.'),
    lastName: yup.string().required('Ce champ est requis.'),
    mail: yup.string().required('Entrez une adresse email valide.').email('Email au format @gmail.com'),
    password: yup
      .string()
      .matches(
        passwordRegExp,
        'Votre mot de passe doit comporter au moins 8 caractères, une lettre majuscule, un chiffre et un caractère spécial.'
      ),
  });

  const toggleShowPwd = () => {
    setShowPwd(!showPwd);
  };


  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  // à faire rajouter une modal
  //La fonction signUp permet de créer un utilisateur
  const signUp = async (data) => {
    setActivity(true);
    try {
      const user = await Auth.signUp({
        username: data.mail,
        password: data.password,
        attributes: {
          email: data.mail,
        },
      });
      if (user.userSub) {
        const newData = {
          ...data,
          id_role_user: statut,
          id: user.userSub,
        };

        ConfigServices.setUserPassword(newData, true).then((response) => {
          MiddlewareAdmin.createAdmin(newData).then((res) => {
            if (res.statut) {
              navigate('/admin');
            }
            setActivity(false);
          });
        });
      }
    } catch (error) {
      console.log('error signing up:', error);
      setActivity(false);
    }
  };

  const onSubmit = (data) => {
    signUp(data);
  };

  return (
    <section>
      <div>
        {/* div main */}
        <div className="pb-4 border-b mx-14 border-white-admin pt-14">
          <h3 className="font-semibold">Créer un nouveau administrateur</h3>
        </div>
        {/* div form */}
        <div className="pt-11">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex items-center pb-4 space-x-4 border-b mx-14 border-white-admin">
              <label htmlFor="name">Prénom</label>
              <input
                className={errors.firstName ? 'w-[318px] input_error' : 'w-[318px] input'}
                id="name"
                type="text"
                placeholder="Prénom"
                autoComplete="none"
                {...register('firstName')}
              />
              <p className="text-xs text-red-error">{errors.firstName?.message}</p>
            </div>
            <div className="flex items-center py-5 space-x-4 border-b mx-14 border--white-admin">
              <label htmlFor="last-name">Nom</label>
              <input
                className={errors.lastName ? 'w-[318px] input_error' : 'w-[318px] input'}
                type="text"
                id="last-name"
                placeholder="Nom"
                {...register('lastName')}
              />
              <p className="text-xs text-red-error">{errors.lastName?.message}</p>
            </div>
            <div className="flex items-center py-5 space-x-4 border-b mx-14 border-white-admin">
              <label htmlFor="mail">Adresse mail</label>
              <input
                className={errors.mail ? 'w-[458px] input_error' : 'w-[458px] input'}
                type="text"
                id="mail"
                placeholder="Adresse mail"
                {...register('mail', {
                  pattern: {
                    value: /^\S+@\S+$/i,
                  },
                })}
              />
              <p className="text-xs text-red-error">{errors.mail?.message}</p>
            </div>
            <div className="flex items-center py-5 space-x-4 border-b mx-14 border-white-admin">
              <label htmlFor="password">Mot de passe</label>
              <div className="flex items-center space-x-2">
                <input
                  className={errors.password ? 'w-[318px] input_error' : 'w-[318px] input'}
                  type={showPwd ? 'text' : 'password'}
                  id="password"
                  placeholder="Mot de passe"
                  {...register('password')}
                />
                {showPwd ? (
                  <img
                    src={HidePwd}
                    alt="hide password"
                    className="w-6 h-6 cursor-pointer"
                    onClick={toggleShowPwd}
                  />
                ) : (
                  <img
                    src={ShowPwd}
                    alt="show password"
                    className="w-6 h-6 cursor-pointer"
                    onClick={toggleShowPwd}
                  />
                )}
              </div>

              <p className="text-xs text-red-error">{errors.password?.message}</p>
            </div>
            <div className="flex items-center py-5 space-x-4 mx-14">
              <label htmlFor="status-select">Statut</label>
              <select
                className="cursor-pointer text-blue-admin text-sm bg-blue-dropdown w-[330px] py-3 px-5 rounded-lg inputFocus border-none"
                name="status"
                id="status"
                onChange={(event) => setStatut(event.target.value)}
                value={statut}
              >
                {roleAdmin.length &&
                  roleAdmin.map((role) => (
                    <option
                      key={role.id}
                      value={role.id}
                    >
                      {role.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="flex items-center justify-between pt-4 mt-16 border-t border-white-admin ">
              <div
                onClick={() => navigate(-1)}
                className="px-5 py-3 border rounded-lg cursor-pointer ml-14 border-white-admin text-blue-admin"
              >
                Annuler
              </div>
              <div className="rounded-lg cursor-pointer mr-14 bg-blue-admin">
                <button
                  type="submit"
                  className="px-5 py-3 font-semibold text-white"
                >
                  Créer un nouveau administrateur
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default AddAmin;
