import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { middlewareSettings } from '../../../services/middlewares/middlewareSettings';
import { useStoreState, useStoreActions } from 'easy-peasy';

const AddTypeInterventionSettings = () => {
  //init store
  const setActivity = useStoreActions((action) => action.setActivity);

  const [typeIntervention, setTypeIntervention] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setActivity(true);
    middlewareSettings.createTypeIntervention({ typeIntervention }).then((res) => {
      if (res.statut) {
        navigate('/settings/work-order');
      }
      setActivity(false);
    });
  };
  return (
    <div>
      <div className="pt-11 pb-4 border-b border-white-admin mb-3 mx-14">
        <h3 className="font-semibold">Ajouter une autre nature d'intervention</h3>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex mx-14 space-x-4 items-center py-5">
          <label htmlFor="name">Nature d'intervention</label>
          <input
            className="w-[318px] py-3 pl-5 border border-white-admin rounded-lg text-sm"
            type="text"
            placeholder="Nature d'intervention"
            onChange={(e) => setTypeIntervention(e.target.value)}
          />
        </div>
        <div className="mt-64 pt-4 flex justify-between items-center border-t border-white-admin">
          <Link
            to="/settings/work-order"
            className="cursor-pointer ml-14 border border-white-admin rounded-lg"
          >
            <button className="py-3 px-5 text-blue-admin">Annuler</button>
          </Link>
          <div className=" cursor-pointer mr-14 bg-blue-admin rounded-lg">
            <button
              type="submit"
              className="py-3 px-5 text-white font-semibold"
            >
              Ajouter une nature d'intervention
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddTypeInterventionSettings;
