import React from 'react';
import { Link, useParams } from 'react-router-dom';

const NavigationAllCoupons = () => {
  //init url params
  const urlParams = useParams();
  const currentPath = urlParams['*'].split('/');

  return (
    <section className="mx-14">
      <div className="pb-4 border-b pt-11 border-white-admin">
        <h3 className="font-semibold">Liste des bons</h3>
      </div>
      <div className="pb-4 border-b pt-11 border-white-admin">
        <ul className="flex space-x-8">
          <li className="text-blue-admin">
            <Link
              to="purchaseorder"
              className={currentPath.includes('purchaseorder') ? `borderBottomActive` : null}
            >
              Bons de commande
            </Link>
          </li>
          <li className="text-blue-admin">
            <Link
              to="intervention"
              className={currentPath.includes('intervention') ? `borderBottomActive` : null}
            >
              Bons d’intervention
            </Link>
          </li>
          <li className="text-blue-admin">
            <Link
              to="interventionCtm"
              className={currentPath.includes('interventionCtm') ? `borderBottomActive` : null}
            >
              Bons d’intervention CTM
            </Link>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default NavigationAllCoupons;

