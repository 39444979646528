import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { ToastContainer, toast } from 'react-toastify';

// styles
import 'react-toastify/dist/ReactToastify.css';
import './css/pdf.css';

// import components
import Modal_2 from '../../components/Modal_2';

// import middleware
import middlewareCoupon from '../../../services/middlewares/middlewareCoupon';
import middlewareCouponPreInter from '../../../services/middlewares/middlewareCouponPreInter';

// import tool
import { filterMaterialData } from '../../tools/manager';

// import img
import DeleteIcon from '../../../assets/images/svg/delete-icon.svg';

// model data Material
const refMaterial = {
  nature: '',
  material: '',
  refName: '',
  quantity: '',
  pose: '',
  depot: '',
  surface: '',
  statut: false,
};

const EditPrefillWorkOrder = () => {
  //  🌱🌱  navigate  🌱🌱
  let navigate = useNavigate();
  const { idCustomer, idworkorder } = useParams();

  //  🌱🌱  init store  🌱🌱
  const setActivity = useStoreActions((action) => action.setActivity);

  //  🌱🌱  init state  🌱🌱
  const [preInter, setPreInter] = useState(null);
  const [customer, setCustomer] = useState();
  const [typeInterventions, setTypeInterventions] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [formError, setformError] = useState('');
  const [newMaterial, setNewMaterial] = useState(refMaterial);
  const [selectedLisReferences, setSelectedLisReferences] = useState([]);
  const [modeAdd, setmodeAdd] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [showModalDelete, setshowModalDelete] = useState(false);
  const [interSelectedDelete, setInterSelectedDelete] = useState(null);

  //  🌱🌱  call list nature intervention  🌱🌱
  const callListInterventionSettings = () => {
    middlewareCoupon.getListInterventionSettings().then((result) => {
      if (result.statut) {
        setTypeInterventions(result.data.items);
      } else {
        console.log('Error: ', result.error);
        window.location.href = '/customers';
      }
    });
  };

  //  🌱🌱  call list material and ref  🌱🌱
  const callListMaterialSettings = () => {
    middlewareCoupon.getListMaterialSettings().then((result) => {
      if (result.statut) {
        const data = result.data.items.filter((item) => item.name !== 'Climatiseur');
        setMaterials(data);
      } else {
        console.log('Error: ', result.error);
        window.location.href = '/customers';
      }
    });
  };

  //  🌱🌱  call customer and data worksite in coupon  🌱🌱
  const callGetCompagny = () => {
    setActivity(true);
    middlewareCoupon.getCompagny(idCustomer).then((result) => {
      if (result.statut) {
        setCustomer(result.data);
      } else {
        console.log('Error: ', result.error);
        window.location.href = '/customers';
      }
      setActivity(false);
    });
  };

  //  🌱🌱  call bon pre-rempli  🌱🌱
  const CallGetPreCoupon = async () => {
    setActivity(true);
    try {
      const result = await middlewareCouponPreInter.getPreCoupon(idworkorder);
      setPreInter(result);
    } catch (error) {
      toast.error('❌ #1 Une erreur est survenue durant le chargement des données du bon');
    } finally {
      setActivity(false);
    }
  };

  //  🌱🌱  call update materials bon pre-rempli  🌱🌱
  const CallUpdateListMareial = async (data) => {
    setActivity(true);
    try {
      await middlewareCouponPreInter.updateListMareial(data);
      setNewMaterial(refMaterial);
      setmodeAdd(false);
      CallGetPreCoupon();
    } catch (error) {
      toast.error('❌ #1 Une erreur est survenue durant la modification des données du bon');
    } finally {
      setActivity(false);
      setInterSelectedDelete(null);
      setshowModal(false);
    }
  };

  //  🌱🌱  call update materials bon pre-rempli  🌱🌱
  const CalldeleteCoupon = async () => {
    setActivity(true);
    try {
      await middlewareCouponPreInter.deleteInterventioncoupon(idworkorder);
      navigate(-1);
    } catch (error) {
      toast.error('❌ #1 Une erreur est survenue durant la suppression du bon');
    } finally {
      setActivity(false);
    }
  };

  //  🌱🌱  add line Material form  🌱🌱
  const addMaterial = (e) => {
    e.preventDefault();
    if (modeAdd) {
      if (isOkfrom()) {
        console.log('newMaterial: ', newMaterial);
        const myListMaterials = [...preInter?.materials, newMaterial];
        const newData = {
          id: idworkorder,
          materials: filterMaterialData(myListMaterials),
        };
        CallUpdateListMareial(newData);
      }
    } else {
      setmodeAdd(true);
    }
  };

  //  🌱🌱  action btn deleteMaterial  🌱🌱
  const deleteInter = (key) => {
    setInterSelectedDelete(key);
    setshowModal(true);
  };

  //  🌱🌱  set value Material  🌱🌱
  const changeValueMaterial = (value) => {
    const myMaterial = materials.find((el) => el.id == value.target.value);
    setNewMaterial({ ...newMaterial, material: myMaterial.name, refName: '' });
    setSelectedLisReferences(myMaterial.references[0].reference.split(','));
  };

  //   🌱🌱  deleteMaterial in coupon  🌱🌱
  const killInter = () => {
    const myListMaterials = [...preInter?.materials];
    myListMaterials.splice(interSelectedDelete, 1);
    const newData = {
      id: idworkorder,
      materials: filterMaterialData(myListMaterials),
    };
    CallUpdateListMareial(newData);
  };

  //   🌱🌱  Verif is all form not empty  🌱🌱
  const isOkfrom = () => {
    if (!newMaterial.nature) {
      setformError("Défaut d'une nature d'intervention");
      return false;
    }
    if (!newMaterial.material) {
      setformError("Défaut d'un nom de matériel");
      return false;
    }
    if (!newMaterial.refName) {
      setformError("Défaut d'une référence");
      return false;
    }
    if (newMaterial.quantity == '' || newMaterial.quantity < 0) {
      setformError("Défaut d'une quantité");
      return false;
    }
    return true;
  };

  //  🌱🌱  Effect disable msg error form  🌱🌱
  useEffect(() => {
    setformError('');
  }, [newMaterial]);

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callGetCompagny();
    callListInterventionSettings();
    callListMaterialSettings();
    CallGetPreCoupon();
  }, []);

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="pt-14">
      <div>
        {/* fisrt div form */}
        <div className="flex space-x-8 mx-14">
          <div className="flex flex-col space-y-4">
            <label htmlFor="client">Nom du client</label>
            <p className="w-[174px] rounded-lg placeholder:text-blue-admin py-4 pl-5 border border-white-admin">
              {customer?.name}
            </p>
          </div>
          <div className="flex flex-col space-y-4">
            <label htmlFor="client">BC CTM</label>
            <p className="w-[174px] rounded-lg placeholder:text-blue-admin py-4 pl-5 border border-white-admin">
              {preInter?.ctm || ''}
            </p>
          </div>
          <div className="flex flex-col space-y-4">
            <label htmlFor="client">Numéro du bon</label>
            <p className="w-[174px] rounded-lg placeholder:text-blue-admin py-4 pl-5 border border-white-admin">
              {preInter?.numero || ''}
            </p>
          </div>
        </div>
        <h5 className="font-semibold ml-14 mt-4">Etat des lieux des interventions</h5>
        {!preInter?.materials.length && (
          <p className="ml-20 my-5 italic text-red-error">Vous n'avez aucune intervention lier à ce bon !</p>
        )}
        {!!preInter?.materials.length && (
          <div className="mx-14 w-full mt-3">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="text-xs">Nature de l'intervention</th>
                  <th className="text-xs">Nom du matériel</th>
                  <th className="text-xs">Référence</th>
                  <th className="text-xs">Surface</th>
                  <th className="text-xs">Lieu de pose</th>
                  <th className="text-xs">Lieu dépôt</th>
                  <th className="text-xs">Quantité</th>
                  <th className="text-xs">Commentaire</th>
                  <th className="text-xs">Action</th>
                </tr>
              </thead>
              <tbody>
                {preInter?.materials.map((material, index) => (
                  <tr key={index}>
                    <td
                      className={`border ${
                        material?.statut ? 'border-blue-admin' : 'border-white-admin'
                      } pl-3 border-l-8`}
                    >
                      {material?.nature || '-'}
                    </td>
                    <td className={`border ${material?.statut ? 'border-blue-admin' : 'border-white-admin'} pl-3 py-1`}>
                      {material?.material || '-'}
                    </td>
                    <td className={`border ${material?.statut ? 'border-blue-admin' : 'border-white-admin'} pl-3 py-1`}>
                      {material?.refName || '-'}
                    </td>
                    <td className={`border ${material?.statut ? 'border-blue-admin' : 'border-white-admin'} pl-3 py-1`}>
                      {material?.surface || '-'}
                    </td>
                    <td className={`border ${material?.statut ? 'border-blue-admin' : 'border-white-admin'} pl-3 py-1`}>
                      {material?.pose || '-'}
                    </td>
                    <td className={`border ${material?.statut ? 'border-blue-admin' : 'border-white-admin'} pl-3 py-1`}>
                      {material?.depot || '-'}
                    </td>
                    <td className={`border ${material?.statut ? 'border-blue-admin' : 'border-white-admin'} pl-3 py-1`}>
                      {material?.quantity || '-'}
                    </td>
                    <td className={`border ${material?.statut ? 'border-blue-admin' : 'border-white-admin'} pl-3 py-1`}>
                      {material?.comments || '-'}
                    </td>
                    <td
                      className="border border-white pl-3 cursor-pointer animTrash py-1"
                      onClick={() => deleteInter(index)}
                    >
                      <img
                        src={DeleteIcon}
                        alt="delete icon"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className="mt-10 mx-14 overflow-x-auto">
          <h5 className="font-semibold mb-4">Ajouter une intervention</h5>
          {modeAdd && (
            <table>
              <thead>
                <tr>
                  <th>*Nature de l'intervention</th>
                  <th>*Nom du matériel</th>
                  <th>*Référence</th>
                  <th>Surface</th>
                  <th>Lieu de pose</th>
                  <th>Lieu dépôt</th>
                  <th>*Quantité</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-white border-r-8">
                    <select
                      className="mt-3 w-[240px] rounded-lg placeholder:text-blue-admin py-4 border border-white-admin text-blue-admin border-none inputFocus bg-blue-dropdown"
                      type="text"
                      onChange={(e) => setNewMaterial({ ...newMaterial, nature: e.target.value })}
                      value={newMaterial.nature}
                    >
                      <option
                        value=""
                        disabled
                      >
                        - Nature de l'intervention -
                      </option>
                      {typeInterventions?.map((typeIntervention) => {
                        return (
                          <option
                            key={typeIntervention.id}
                            value={typeIntervention.name}
                          >
                            {typeIntervention.name}
                          </option>
                        );
                      })}
                    </select>
                  </td>
                  <td className="border border-white border-x-8 ">
                    <select
                      className="rounded-lg py-4 border border-white-admin text-blue-admin input mt-3 w-[234px]"
                      type="text"
                      placeholder="-"
                      onChange={changeValueMaterial}
                      value={newMaterial.material}
                    >
                      <option
                        disabled
                        value=""
                      >
                        {' '}
                        - Nom du matériel -{' '}
                      </option>
                      {materials?.map((material) => {
                        return (
                          <option
                            key={material.id}
                            value={material.id}
                          >
                            {material.name}
                          </option>
                        );
                      })}
                    </select>
                  </td>
                  <td className="border border-white border-x-8">
                    <select
                      className="rounded-lg py-4 border border-white-admin text-blue-admin input mt-3 w-[234px]"
                      type="text"
                      placeholder="-"
                      onChange={(e) => setNewMaterial({ ...newMaterial, refName: e.target.value })}
                      value={newMaterial.refName}
                    >
                      <option
                        disabled
                        value={''}
                      >
                        -
                      </option>
                      {selectedLisReferences?.map((reference, index) => {
                        return (
                          <option
                            key={index}
                            value={reference}
                          >
                            {reference}
                          </option>
                        );
                      })}
                    </select>
                  </td>
                  <td className="border border-x-8 border-white">
                    <input
                      className="rounded-lg py-4 border border-white-admin input mt-3"
                      type="number"
                      placeholder="-"
                      onChange={(e) => setNewMaterial({ ...newMaterial, surface: e.target.value })}
                      value={newMaterial.surface}
                    />
                  </td>
                  <td className="border border-x-8 border-white">
                    <input
                      className="rounded-lg py-4 border border-white-admin input mt-3"
                      type="text"
                      placeholder="-"
                      onChange={(e) => setNewMaterial({ ...newMaterial, pose: e.target.value })}
                      value={newMaterial.pose}
                    />
                  </td>
                  <td className="border border-x-8 border-white">
                    <input
                      className="rounded-lg py-4 border border-white-admin input mt-3"
                      type="text"
                      placeholder="-"
                      onChange={(e) => setNewMaterial({ ...newMaterial, depot: e.target.value })}
                      value={newMaterial.depot}
                    />
                  </td>
                  <td className="border border-x-8 border-white">
                    <input
                      className="rounded-lg py-4 border border-white-admin input mt-3"
                      type="number"
                      placeholder="-"
                      onChange={(e) => setNewMaterial({ ...newMaterial, quantity: e.target.value })}
                      value={newMaterial.quantity}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          <div className="mt-5">
            <button
              className={`w-[280px] py-3  text-sm ${
                modeAdd ? 'text-white bg-blue-admin' : 'text-blue-admin'
              } rounded-lg border border-white-admin mb-2`}
              onClick={addMaterial}
            >
              + Ajouter une nouvelle intervention
            </button>
          </div>
        </div>
        {!!formError && <p className="text-red-error text-end mt-5 mr-20 italic">{formError}</p>}
        <div className="mt-16 py-4 flex justify-between items-center border-t border-white-admin">
          <button
            onClick={() => navigate(-1)}
            className="border rounded-lg cursor-pointer ml-14 border-white-admin px-5 py-3 text-blue-admin"
          >
            Retour
          </button>
          <button
            onClick={() => setshowModalDelete(true)}
            className="border rounded-lg cursor-pointer ml-14 bg-red-error mr-14 px-5 py-3 text-white"
          >
            Supprimer le bon
          </button>
        </div>
      </div>
      <Modal_2
        showModal={showModal}
        title={`Intervention`}
        text="Voulez-vouz vraiment supprimer cette intervention du bon ?"
        image={DeleteIcon}
      >
        <button
          className="px-4 py-3 text-white rounded-lg cursor-pointer bg-red-error w-full"
          onClick={killInter}
        >
          supprimer
        </button>
        <button
          className="px-4 py-3 text-blue-admin rounded-lg cursor-pointer border border-blue-admin mt-1 w-full"
          onClick={() => setshowModal(false)}
        >
          Annuler
        </button>
      </Modal_2>
      <Modal_2
        showModal={showModalDelete}
        title={`Bon d'intervention n°${preInter?.numero}`}
        text="Voulez-vouz vraiment supprimer ce bon d'intervention ?"
        image={DeleteIcon}
      >
        <button
          className="px-4 py-3 text-white rounded-lg cursor-pointer bg-red-error w-full"
          onClick={CalldeleteCoupon}
        >
          supprimer
        </button>
        <button
          className="px-4 py-3 text-blue-admin rounded-lg cursor-pointer border border-blue-admin mt-1 w-full"
          onClick={() => setshowModalDelete(false)}
        >
          Annuler
        </button>
      </Modal_2>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
      />
    </section>
  );
};

export default EditPrefillWorkOrder;
