import axios from 'axios';

// * * * * config axios * * * * * //
const urlGraphQL = process.env.REACT_APP_URL_GRAPH_QL;
const options = {
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_API_KEY,
  },
};
// * * * * * * * * *  * * * * * //

const middlewareCompany = {
  // request graphQL Create Company  ###################
  getlistCompanies: async (role_user) => {
    const body = {
      query: `
      query MyQuery {
        listTypeCompanies(filter: {name: {eq: "${role_user}"}}) {
          items {
            id
            name
            companies {
              items {
                address
                ctm
                id
                mail
                name
                phone
                siret
                worksites {
                  items {
                    id
                  }
                }
              }
            }
          }
        }
      }
        `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);
      return {
        statut: true,
        data: request.data.data.listTypeCompanies.items[0].companies.items,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  // request graphQL Create Company  ###################
  getCompanie: async (id) => {
    const body = {
      query: `
      query MyQuery {
        getCompany(id: "${id}") {
          address
          ctm
          id
          mail
          name
          phone
          siret
        }
      }      
        `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);
      return {
        statut: true,
        data: request.data.data.getCompany,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  // request graphQL Create Company ###################
  createCompany: async (data) => {
    const body = {
      query: `
                mutation MyMutation {
                    createCompany(input: {
                        address: "${data.address}", 
                        mail: "${data.mail}", 
                        name: "${data.name}", 
                        phone: "${data.phone}", 
                        siret: "${data.siret}",
                        typeCompanyCompaniesId: "${data.id_type_Company}",
                        ${data.typeClient === 'CTM' ? 'ctm: true' : 'ctm: false'}
                    })
                    {
                        id
                    }
                }
            `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);
      return { statut: true, data: request.data.data.createCompany.items };
    } catch (error) {
      return { statut: false, error };
    }
  },

  // request graphQL Update Company ###################
  updateCompany: async (data) => {
    const body = {
      query: `
                mutation MyMutation {
                    updateCompany(input: {
                        id: "${data.id}", 
                        name: "${data.name}",
                        siret: "${data.siret || ''}",
                        address: "${data.address}", 
                        mail: "${data.mail}", 
                        phone: "${data.phone}",
                        ${data.typeClient === 'CTM' ? 'ctm: true' : 'ctm: false'}
                    })
                    {
                        id
                    }
                }
            `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);
      return { statut: true, data: request.data.data.updateCompany.items };
    } catch (error) {
      return { statut: false, error };
    }
  },

  //request graphQL delete Company ###################
  deleteCompany: async (id) => {
    const body = {
      query: `
                mutation MyMutation {
                    deleteCompany(input: {id: "${id}"}) {
                    id
                    }
                }
              
            `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);
      return { statut: true, data: request.data.data.deleteCompany.items };
    } catch (error) {
      return { statut: false, error };
    }
  },

  getSacaCompany: async () => {
    const body = {
      query: `
      query MyQuery {
  listCompanies(filter: {name: {eq: "Sacarabany SARL"}}) {
    items {
      id
      name
    }
  }
}


        `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);
      return {
        statut: true,
        data: request.data.data.listCompanies.items,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },
};

export default middlewareCompany;
