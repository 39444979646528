import axios from 'axios';

//import components
import { lastNumberCoupon } from '../../shared/tools/manager';

// * * * * config axios * * * * * //
const urlGraphQL = process.env.REACT_APP_URL_GRAPH_QL;
const options = {
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_API_KEY,
  },
};
// * * * * * * * * * * * * * * //

const middlewareCouponPreInter = {
  //request graphQL call pre intervention coupon ###################
  getPreCoupon: async (id) => {
    const body = {
      query: `
        query MyQuery {
          getCouponPreInter(id: "${id}") {
          id
          ctm
          createdAt
          materials
          numero
          signed
          dateSigned
          orderCoupon
          newMailClient
          worksites {
              address
              name
              date_planned
              company {
                  name
                  mail
                  phone
                  siret
                  address
              }
          }
          }
      }
      `,
    };

    const request = await axios.post(urlGraphQL, body, options);
    const data = request?.data?.data?.getCouponPreInter;
    return { ...data, materials: JSON.parse(data?.materials) };
  },

  //request graphQL call liste pre intervention coupon by worksite ###################
  listPreCouponByWorksite: async (idWorksite) => {
    const body = {
      query: `
        query MyQuery {
            listCouponPreInters(filter: {couponPreInterWorksitesId: {eq: "${idWorksite}"}}) {
                items {
                    id
                    numero
                    materials
                    dateSigned
                    worksites {
                        date_planned
                    }
                }
            }
        }
        `,
    };

    const request = await axios.post(urlGraphQL, body, options);
    return request?.data?.data?.listCouponPreInters?.items;
  },

  //request graphQL call liste pre interventions finish (done) ###################
  listPreCouponDone: async (idWorksite) => {
    const body = {
      query: `
        query MyQuery {
            listCouponPreInters(filter: {dateSigned: {attributeExists: true}}) {
                items {
                    id
                    numero
                    dateSigned
                    worksites {
                      name
                      type {
                        name
                      }
                      company {
                        name
                      }
                    }
                }
            }
        }
        `,
    };

    const request = await axios.post(urlGraphQL, body, options);
    return request?.data?.data?.listCouponPreInters?.items;
  },

  //request graphQL call all pre intervention coupon numero ###################
  lastNumberPreInterv: async () => {
    const body = {
      query: `
        query MyQuery {
            listCouponPreInters {
            items {
                numero
            }
            }
        }
        `,
    };

    const request = await axios.post(urlGraphQL, body, options);
    const allData = request?.data?.data?.listCouponPreInters?.items;
    return lastNumberCoupon(allData, 'numero');
  },

  //request graphQL create pre intervention coupon ###################
  createPreInterv: async (data) => {
    const body = {
      query: `
        mutation MyMutation {
            createCouponPreInter(input: {
                ctm: "${data?.ctm}", 
                numero: "${data?.numero}",
                couponPreInterWorksitesId: "${data?.couponPreInterWorksitesId}",
                materials: "${JSON.stringify(data?.materials).replace(/\"/g, '\\"')}",
            }) 
            {
                id
            }
        }
        `,
    };

    const request = await axios.post(urlGraphQL, body, options);
    return request?.data?.data?.createCouponPreInter;
  },

  //request graphQL update materials pre intervention  ###################
  updateListMareial: async (data) => {
    const body = {
      query: `
      mutation MyMutation {
        updateCouponPreInter(input: {
          id: "${data.id}", 
          materials: "${JSON.stringify(data?.materials).replace(/\"/g, '\\"')}"
        })
        {
          id
        }
      }
        `,
    };

    const request = await axios.post(urlGraphQL, body, options);
    return request?.data?.data?.createCouponPreInter;
  },

  //request graphQL delete coupon pre intervention  ###################
  deleteInterventioncoupon: async (data) => {
    const body = {
      query: `
      mutation MyMutation {
        deleteCouponPreInter(input: {
          id: "${data}", 
        })
        {
          id
        }
      }
        `,
    };

    const request = await axios.post(urlGraphQL, body, options);
    return request?.data?.data?.deleteCouponPreInter;
  },
};

export default middlewareCouponPreInter;

