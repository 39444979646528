import axios from 'axios';

const urlGraphQL = process.env.REACT_APP_URL_GRAPH_QL;

const options = {
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_API_KEY,
  },
};

const MiddlewareCollab = {
  // Liste des collaborateurs(affiche la liste des collabs)

  getListCollabs: async (nameCollab) => {
    // Dans la requête des listUsers on filtre le rôle des users
    // Ici on ne souhaite que seul les collaborateurs apparaisent dans la requête donc on filtre avec l'aide de contains par leur id
    const body = {
      query: `query MyQuery {
        listRoleUsers(filter: {name: {contains: "${nameCollab}"}}) {
          items {
            name
            id
            users {
              items {
                first_name
                id
                last_name
                mail
                phone
                role_user {
                  name
                  id
                }
                type {
                  id
                  name
                }
              }
            }
          }
        }
      }
      `,
    };
    try {
      const request = await axios.post(urlGraphQL, body, options);
      return {
        statut: true,
        data: request.data.data.listRoleUsers.items[0].users.items,
      };
    } catch (error) {
      console.log(error);
      return { statut: false, error };
    }
  },
  //Add (Ajouter des collaborateurs)
  createCollab: async (data) => {
    const body = {
      query: `mutation MyMutation {
            createUser(
              input: {
                id: "${data.id}",
                first_name: "${data.firstName}",
                roleUserUsersId: "${data.id_role_user}",
                typeUsersId: "${data.id_type}",
                last_name: "${data.lastName}", 
                phone: "${data.phone}",
              })
              {
                id
                first_name
                phone
              }
            }`,
    };
    try {
      const request = await axios.post(urlGraphQL, body, options);
      return { statut: true, data: request.data.data.createUser };
    } catch (error) {
      console.log('error', error);
      return { statut: false, error };
    }
  },
  //Edit (Modifier les collaborateurs)
  updateCollab: async (data) => {
    const body = {
      query: `
        mutation 
        MyMutation {
          updateUser(input: 
            {
            first_name: "${data.firstName}", 
            id: "${data.id}",
              last_name: "${data.lastName}",
              phone:"${data.phone}",
              typeUsersId: "${data.id_type}",
              }
            )
            {
              id
              last_name
              first_name
              phone
            }
        }
        `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);
      return { statut: true, data: request.data.data.updateUser };
    } catch (error) {
      return { statut: false, error };
    }
  },

  // Delete user(supprimer un user)
  deleteUser: async (id) => {
    // body
    const body = {
      query: `mutation MyMutation {
              deleteUser(input: 
                {id: "${id}"}) 
                {
                id
                first_name
              }
            }
                  `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);
      return { statut: true, data: request.data.data.deleteUser };
    } catch (error) {
      return { statut: false, error };
    }
  },
};

export { MiddlewareCollab };
