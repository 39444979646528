import React, { useState, useEffect } from 'react';
import { useStoreActions } from 'easy-peasy';
import { useNavigate } from 'react-router-dom';

// import icons
import Search from '../../../assets/images/svg/search.svg';

import middlewareCoupon from '../../../services/middlewares/middlewareCoupon';

// format date
const dateFormat = (date) => {
  const temps = new Date(date);
  return {
    year: temps.getFullYear().toString(),
    month: ('0' + (temps.getMonth() + 1)).slice(-2),
    day: ('0' + temps.getDate()).slice(-2),
  };
};

const Orders = () => {
  const navigation = useNavigate();

  //services
  const setActivity = useStoreActions((action) => action.setActivity);

  //init state
  const [orderCoupons, setOrderCoupons] = useState([]);
  const [listorderCoupons, setListorderCoupons] = useState([]);
  const [textSearch, setTextSearch] = useState('');

  // fun request all coupon
  const getAllOrder = () => {
    setActivity(true);
    middlewareCoupon.allorders().then((result) => {
      if (result.statut) {
        setOrderCoupons(result.data);
        setActivity(false);
      } else {
        setActivity(false);
      }
    });
  };

  //effect call request all coupon
  useEffect(() => {
    getAllOrder();
  }, []);

  // funcion filter search
  useEffect(() => {
    if (textSearch === '') {
      setListorderCoupons(orderCoupons);
    } else {
      const tabFilter = orderCoupons.filter(
        (element) =>
          element?.worksite?.company?.name.toLowerCase().includes(textSearch.toLowerCase()) ||
          element?.worksite?.name?.toLowerCase().includes(textSearch.toLowerCase()) ||
          element?.numero.includes(textSearch)
      );
      setListorderCoupons(tabFilter);
    }
  }, [orderCoupons, textSearch]);

  //render
  return (
    <section className="mx-14">
      {/* div main */}
      <div className="h-[500px]">
        {/* div search bar */}
        <div className="relative flex mt-6 border border-white-admin rounded-xl">
          <input
            onInput={(e) => setTextSearch(e.target.value)}
            type="text"
            placeholder="Recherche (client ou chantier ou numéro)"
            className="w-full py-3 pl-10 border-none rounded-xl placeholder:text-blue-admin inputFocus"
          />

          <img
            src={Search}
            alt="search icon"
            className="absolute left-0 flex items-center w-8 h-5 pl-3 top-4"
          />
        </div>
        {/* table list */}
        <div className="overflow-auto pt-14 w-full h-full">
          <table className="w-full">
            <thead className="h-10">
              <tr>
                <th>Réalisé le : </th>
                <th className="pl-10 xl:pl-20">Numéro</th>
                <th className="pl-10 xl:pl-20">Type de chantier</th>
                <th className="pl-10 xl:pl-20">Nom du client</th>
                <th className="pl-10 xl:pl-20">Nom du chantier</th>
              </tr>
            </thead>
            <tbody>
              {listorderCoupons?.length ? (
                listorderCoupons
                  ?.sort((a, b) => b.numero - a.numero)
                  ?.map((orderCoupon) => {
                    const dateCouponCreate = dateFormat(orderCoupon.createdAt);
                    return (
                      <tr
                        key={orderCoupon.id}
                        className={`h-16 border-b border-white-admin hover:bg-blue-50 transition-all ${
                          orderCoupon.ctm !== 'true' ? 'cursor-pointer' : ''
                        }`}
                        onClick={() => {
                          if (orderCoupon.ctm !== 'true') navigation(`/pdf-preview/order/${orderCoupon.id}`);
                        }}
                      >
                        <td>
                          {dateCouponCreate.day}.{dateCouponCreate.month}.{dateCouponCreate.year}
                        </td>
                        <td className="pl-10 xl:pl-20">
                          {orderCoupon?.numero} {orderCoupon.ctm !== 'true' ? '' : '- pré-rempli'}
                        </td>
                        <td className="pl-10 xl:pl-20">{orderCoupon?.worksite?.type?.name}</td>
                        <td className="pl-10 xl:pl-20">{orderCoupon?.worksite?.company?.name}</td>
                        <td className="pl-10 xl:pl-20">{orderCoupon?.worksite?.name}</td>
                      </tr>
                    );
                  })
              ) : (
                <tr>
                  <td
                    className="text-center text-lg font-semibold pt-20"
                    colSpan={6}
                  >
                    {textSearch ? "Oups, ce chantier ou client n'existe pas 🤭" : 'Ajouter un bon de commande'}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default Orders;

