import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
// import useBreadcrumbs from "use-react-router-breadcrumbs";

const changeLanguage = {
  home: 'Accueil',
  admin: 'Administrateurs',
  collaborators: 'Collaborateurs',
  edit: 'Editer',
  add: 'Ajouter',
  suppliers: 'Fournisseurs',
  customers: 'Clients',
  worksite: 'Chantiers',
  order: 'Bons',
  purchaseorder: 'Commandes',
  workorder: 'Interventions',
  settings: 'Paramètres',
  'classify-order': 'Bons à classer',
  'work-order': "Bons d'intervention",
  'purchase-order': 'Bons de commande',
  ordercoupon: 'Bons de commande',
  interventioncoupon: "Bon d'intervention",
  'all-coupons': 'Tous les coupons',
  'pdf-preview': 'Aperçu',
};

const Breadcrumbs = () => {
  //init urlParams
  const urlParams = useParams();

  //init state
  const [mybreadcrumb, setmybreadcrumb] = useState([]);

  //effect
  useEffect(() => {
    const currentPath = urlParams['*'].split('/');
    let tabName = currentPath.filter((link) => link.length < 25);
    tabName = ['home', ...tabName];
    const tabPath = [''];

    for (const [i, v] of currentPath.entries()) {
      let path = '';
      for (let index = 0; index <= i; index++) {
        path += `${currentPath[index]}/`;
      }
      if (currentPath[i + 1] && currentPath[i + 1].length > 25) {
        path += `${currentPath[i + 1]}/`;
        tabPath.push(path);
      } else {
        tabPath.push(path);
      }
    }

    const finalTab = tabName.map((link, index) => {
      return { name: link, path: tabPath[index] };
    });

    setmybreadcrumb(finalTab);
  }, [urlParams]);

  //Render
  return (
    <section className="flex space-x-4 text-sm font-semibold text-gray-admin">
      {mybreadcrumb.map((line, index) => {
        return (
          <div
            className="flex"
            key={index}
          >
            <Link to={`/${line.path}`}>{changeLanguage[line.name] ? changeLanguage[line.name] : line.name}</Link>
            <div className="pl-4 text-gray-separate">{index < mybreadcrumb.length - 1 && '|'}</div>
          </div>
        );
      })}
    </section>
  );
};

export default Breadcrumbs;
