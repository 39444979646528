import { useEffect, useState } from 'react';
import middlewareCompany from '../../services/middlewares/middlewareCompany';
import { Link } from 'react-router-dom';
import Modal from '../../shared/components/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrimaryButton from '../../shared/components/PrimaryButton';
import { useStoreState, useStoreActions } from 'easy-peasy';

// import icons
import DeleteIcon from '../../assets/images/svg/delete-icon.svg';
import EditIcon from '../../assets/images/svg/edit-icon.svg';
import Search from '../../assets/images/svg/search.svg';
import AttentionIcon from '../../assets/images/svg/attention.svg';
//env value
const supplierName = process.env.REACT_APP_SUPPLIER_NAME;

const Suppliers = () => {
  //init store
  const setActivity = useStoreActions((action) => action.setActivity);
  const activity = useStoreState((state) => state.activity);

  //init state
  const [ListeSuppliers, setListeSuppliers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [idSupplierSelected, setidSupplierSelected] = useState(null);
  const [suppliersFilter, setSuppliersFilter] = useState(ListeSuppliers);
  const [textSearch, setTextSearch] = useState('');

  // func filter search, Listen
  useEffect(() => {
    if (textSearch === '') {
      setSuppliersFilter(ListeSuppliers);
    } else {
      const tabFilter = ListeSuppliers.filter((element) =>
        element.name.toLowerCase().includes(textSearch.toLowerCase())
      );
      setSuppliersFilter(tabFilter);
    }
  }, [textSearch, ListeSuppliers]);

  //Effect
  useEffect(() => {
    callSupppliers();
  }, []);

  // fun call list Suppliers
  const callSupppliers = () => {
    setActivity(true);
    middlewareCompany.getlistCompanies(supplierName).then((result) => {
      if (result.statut) {
        setListeSuppliers(result.data);
      } else {
        toast.error('❌ Une erreur est survenue lors de la récupération de la liste des Fournisseurs 🤯');
      }
      setActivity(false);
    });
  };

  // fun delete supplier
  const deleteAction = (id) => {
    setShowModal(true);
    setidSupplierSelected(id);
  };

  // fun delete supplier
  const deleteSupplier = () => {
    setActivity(true);
    middlewareCompany.deleteCompany(idSupplierSelected).then((result) => {
      if (result.statut) {
        setShowModal(false);
        callSupppliers();
      }
      setActivity(false);
    });
  };

  //Render
  return (
    <>
      <section className="px-14">
        <div>
          <div className="flex items-center justify-between pb-4 border-b pt-11 border-white-admin ">
            <h3 className="font-semibold">Liste des Fournisseurs</h3>
            <PrimaryButton
              title="Ajouter un nouveau fournisseur"
              link="add"
            />
          </div>
          <div className="relative flex mt-6 border border-white-admin rounded-xl">
            <input
              type="text"
              placeholder="Recherche Fournisseur"
              className="w-full py-3 pl-10 border-none rounded-xl placeholder:text-blue-admin inputFocus"
              onInput={(e) => setTextSearch(e.target.value)}
            />
            <img
              src={Search}
              alt="search icon"
              className="absolute left-0 flex items-center w-8 h-5 pl-3 top-4"
            />
          </div>
          <div className="overflow-auto pt-14">
            <table className="w-full">
              <thead className="h-10">
                <tr>
                  <th>Nom du fournisseur</th>
                  <th className="pl-10 xl:pl-24">N° de siret</th>
                  <th className="pl-10 xl:pl-24">Adresse de facturation</th>
                  <th className="pl-10 xl:pl-24">Téléphone</th>
                  <th className="pl-10 xl:pl-24">Actions</th>
                </tr>
              </thead>
              <tbody>
                {suppliersFilter.length ? (
                  suppliersFilter.map((Supplier) => {
                    return (
                      <tr
                        key={Supplier.id}
                        className="h-16 border-b border-white-admin hover:bg-blue-50 transition-all"
                      >
                        <td>{Supplier.name}</td>
                        <td className="pl-10 xl:pl-24">{Supplier.siret}</td>
                        <td className="pl-10 xl:pl-24">{Supplier.address}</td>
                        <td className="pl-10 xl:pl-24">{Supplier.phone}</td>
                        <td className="flex items-center pt-4 pl-10 space-x-4 xl:pl-24 ">
                          <Link
                            to={`./edit/${Supplier.id}`}
                            state={Supplier}
                          >
                            <button className="w-8 p-2 border rounded-lg border-white-admin">
                              <img
                                src={EditIcon}
                                alt="edit icon"
                              />
                            </button>
                          </Link>
                          <button
                            className="w-8 p-2 border rounded-lg border-white-admin"
                            onClick={() => deleteAction(Supplier.id)}
                          >
                            <img
                              src={DeleteIcon}
                              alt="delete icon"
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      className="text-center text-lg font-semibold pt-20"
                      colSpan={6}
                    >
                      {activity ? (
                        <></>
                      ) : textSearch ? (
                        "Oups, ce fournisseur n'existe pas 🤭"
                      ) : (
                        'Ajouter un fournisseur'
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <Modal isVisible={showModal}>
        <div>
          <div className="pt-5 pl-5">
            <img
              src={AttentionIcon}
              alt="attention icon"
            />
            <p className="pl-5 pt-7">Supression fournisseur</p>
            <p className="px-5 pt-2 text-gray-admin">
              Êtes-vous sûre de vouloir supprimer ce fournisseur ? Cette action est définitive.
            </p>
            <div className="text-center pt-7">
              <button
                className="px-12 py-3 mr-4 font-semibold border rounded-xl text-red-error"
                onClick={() => setShowModal(false)}
              >
                Annuler
              </button>
              <button
                className="px-10 py-3 text-white bg-red-error rounded-xl"
                onClick={deleteSupplier}
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
      />
    </>
  );
};

export default Suppliers;
