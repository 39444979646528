let saveUsername = (username) => {
  localStorage.setItem('username', username);
};

let logout = () => {
  localStorage.clear();
};

let isLogged = () => {
  let username = localStorage.getItem('username');

  return !!username;
};

export const accountService = {
  saveUsername,
  logout,
  isLogged,
};
