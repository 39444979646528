import { Routes, Route } from 'react-router-dom';
import AdminRouter from './routes/AdminRouter';

function App() {
  return (
    <Routes>
      <Route
        path="/*"
        element={<AdminRouter />}
      />
    </Routes>
  );
}

export default App;
