import axios from 'axios';

const urlGraphQL = process.env.REACT_APP_URL_GRAPH_QL;

const options = {
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_API_KEY,
  },
};

const middlewareSettings = {
  getListMaterial: async () => {
    const body = {
      query: `query MyQuery {
  listMaterialSettings {
    items {
      id
      name
      references {
        reference
        published
      }
    }
  }
}

`,
    };
    try {
      const request = await axios.post(urlGraphQL, body, options);
      return {
        statut: true,
        data: request.data.data.listMaterialSettings.items,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  createMaterial: async (data) => {
    const body = {
      query: `mutation MyMutation {
              createMaterialSettings(input: {name: "${data.material}", references: {published: true, reference: "${data.values}"}}) {
    name
    references {
      published
      reference
    }
  }
}`,
    };
    try {
      const request = await axios.post(urlGraphQL, body, options);
      return { statut: true, data: request.data.data.createMaterial };
    } catch (error) {
      console.log('error', error);
      return { statut: false, error };
    }
  },

  updateMaterial: async (data) => {
    const body = {
      query: `mutation MyMutation {
  updateMaterialSettings(input: {id: "${data.id}", name: "${data.material}", references: {published: true, reference: "${data.values}"}}) {
    id
    name
    references {
      reference
      published
    }
  }
}`,
    };
    try {
      const request = await axios.post(urlGraphQL, body, options);
      return { statut: true, data: request.data.data.updateMaterial };
    } catch (error) {
      console.log('error', error);
      return { statut: false, error };
    }
  },

  deleteMaterial: async (id) => {
    const body = {
      query: `mutation MyMutation {
                deleteMaterialSettings(input: {id: "${id}"}) 
                   {
                  id
                }  
              }     

`,
    };
    try {
      const request = await axios.post(urlGraphQL, body, options);
      return { statut: true, data: request.data.data.deleteMaterial };
    } catch (error) {
      return { statut: false, error };
    }
  },

  getListTypeIntervention: async () => {
    const body = {
      query: `query MyQuery {
              listTypeInterventionSettings {
                items {
                  id
                  name
                }
              }
            }`,
    };
    try {
      const request = await axios.post(urlGraphQL, body, options);
      return {
        statut: true,
        data: request.data.data.listTypeInterventionSettings.items,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },
  createTypeIntervention: async (data) => {
    const body = {
      query: `mutation MyMutation {
              createTypeInterventionSettings(input: {name: "${data.typeIntervention}"}) {
    name
  }
}`,
    };
    try {
      const request = await axios.post(urlGraphQL, body, options);
      return { statut: true, data: request.data.data.createTypeIntervention };
    } catch (error) {
      console.log('error', error);
      return { statut: false, error };
    }
  },

  updateTypeIntervention: async (data) => {
    const body = {
      query: `mutation MyMutation {
  updateTypeInterventionSettings(input: {id: "${data.id}", name: "${data.typeIntervention}"}) {
    id
    name
  }
}

`,
    };
    try {
      const request = await axios.post(urlGraphQL, body, options);
      return { statut: true, data: request.data.data.updateTypeIntervention };
    } catch (error) {
      console.log('error', error);
      return { statut: false, error };
    }
  },

  deleteTypeIntervention: async (id) => {
    const body = {
      query: `mutation MyMutation {
                deleteTypeInterventionSettings(
                  input: 
                  {
                    id: 
                    "${id}"
                  }) 
                  {
    id
  }
}    

`,
    };
    try {
      const request = await axios.post(urlGraphQL, body, options);
      return {
        statut: true,
        data: request.data.data.deleteTypeIntervention,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },
};

export { middlewareSettings };
