import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

// import icons
import DeleteIcon from '../../../assets/images/svg/delete-icon.svg';
import AttentionIcon from '../../../assets/images/svg/attention.svg';
// import mideleware
import middlewareCoupon from '../../../services/middlewares/middlewareCoupon';
// import components
import Modal from '../Modal';
import { useStoreActions } from 'easy-peasy';
import middlewareCompany from '../../../services/middlewares/middlewareCompany';

//model data Material
const refMaterial = {
  idMaterial: '',
  name: '',
  reference: null,
  quantity: '',
};

// data fix
const createName = 'Vétilla Zobda';
const supplierName = 'Sacarabany SARL';

const EditPrefillPurshaseOrder = () => {
  //state (données)
  const [listeMaterialCreate, setListeMaterialCreate] = useState([refMaterial]);
  const [newListeMaterialCreate, setNewListeMaterialCreate] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [referencesMaterials, setReferencesMaterials] = useState([]);
  const [orderCoupon, setOrderCoupon] = useState([]);
  const [deleteMaterialId, setDeleteMaterialId] = useState('');
  const [numberCoupon, setNumberCoupon] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showModalCoupon, setShowModalCoupon] = useState(false);
  const [idCoupon, setIdCoupon] = useState(null);
  const [idSaca, setIdSaca] = useState([]);
  const [formError, setformError] = useState(false);
  const { id, idWorksite } = useParams();

  const setActivity = useStoreActions((action) => action.setActivity);

  useEffect(() => {
    setformError(false);
  }, [listeMaterialCreate, newListeMaterialCreate]);

  // navigate
  const navigate = useNavigate();

  // effect
  useEffect(() => {
    getOrderCouponById();
    callListMaterialSettings();
    callListSaca();
  }, []);

  const addNewMaterial = (e) => {
    e.preventDefault();
    setNewListeMaterialCreate([...newListeMaterialCreate, refMaterial]);
  };

  //set value Material
  const changeValueMaterial = (index, key, value) => {
    let tempData = JSON.parse(JSON.stringify(listeMaterialCreate));
    if (key === 'name') {
      tempData[index].idMaterial = value;
      tempData[index][key] = referencesMaterials.find((el) => el.id === value).name;
      tempData[index].reference = null;
    } else {
      tempData[index][key] = value;
    }

    setListeMaterialCreate(tempData);
  };

  const changeValueNewMaterial = (index, key, value) => {
    let tempData = JSON.parse(JSON.stringify(newListeMaterialCreate));
    if (key === 'name') {
      tempData[index].idMaterial = value;
      tempData[index][key] = referencesMaterials.find((el) => el.id === value).name;
      tempData[index].reference = null;
    } else {
      tempData[index][key] = value;
    }

    setNewListeMaterialCreate(tempData);
  };

  // delete line new Material
  const deleteMaterial = (e, index) => {
    e.preventDefault();
    const temps = JSON.parse(JSON.stringify(newListeMaterialCreate));
    temps.splice(index, 1);
    setNewListeMaterialCreate(temps);
  };
  // delete Material Old
  const deleteOldMaterial = (e, index, id) => {
    e.preventDefault();
    setShowModal(true);
    setDeleteMaterialId(id);
  };

  //call list id saca
  const callListSaca = () => {
    middlewareCompany.getSacaCompany().then((result) => {
      if (result.statut) {
        setIdSaca(result.data[0].id);
      } else {
        console.log('Error: ', result.error);
        window.location.href = '/customers';
      }
    });
  };

  // get order coupon by id
  const getOrderCouponById = () => {
    middlewareCoupon.getOrderCouponById(id).then((result) => {
      if (result.statut) {
        setNumberCoupon(result.data.numero);
        setOrderCoupon(result.data);
        const newData = result.data.materialData.items.map((items) => {
          return {
            ...items,
            id: items.id,
            name: items.materialSettings.name,
            reference: items.indexRef,
            refs: items.materialSettings.references[0].reference.split(','),
            idMaterial: items.materialSettings.id,
          };
        });

        setListeMaterialCreate(newData);
      } else {
        console.log('Error: ', result.error);
        window.location.href = '/customers';
      }
    });
  };

  // func delete coupon
  const deletePrefillCoupon = () => {
    middlewareCoupon.deletePrefillCoupon(idCoupon).then((result) => {
      if (result.statut) {
        // getOrderCouponById();
        navigate(-1);
      }
      setActivity(false);
    });
  };

  // func onClick delete coupon
  const handleDelete = (e, id) => {
    e.preventDefault();
    setIdCoupon(id);
    setShowModalCoupon(true);
  };

  // verif is all form not empty
  const isOkfrom = () => {
    let result = true;
    !numberCoupon && (result = false);
    for (const element of listeMaterialCreate) {
      for (const key in element) {
        if (element[key] === null || element[key] === '' || element[key] === undefined) {
          result = false;
        }
      }
    }
    for (const item of newListeMaterialCreate) {
      for (const cle in item) {
        if (item[cle] === null || item[cle] === '' || item[cle] === undefined) {
          result = false;
        }
      }
    }
    return result;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (isOkfrom()) {
      const newdata = {
        id: id,
        name: createName,
        supplierName,
        numero: numberCoupon,
        worksiteId: idWorksite,
        listeMaterialCreate,
        idSaca: idSaca,
        newListeMaterialCreate,
      };

      middlewareCoupon.updateOrderCoupon(newdata).then((result) => {
        if (result.statut) {
          navigate(-1);
        } else {
          console.log('error: ', result.error);
        }
      });
    } else {
      setformError(true);
    }
  };

  const navigateOrder = () => {
    navigate(-1);
  };
  //call list materialSettings
  const callListMaterialSettings = () => {
    middlewareCoupon.getListMaterialSettings().then((result) => {
      if (result.statut) {
        const data = result.data.items.filter((item) => item.name !== 'Climatiseur');
        setReferencesMaterials(data);
        setMaterials(data);
      } else {
        console.log('Error: ', result.error);
        window.location.href = '/customers';
      }
    });
  };

  const callDeleteMaterial = (id) => {
    setActivity(true);
    middlewareCoupon.deleteMaterialData(id).then((result) => {
      if (result.statut) {
        navigate(0);
      } else {
        console.log('Error: ', result.error);
        setActivity(false);
      }
    });
  };

  const deleteCoupon = () => {
    setActivity(true);
    middlewareCoupon.deletesMultipleMaterialsData(listeMaterialCreate).then((result) => {
      if (result.statut) {
        setShowModalCoupon(false);
        deletePrefillCoupon();
      } else {
        setActivity(false);
      }
    });
  };

  return (
    <section>
      <div className="mt-14">
        <form onSubmit={onSubmit}>
          <div className="flex space-x-6 mx-14">
            <div className="flex flex-col space-y-4">
              <label>Nom du client</label>
              <input
                className="inputFocus w-[343px] inputPreFill text-blue-admin"
                type="text"
                placeholder="Nom du client"
                defaultValue={orderCoupon?.worksite?.company?.name}
                disabled
              />
            </div>
            <div className="flex flex-col space-y-4 ">
              <label>Nom du fournisseur</label>
              <input
                className="inputFocus inputPreFill w-[343px] text-blue-admin"
                type="text"
                placeholder="Sacarabany SARL"
                defaultValue={supplierName}
                disabled
              />
            </div>
            <div className="flex flex-col w-full space-y-4">
              <label>Numéro du bon</label>
              <input
                className="inputFocus inputPreFill text-blue-admin"
                type="number"
                defaultValue={orderCoupon?.numero}
                disabled
              />
            </div>
          </div>
          <div className="flex space-x-6 mx-14">
            <div className="flex flex-col mt-5 space-y-4 ">
              <label>Créer par</label>
              <input
                className="inputFocus inputPreFill w-[343px]  text-blue-admin"
                type="text"
                placeholder="Vétilla Zobda"
                defaultValue={createName}
                disabled
              />
            </div>
          </div>
          {/* div list */}
          <div className="mt-8 mx-14">
            <h4 className="font-semibold">Liste du matériel utilisé</h4>
            {listeMaterialCreate.map((materiel, index) => {
              const idMaterial = listeMaterialCreate[index].idMaterial;
              const reference = referencesMaterials
                .find((el) => el.id === idMaterial)
                ?.references[0]?.reference?.split(',');
              return (
                <>
                  <div
                    key={index}
                    className="flex items-center mt-5 space-x-5"
                  >
                    <div className="flex flex-col space-y-3">
                      <label className="text-gray-placeholder">Nom du matériel</label>
                      <select
                        className="w-[234px] rounded-lg py-4 border-gray-separate text-blue-admin"
                        onChange={(e) => changeValueMaterial(index, 'name', e.target.value)}
                      >
                        {materials?.map((material) => {
                          return (
                            <option
                              key={material.id}
                              value={material.id}
                              selected={material.id === listeMaterialCreate[index]?.idMaterial}
                            >
                              {material.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="flex flex-col space-y-3">
                      <label className="text-gray-placeholder">Référence</label>
                      <select
                        className="w-[234px] rounded-lg py-4 border-gray-separate text-blue-admin"
                        onChange={(e) => changeValueMaterial(index, 'reference', e.target.value)}
                      >
                        <option
                          disabled
                          selected={listeMaterialCreate[index]?.reference === null ? true : false}
                        >
                          -
                        </option>

                        {reference?.map((referenceMaterial, ind) => {
                          return (
                            <option
                              key={ind}
                              value={ind}
                              selected={ind === listeMaterialCreate[index]?.reference}
                            >
                              {referenceMaterial}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="flex flex-col space-y-3">
                      <label className="text-gray-placeholder">Quantité</label>
                      <input
                        className="max-w-[234px] rounded-lg py-4 border border-gray-separate placeholder:text-blue-admin text-blue-admin"
                        type="number"
                        placeholder="-"
                        defaultValue={listeMaterialCreate[index].quantity}
                        onInput={(e) => changeValueMaterial(index, 'quantity', e.target.value)}
                      />
                    </div>
                    <div>
                      <button
                        onClick={(e) => deleteOldMaterial(e, index, materiel.id)}
                        className="w-8 p-2 border rounded-lg mt-7 border-white-admin"
                      >
                        <img
                          src={DeleteIcon}
                          alt="delete icon"
                        />
                      </button>
                    </div>
                  </div>
                </>
              );
            })}
          </div>

          {/* 2nd table */}

          <div className="mt-8 mx-14">
            <h4 className="font-semibold">Liste de nouveau matériel à ajouter</h4>
            {newListeMaterialCreate.map((materiel, index) => {
              const idMaterial = newListeMaterialCreate[index].idMaterial;
              const reference = referencesMaterials
                .find((el) => el.id === idMaterial)
                ?.references[0]?.reference?.split(',');

              return (
                <>
                  <div
                    key={materiel.id}
                    className="flex items-center mt-5 space-x-5"
                  >
                    <div className="flex flex-col space-y-3">
                      <label className="text-gray-placeholder">Nom du matériel</label>
                      <select
                        className="w-[234px] rounded-lg py-4 border-gray-separate text-blue-admin"
                        onChange={(e) => changeValueNewMaterial(index, 'name', e.target.value)}
                      >
                        <option
                          disabled
                          selected
                        >
                          Nom du matériel
                        </option>
                        {materials?.map((material) => {
                          return (
                            <option
                              key={material.id}
                              value={material.id}
                              defaultValue={material.id === newListeMaterialCreate[index]?.name}
                            >
                              {material.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="flex flex-col space-y-3">
                      <label className="text-gray-placeholder">Référence</label>
                      <select
                        className="w-[234px] rounded-lg py-4 border-gray-separate text-blue-admin"
                        onChange={(e) => changeValueNewMaterial(index, 'reference', e.target.value)}
                      >
                        <option
                          disabled
                          selected={newListeMaterialCreate[index]?.reference === null ? true : false}
                        >
                          -
                        </option>

                        {reference?.map((referenceMaterial, index) => {
                          return (
                            <option
                              key={referenceMaterial.id}
                              value={index}
                              defaultValue={referenceMaterial?.id === newListeMaterialCreate[index]?.reference}
                            >
                              {referenceMaterial}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="flex flex-col space-y-3">
                      <label className="text-gray-placeholder">Quantité</label>
                      <input
                        className="max-w-[234px] rounded-lg py-4 border border-gray-separate placeholder:text-blue-admin text-blue-admin"
                        type="text"
                        placeholder="-"
                        value={newListeMaterialCreate[index]?.quantity}
                        onInput={(e) => changeValueNewMaterial(index, 'quantity', e.target.value)}
                      />
                    </div>
                    <div>
                      <button
                        onClick={(e) => deleteMaterial(e, index)}
                        className="w-8 p-2 border rounded-lg mt-7 border-white-admin"
                      >
                        <img
                          src={DeleteIcon}
                          alt="delete icon"
                        />
                      </button>
                    </div>
                  </div>
                </>
              );
            })}
            <button
              onClick={addNewMaterial}
              className="px-4 py-3 mt-6 text-sm font-semibold border rounded-lg text-blue-admin border-gray-separate"
            >
              + Ajouter un nouveau matériel
            </button>
          </div>
          {formError && <p className="text-red-error text-end mt-5">Veuillez remplir tous les champs!</p>}
          <div className="flex items-center justify-between py-4 mt-16 border-t border-white-admin">
            <button
              onClick={navigateOrder}
              className="border rounded-lg cursor-pointer ml-14 border-white-admin px-5 py-3 text-blue-admin"
            >
              Annuler
            </button>
            <div class>
              <button
                onClick={(e) => handleDelete(e, id)}
                className="px-5 py-3 font-semibold text-red-error rounded-lg cursor-pointer mr-5 border border-red-bg-error"
              >
                Supprimer ce bon pré-rempli
              </button>
              <button
                type="submit"
                className="px-5 py-3 font-semibold text-white rounded-lg cursor-pointer mr-14 bg-blue-admin"
              >
                Enregistrer ce bon pré-rempli
              </button>
            </div>
          </div>
        </form>
      </div>
      <Modal isVisible={showModalCoupon}>
        <div>
          <div className="pt-5 pl-5">
            <img
              src={AttentionIcon}
              alt="attention icon"
            />
            <p className="pl-5 pt-7">Supression du bon pré-rempli</p>
            <p className="px-5 pt-2 text-gray-admin">
              {' '}
              Êtes-vous sûre de vouloir supprimer bon ? Cette action est définitive.
            </p>
            <div className="text-center pt-7">
              <button
                className="px-12 py-3 mr-4 font-semibold border rounded-xl text-red-error"
                onClick={() => setShowModalCoupon(false)}
              >
                Annuler
              </button>
              <button
                onClick={deleteCoupon}
                className="px-10 py-3 text-white bg-red-error rounded-xl"
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal isVisible={showModal}>
        <div>
          <div className="pt-5 pl-5">
            <img
              src={AttentionIcon}
              alt="attention icon"
            />
            <p className="pl-5 pt-7">Supression du matériel</p>
            <p className="px-5 pt-2 text-gray-admin">
              {' '}
              Êtes-vous sûre de vouloir supprimer ce matériel ? Cette action est définitive.
            </p>
            <div className="text-center pt-7">
              <button
                className="px-12 py-3 mr-4 font-semibold border rounded-xl text-red-error"
                onClick={() => setShowModal(false)}
              >
                Annuler
              </button>
              <button
                onClick={() => {
                  setShowModal(false);
                  callDeleteMaterial(deleteMaterialId);
                }}
                className="px-10 py-3 text-white bg-red-error rounded-xl"
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </section>
  );
};

export default EditPrefillPurshaseOrder;
