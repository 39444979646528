import React from 'react';
import { Link } from 'react-router-dom';
import LogoSarl from '../assets/images/svg/logo-flex-sacarabany-sarl.svg';
import PrimaryButton from '../shared/components/PrimaryButton';

const NotFound = () => {
  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <div className="flex justify-center items-center flex-col">
        <img
          src={LogoSarl}
          alt="logo sacarabany sarl"
        />
        <p className="my-3">La page que vous avez demandé n'existe pas</p>
        <PrimaryButton
          title="Accueil"
          link="/"
        />
      </div>
    </div>
  );
};

export default NotFound;
