import { useState, useEffect } from 'react';
import { useStoreActions } from 'easy-peasy';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import middlewareCompany from '../../../services/middlewares/middlewareCompany';

import deleteIcon from '../../../assets/images/svg/delete-icon.svg';

const EditCustomer = () => {
  //init store
  const setActivity = useStoreActions((action) => action.setActivity);

  //init navigation
  const navigate = useNavigate();

  //init default values client
  const currentCustomer = useLocation().state;

  //no data navigate to Marie©
  if (!currentCustomer) {
    window.location.href = '/customers';
  }

  //initState
  const [ctm, setCtm] = useState(currentCustomer.ctm);
  const [listMails, setListMails] = useState(currentCustomer.mail.split(','));
  const [textMail, setTextMail] = useState('');
  const [msgErrMail, setMsgErrMail] = useState('');

  //Schema form
  const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  const siretRegExp = /^[0-9]{14}$|^(?!.*\S)/;
  const mailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const schema = yup.object().shape({
    name: yup.string().required('Ce champ est requis.'),
    siret: yup.string(),
    address: yup.string().required('Ce champ est requis.'),
    phone: yup.string(),
  });

  const addMail = () => {
    if (textMail.match(mailRegExp)) {
      setListMails([...listMails, textMail]);
      setTextMail('');
    } else {
      setMsgErrMail("format de l'adresse mail");
    }
  };

  const deleteMail = (index) => {
    const newlist = [...listMails];
    newlist.splice(index, 1);
    setListMails(newlist);
  };

  // effect watch mailk input
  useEffect(() => {
    setMsgErrMail('');
  }, [textMail]);

  //config form verifications
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  //Submit fun
  const onSubmit = (data) => {
    if (listMails.length) {
      const newDatas = { ...data, id: currentCustomer.id, mail: listMails };
      setActivity(true);
      middlewareCompany.updateCompany(newDatas).then((result) => {
        if (result.statut) {
          navigate('/customers');
        }
        setActivity(false);
      });
    } else {
      setMsgErrMail('Ajouter une adresse mail');
    }
  };

  //Render
  return (
    <>
      <section>
        <div>
          <div className="mx-14 border-b border-white-admin pt-14 pb-4">
            <h3 className="font-semibold">Modifier un client</h3>
          </div>

          <div className="pt-11">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mx-14 flex space-x-4 items-center border-b border-white-admin pb-4 mb-4">
                <label htmlFor="name">Nom du client</label>
                <input
                  id="name"
                  defaultValue={currentCustomer.name}
                  className="w-[318px] py-3 pl-5 border border-white-admin rounded-lg text-sm input"
                  placeholder="Nom du client"
                  {...register('name')}
                />
                <p className="text-xs text-red-500">{errors.name?.message}</p>
              </div>

              <div className="mx-14 flex space-x-4 items-center border-b border-white-admin pb-4 mb-4">
                <label htmlFor="siret">Numéro de SIRET</label>
                <input
                  id="siret"
                  defaultValue={currentCustomer.siret}
                  className="w-[318px] py-3 pl-5 border border-white-admin rounded-lg text-sm input"
                  placeholder="N° XXX XXX XXXXX XXX"
                  {...register('siret')}
                />
                <p className="text-xs text-red-500">{errors.siret?.message}</p>
              </div>

              <div className="mx-14 flex space-x-4 items-center border-b border-white-admin pb-4 mb-4">
                <label htmlFor="address">Adresse postale</label>
                <input
                  id="address"
                  defaultValue={currentCustomer.address}
                  className="w-[318px] py-3 pl-5 border border-white-admin rounded-lg text-sm input"
                  placeholder="22 rue du général de Gaulle 97236 Lamentin"
                  {...register('address')}
                />
                <p className="text-xs text-red-500">{errors.address?.message}</p>
              </div>

              <div className="mx-14 flex space-x-4 items-center  pb-4 ">
                <label htmlFor="mail">Adresse mail de facturation</label>
                <input
                  className="w-[318px] py-3 pl-5 border border-white-admin rounded-lg text-sm input"
                  placeholder="client@exemple.mq"
                  value={textMail}
                  onInput={(e) => setTextMail(e.target.value)}
                />
                <bouton
                  className=" border rounded-lg border-white-admin px-2 text-center cursor-pointer"
                  onClick={addMail}
                >
                  +
                </bouton>
                <p className=" mx-14 text-xs text-red-500">{msgErrMail}</p>
              </div>
              <div className="mx-14 flex space-x-4 items-center border-b border-white-admin pb-4 mb-4">
                {listMails?.map((mail, index) => (
                  <div className=" flex w-fit py-3 px-5 border border-white-admin rounded-lg text-sm input">
                    <img
                      src={deleteIcon}
                      alt="delete"
                      className="mr-2 cursor-pointer"
                      onClick={() => deleteMail(index)}
                    />
                    <p>{mail}</p>
                  </div>
                ))}
              </div>

              <div className="mx-14 flex space-x-4 items-center border-b border-white-admin pb-4 mb-4">
                <label htmlFor="phone">Téléphone</label>
                <input
                  id="phone"
                  defaultValue={currentCustomer.phone}
                  className="w-[318px] py-3 pl-5 border border-white-admin rounded-lg text-sm input"
                  placeholder="Téléphone"
                  {...register('phone')}
                />
                <p className="text-xs text-red-500">{errors.phone?.message}</p>
              </div>

              <div className="mx-14 flex space-x-4 items-center border-b border-white-admin pb-4 mb-4">
                <label>Type de client</label>
                <input
                  className="w-4 h-4 checkedFocus"
                  type="radio"
                  id="normal"
                  {...register('typeClient')}
                  value="normal"
                  checked={!ctm}
                  onChange={() => setCtm(false)}
                />
                <label htmlFor="normal">Normal</label>
                <input
                  className="w-4 h-4 checkedFocus"
                  type="radio"
                  id="ctm"
                  {...register('typeClient')}
                  value="CTM"
                  checked={ctm}
                  onChange={() => setCtm(true)}
                />
                <label htmlFor="ctm">CTM</label>
              </div>

              <div className="mt-16 pt-4 flex justify-between items-center border-t border-white-admin">
                <Link
                  to="/customers"
                  className="cursor-pointer ml-14 border border-white-admin rounded-lg"
                >
                  <button className="py-3 px-5 text-blue-admin">Annuler</button>
                </Link>
                <div className=" cursor-pointer mr-14 bg-blue-admin rounded-lg">
                  <button
                    type="submit"
                    className="py-3 px-5 text-white font-semibold"
                  >
                    Enregister les Modifications
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditCustomer;
