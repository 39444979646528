import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../shared/components/Header';
import SideMenu from '../shared/layout/SideMenu';
import LoaderActivity from '../shared/components/LoaderActivity';

const Home = () => {
  return (
    <div className="w-screen h-screen overflow-hidden">
      <div className="flex w-full h-full">
        <SideMenu />
        <div className="flex flex-col h-full grow overflow-hidden relative">
          <LoaderActivity />
          <Header />
          <div className="flex flex-col w-full grow overflow-auto outlet-custom">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
