import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, Link } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import middlewareCompany from '../../../services/middlewares/middlewareCompany';

//env value
const customerName = process.env.REACT_APP_CUSTOMER_NAME;

const AddCustomer = () => {
  //init store
  const setActivity = useStoreActions((action) => action.setActivity);

  //Global store
  const typeCompanies = useStoreState((state) => state.typeCompanies);
  const customerId = typeCompanies.find((type) => type.name === customerName).id;

  //init navigation
  const navigate = useNavigate();

  //initState
  const [ctm, setCtm] = useState(false);

  //Schema form
  const phoneRegExp = /^[0-9]{10}$|([+])([0-9]{11,12})$/;
  const siretRegExp = /^[0-9]{14}$|^(?!.*\S)/;
  const schema = yup.object().shape({
    name: yup.string().required('Ce champ est requis.'),
    siret: yup.string(),
    address: yup.string().required('Ce champ est requis.'),
    mail: yup.string().email('Email au format: Client@gmail.mq').required('Ce champ est requis.'),
    phone: yup.string(),
  });

  //config form verifications
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  //Submit fun
  const onSubmit = (data) => {
    const newDatas = { ...data, id_type_Company: customerId, mail: [data.mail, 'test@live.fr'] };
    setActivity(true);
    middlewareCompany.createCompany(newDatas).then((result) => {
      if (result.statut) {
        navigate('/customers');
      }
      setActivity(false);
    });
  };

  //Render
  return (
    <>
      <section>
        <div>
          <div className="pb-4 border-b mx-14 border-white-admin pt-14">
            <h3 className="font-semibold">Créer un nouveau client</h3>
          </div>

          <div className="pt-11">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex items-center pb-4 mb-4 space-x-4 border-b mx-14 border-white-admin">
                <label htmlFor="name">Nom du client</label>
                <input
                  id="name"
                  className={errors.name ? 'w-[318px] input_error' : 'w-[318px] input'}
                  placeholder="Nom du client"
                  {...register('name')}
                />
                <p className="text-xs text-red-500">{errors.name?.message}</p>
              </div>

              <div className="flex items-center pb-4 mb-4 space-x-4 border-b mx-14 border-white-admin">
                <label htmlFor="siret">Numéro de SIRET</label>
                <input
                  id="siret"
                  className={errors.siret ? 'w-[318px] input_error' : 'w-[318px] input'}
                  placeholder="N° XXX XXX XXXXX XXX"
                  {...register('siret')}
                />
                <p className="text-xs text-red-500">{errors.siret?.message}</p>
              </div>

              <div className="flex items-center pb-4 mb-4 space-x-4 border-b mx-14 border-white-admin">
                <label htmlFor="address">Adresse postale</label>
                <input
                  id="address"
                  className={errors.address ? ' w-[318px] input_error' : 'w-[318px] input'}
                  placeholder="22 rue du général de Gaulle 97236 Lamentin"
                  {...register('address')}
                />
                <p className="text-xs text-red-500">{errors.address?.message}</p>
              </div>

              <div className="flex items-center pb-4 mb-4 space-x-4 border-b mx-14 border-white-admin">
                <label htmlFor="mail">Adresse mail de facturation</label>
                <input
                  id="mail"
                  className={errors.mail ? ' w-[318px] input_error' : 'w-[318px] input'}
                  placeholder="Client@exemple.mq"
                  {...register('mail')}
                />
                <p className="text-xs text-red-500">{errors.mail?.message}</p>
              </div>

              <div className="flex items-center pb-4 mb-4 space-x-4 border-b mx-14 border-white-admin">
                <label htmlFor="phone">Téléphone</label>
                <input
                  id="phone"
                  className={errors.phone ? 'w-[318px] input_error' : 'w-[318px] input'}
                  placeholder="Téléphone"
                  {...register('phone')}
                />
                <p className="text-xs text-red-500">{errors.phone?.message}</p>
              </div>

              <div className="flex items-center pb-4 mb-4 space-x-4 border-b mx-14 border-white-admin">
                <label>Type de client</label>
                <input
                  type="radio"
                  className="w-4 h-4 checkedFocus"
                  id="normal"
                  {...register('typeClient')}
                  value="normal"
                  checked={!ctm}
                  onChange={() => setCtm(false)}
                />
                <label htmlFor="normal">Normal</label>
                <input
                  type="radio"
                  className="w-4 h-4 checkedFocus"
                  id="ctm"
                  {...register('typeClient')}
                  value="CTM"
                  checked={ctm}
                  onChange={() => setCtm(true)}
                />
                <label htmlFor="ctm">CTM</label>
              </div>

              <div className="flex items-center justify-between pt-4 mt-16 border-t border-white-admin">
                <Link
                  to="/customers"
                  className="border rounded-lg cursor-pointer ml-14 border-white-admin"
                >
                  <button className="px-5 py-3 text-blue-admin">Annuler</button>
                </Link>
                <div className="rounded-lg cursor-pointer mr-14 bg-blue-admin">
                  <button
                    type="submit"
                    className="px-5 py-3 font-semibold text-white"
                  >
                    Créer un nouveau client
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddCustomer;
