import axios from 'axios';

// * * * * config axios * * * * * //
const urlGraphQL = process.env.REACT_APP_URL_GRAPH_QL;
const options = {
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_API_KEY,
  },
};
// * * * * * * * * * * * * * * //

const middlewareCoupon = {
  getOrderCoupon: async (id_coupon) => {
    const body = {
      query: `
       query MyQuery {

          getOrderCoupon(id: "${id_coupon}") {

            id

            numero

            name

            signed

            nameClient

            nameWorksite

            createdAt

            updatedAt

            worksite {

              company {

                address

                mail

                siret

                name

              }

              name

            }

            materialData {

              items {

                name

                quantity

                materialSettings {

                  name

                  references {

                    published

                    reference

                  }

                }

                indexRef

                description

              }

            }

            company {

              address

              mail

              name

              siret

            }
            collaborator {

              first_name

              last_name
              
            }

          }

        }
        `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);
      return {
        statut: true,
        data: request.data.data.getOrderCoupon,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  getInterventionCoupon: async (id_coupon) => {
    const body = {
      query: `
      query MyQuery {
          getInterventionCoupon(id: "${id_coupon}") {
            id
            ctm
            numero
            bcCtm
            nameWorksite
            nameClient
            newMailClient
            createdAt
            updatedAt
            worksite {
              company {
                name
                siret
                address
                mail
                phone
              }
              name
              date_planned
            }
            materialData {
              items {
                name
                materialSettings {
                  references {
                    published
                    reference
                  }
                  name
                }
                surface
                quantity
                indexRef
                description
                referenceQr
                address
                poseAddress
              }
            }
            signed
            orderCoupon {
              numero
            }
          }
        }
        `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);
      return {
        statut: true,
        data: request.data.data.getInterventionCoupon,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  getCompagny: async (id_compagny) => {
    const body = {
      query: `
      query MyQuery {
  getCompany(id: "${id_compagny}") {
    name
    worksites {
      items {
        id
        users {
          items {
            user {
              first_name
              last_name
              id
            }
          }
        }
      }
    }
  }
}

        `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);
      return {
        statut: true,
        data: request.data.data.getCompany,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  getListInterventionSettings: async () => {
    const body = {
      query: `
      query MyQuery {
  listTypeInterventionSettings {
    items {
      id
      name
    }
  }
}

        `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);
      return {
        statut: true,
        data: request.data.data.listTypeInterventionSettings,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  createMaterialData: async (payload) => {
    const body = {
      query: `
      mutation MyMutation {

            createMaterialData(input: {

              name: "${payload.name}",

              quantity: ${payload.quantity},

              ${payload.address ? `,address: "${payload.address}"` : ''}

              ${payload.poseAddress ? `,poseAddress: "${payload.poseAddress}"` : ''}

              ${payload.indexRef !== undefined ? `,indexRef: ${payload.indexRef}` : ''}

              ${payload.description ? `,description: "${payload.description}"` : ''}

              ${payload.referenceQr ? `,referenceQr: "${payload.referenceQr}"` : ''}

              ${payload.orderCouponId ? `,orderCouponMaterialDataId: "${payload.orderCouponId}"` : ''}

              ${payload.materialSettingsId ? `,materialDataMaterialSettingsId: "${payload.materialSettingsId}"` : ''}

              ${
                payload.typeInterventionSettingsId
                  ? `,materialDataTypeInterventionSettingsId: "${payload.typeInterventionSettingsId}"`
                  : ''
              }

              ${
                payload.interventionCouponId
                  ? `,interventionCouponMaterialDataId: "${payload.interventionCouponId}"`
                  : ''
              }

            })

            {

              id

            }

          }

        `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);
      return {
        statut: true,
        data: request.data.data.createMaterialData,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  createInterventionCoupon: async (data) => {
    const body = {
      query: `
        mutation MyMutation {
          createInterventionCoupon(input: {
            bcCtm: "${data.bcCtm}",
            ctm: "true", 
            interventionCouponUserId: "${data.colabSelected}",
            name: "Vétilla Zobda", 
            numero: "${data.numero}", 
            worksiteIntervention_couponId: "${data.worksite}"
          }) 
          {
            id
          }
        }
      `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);
      data.listeMaterialCreate.forEach((material) => {
        const newDate = {
          ...material,
          interventionCouponId: request.data.data.createInterventionCoupon.id,
          materialSettingsId: material.idMaterial,
          indexRef: material.reference,
          typeInterventionSettingsId: material.typeInterventionSettingsId,
        };
        middlewareCoupon.createMaterialData(newDate);
      });
      return {
        statut: true,
        data: request.data.data.createInterventionCoupon,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  getListMaterialSettings: async () => {
    const body = {
      query: `
      query MyQuery {
  listMaterialSettings {
    items {
      name
      id
      references {
        published
        reference
      }
    }
  }
}

        `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);

      return {
        statut: true,
        data: request.data.data.listMaterialSettings,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  createOrderCoupon: async (data) => {
    const body = {
      query: `
      mutation MyMutation {
  createOrderCoupon(input: {name: "${data.name}",orderCouponCompanyId: "${data.idSaca}", numero: "${data.numero}", worksiteOrder_couponId: "${data.worksiteId}", bcCtm: "", ctm: "true"}) {
    id
  }
}
        `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);
      data.listeMaterialCreate.forEach((material) => {
        const newDate = {
          ...material,
          orderCouponId: request.data.data.createOrderCoupon.id,
          materialSettingsId: material.idMaterial,
          indexRef: material.reference,
        };
        middlewareCoupon.createMaterialData(newDate);
      });
      return {
        statut: true,
        data: request.data.data.createOrderCoupon,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  getOrderCouponById: async (id) => {
    const body = {
      query: `
     query MyQuery {
  getOrderCoupon(id: "${id}") {
    classified
    name
    numero
    signed
    worksite {
      id
      company {
        name
      }
    }
    materialData {
      items {
        id
        indexRef
        quantity
        materialSettings {
          id
          name
          references {
            published
            reference
          }
        }
      }
    }
  }
}

        `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);

      return {
        statut: true,
        data: request.data.data.getOrderCoupon,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  deleteMaterialData: async (id) => {
    const body = {
      query: `
      mutation MyMutation {
        deleteMaterialData(input: {id: "${id}"}) {id}
      }`,
    };
    try {
      const request = await axios.post(urlGraphQL, body, options);

      return {
        statut: true,
        data: request.data.data.getOrderCoupon,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  deletePrefillCoupon: async (id) => {
    const body = {
      query: `
      mutation MyMutation {
  deleteOrderCoupon(input: {id: "${id}"}, condition: {ctm: {eq: "true"}}) {
    id
  }
}

`,
    };
    try {
      const request = await axios.post(urlGraphQL, body, options);

      return {
        statut: true,
        data: request.data.data.deleteOrderCoupon,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  updateOrderCoupon: async (data) => {
    const body = {
      query: `
      mutation MyMutation {
  updateOrderCoupon(input: {id: "${data.id}", numero: "${data.numero}", name: "${data.name}", ctm: "true", bcCtm: "", orderCouponCompanyId: "${data.idSaca}", worksiteOrder_couponId: "${data.worksiteId}"}) {
    id
  }
}
        `,
    };
    try {
      const request = await axios.post(urlGraphQL, body, options);
      if (data.newListeMaterialCreate.length >= 1) {
        data.newListeMaterialCreate.forEach((material) => {
          const newDate = {
            ...material,
            orderCouponId: request.data.data.updateOrderCoupon.id,
            materialSettingsId: material.idMaterial,
            indexRef: material.reference,
          };
          middlewareCoupon.createMaterialData(newDate);
        });
      }
      data.listeMaterialCreate.forEach((material) => {
        const newDate = {
          ...material,
          orderCouponId: request.data.data.updateOrderCoupon.id,
          materialSettingsId: material.idMaterial,
          indexRef: material.reference,
        };
        middlewareCoupon.updateMaterialData(newDate);
      });

      return {
        statut: true,
        data: request.data.data.updateOrderCoupon,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  updateMaterialData: async (payload) => {
    const body = {
      query: `
      mutation MyMutation {

            updateMaterialData(input: {
             
              id :  "${payload.id}",

              name: "${payload.name}",

              quantity: ${payload.quantity},

              ${payload.address ? `,address: "${payload.address}"` : ''}

              ${payload.indexRef !== undefined ? `,indexRef: ${payload.indexRef}` : ''}

              ${payload.description ? `,description: "${payload.description}"` : ''}

              ${payload.referenceQr ? `,referenceQr: "${payload.referenceQr}"` : ''}

              ${payload.orderCouponId ? `,orderCouponMaterialDataId: "${payload.orderCouponId}"` : ''}

              ${payload.materialSettingsId ? `,materialDataMaterialSettingsId: "${payload.materialSettingsId}"` : ''}

              ${
                payload.typeInterventionSettingsId
                  ? `,materialDataTypeInterventionSettingsId: "${payload.typeInterventionSettingsId}"`
                  : ''
              }

              ${
                payload.interventionCouponId
                  ? `,interventionCouponMaterialDataId: "${payload.interventionCouponId}"`
                  : ''
              }

            })

            {

              id

            }

          }

        `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);
      return {
        statut: true,
        data: request.data.data.updateMaterialData,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  getInterventionCouponById: async (id) => {
    const body = {
      query: `
    query MyQuery {
  getInterventionCoupon(id: "${id}") {
    bcCtm
    ctm
    id
    interventionCouponOrderCouponId
    interventionCouponUserId
    user {
      id
      last_name
      first_name
    }
    name
    numero
    materialData {
      items {
        id
        indexRef
        materialDataMaterialSettingsId
        materialDataTypeInterventionSettingsId
        interventionCouponMaterialDataId
        name
        quantity
        typeInterventionSettings {
          name
          id
        }
        materialSettings {
          id
          name
          references {
            published
            reference
          }
        }
      }
    }
  }
}



        `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);

      return {
        statut: true,
        data: request.data.data.getInterventionCoupon,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  deletePrefillInterventionCoupon: async (id) => {
    const body = {
      query: `
      mutation MyMutation {
  deleteInterventionCoupon(input: {id: "${id}"}, condition: {ctm: {eq: "true"}}) {
    id
  }
}



`,
    };
    try {
      const request = await axios.post(urlGraphQL, body, options);

      return {
        statut: true,
        data: request.data.data.deleteInterventionCoupon,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  updateInterventionCoupon: async (data) => {
    const body = {
      query: `
        mutation MyMutation {
          updateInterventionCoupon(input: {
            id: "${data.id}"
            bcCtm: "${data.bcCtm}",
            ctm: "true", 
            interventionCouponUserId: "${data.colabSelected}",
            name: "Vétilla Zobda", 
            numero: "${data.numero}", 
            worksiteIntervention_couponId: "${data.worksite}"
          }) 
          {
            id
          }
        }
      `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);
      if (data.newListeMaterialCreate.length >= 1) {
        data.newListeMaterialCreate.forEach((material) => {
          const newDate = {
            ...material,
            interventionCouponId: request.data.data.updateInterventionCoupon.id,
            materialSettingsId: material.idMaterial,
            indexRef: material.reference,
            typeInterventionSettingsId: material.typeInterventionSettingsId,
          };
          middlewareCoupon.createMaterialData(newDate);
        });
      }
      data.listeMaterialCreate.forEach((material) => {
        const newDate = {
          ...material,
          interventionCouponId: request.data.data.updateInterventionCoupon.id,
          materialSettingsId: material.idMaterial,
          indexRef: material.reference,
        };
        middlewareCoupon.updateMaterialData(newDate);
      });
      return {
        statut: true,
        data: request.data.data.updateInterventionCoupon,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  listAllNumberOrderCoupon: async () => {
    const body = {
      query: `
        query MyQuery {
          listOrderCoupons {
            items {
              numero
            }
          }
        }
      `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);

      return {
        statut: true,
        data: request.data.data.listOrderCoupons.items,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  listAllNumberInterventionCoupon: async () => {
    const body = {
      query: `
        query MyQuery {
          listInterventionCoupons {
            items {
              numero
            }
          }
        }
      `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);

      return {
        statut: true,
        data: request.data.data.listInterventionCoupons.items,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  //
  deletesMultipleMaterialsData: async (listMaterial) => {
    const body = {
      query: `
          mutation MyMutation {
          ${listMaterial.map(
            (material, index) =>
              `task${index}: deleteMaterialData(input: {id: "${material.id}"}) {
              id
            }`
          )}
        }
      `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);

      return {
        statut: true,
        data: request.data.data,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },

  //
  allorders: async (listMaterial) => {
    const body = {
      query: `
      query MyQuery {
        listOrderCoupons(filter: {not: {classified: {eq: false}}}) {
          items {
            id
            name
            numero
            createdAt
            ctm
            worksite {
              type {
                name
              }
              name
              company {
                name
              }
            }
          }
        }
      }
      `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);

      return {
        statut: true,
        data: request.data.data.listOrderCoupons.items,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },
  //
  allIntervention: async (listMaterial) => {
    const body = {
      query: `
      query MyQuery {
        listInterventionCoupons(filter: {not: {classified: {eq: false}}}) {
          items {
            id
            name
            numero
            createdAt
            ctm
            worksite {
              type {
                name
              }
              name
              company {
                name
              }
            }
      
          }
        }
      }
      
      `,
    };

    try {
      const request = await axios.post(urlGraphQL, body, options);

      return {
        statut: true,
        data: request.data.data.listInterventionCoupons.items,
      };
    } catch (error) {
      return { statut: false, error };
    }
  },
};

export default middlewareCoupon;
