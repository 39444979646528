import { useRef } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import middlewareCompany from '../../../services/middlewares/middlewareCompany';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditSuppliers = () => {
  //init store
  const setActivity = useStoreActions((action) => action.setActivity);
  //init navigation
  const navigate = useNavigate();

  //init default values fournisseur
  const currentSupplier = useLocation().state;

  //no data navigate to Marie©
  if (!currentSupplier) {
    window.location.href = '/suppliers';
  }

  //init ref
  const btnSubmit = useRef(true);

  //Schema form
  const phoneRegExp = /^[0-9]{10}$|([+])([0-9]{11,12})$/;
  const siretRegExp = /^[0-9]{14}$|^(?!.*\S)/;
  const schema = yup.object().shape({
    name: yup.string().required('Ce champ est requis.'),
    siret: yup.string().matches(siretRegExp, 'format du siret est de 14 chiffres'),
    address: yup.string().required('Ce champ est requis.'),
    mail: yup.string().email('Email au format: Fournisseur@gmail.mq').required('Ce champ est requis.'),
    phone: yup.string().matches(phoneRegExp, 'Format du numéro est incorrect'),
  });

  //config form verifications
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  //Submit fun
  const onSubmit = (data) => {
    btnSubmit.current.disabled = true;
    btnSubmit.current.classList.add('opacity-30');
    const newDatas = { ...data, id: currentSupplier.id };
    // func update supplier in database
    setActivity(true);
    middlewareCompany.updateCompany(newDatas).then((result) => {
      if (result.statut) {
        navigate('/suppliers');
      } else {
        console.log('erro: ', result.error);
        toast.error("❌ Une erreur est survenue lors de la modification d'un fournisseur");
        btnSubmit.current.disabled = false;
        btnSubmit.current.classList.remove('opacity-30');
      }
      setActivity(false);
    });
  };

  //Render
  return (
    <>
      <section>
        <div>
          <div className="pb-4 border-b mx-14 border-white-admin pt-14">
            <h3 className="font-semibold">Modifier un fournisseur</h3>
          </div>

          <div className="pt-11">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex items-center pb-4 mb-4 space-x-4 border-b mx-14 border-white-admin">
                <label htmlFor="name">Nom du fournisseur</label>
                <input
                  id="name"
                  defaultValue={currentSupplier.name}
                  className={errors.name ? 'w-[318px] input_error' : 'w-[318px] input'}
                  placeholder="Nom du fournisseur"
                  {...register('name')}
                />
                <p className="text-xs text-red-error">{errors.name?.message}</p>
              </div>

              <div className="flex items-center pb-4 mb-4 space-x-4 border-b mx-14 border-white-admin">
                <label htmlFor="siret">Numéro de SIRET</label>
                <input
                  id="siret"
                  defaultValue={currentSupplier.siret}
                  className={errors.siret ? 'w-[318px] input_error' : 'w-[318px] input'}
                  placeholder="N° XXX XXX XXXXX XXX"
                  {...register('siret')}
                />
                <p className="text-xs text-red-500">{errors.siret?.message}</p>
              </div>

              <div className="flex items-center pb-4 mb-4 space-x-4 border-b mx-14 border-white-admin">
                <label htmlFor="addres">Adresse postale</label>
                <input
                  id="addres"
                  defaultValue={currentSupplier.address}
                  className={errors.address ? 'w-[318px] input_error' : 'w-[318px] input'}
                  placeholder="22 rue du général de Gaulle 97236 Lamentin"
                  {...register('address')}
                />
                <p className="text-xs text-red-500">{errors.address?.message}</p>
              </div>

              <div className="flex items-center pb-4 mb-4 space-x-4 border-b mx-14 border-white-admin">
                <label htmlFor="mail">Adresse mail de facturation</label>
                <input
                  id="mail"
                  defaultValue={currentSupplier.mail}
                  className={errors.mail ? 'w-[318px] input_error' : 'w-[318px] input'}
                  placeholder="Fournisseur@exemple.mq"
                  {...register('mail')}
                />
                <p className="text-xs text-red-500">{errors.mail?.message}</p>
              </div>

              <div className="flex items-center pb-4 mb-4 space-x-4 border-b mx-14 border-white-admin">
                <label htmlFor="tel">Téléphone</label>
                <input
                  id="tel"
                  defaultValue={currentSupplier.phone}
                  className={errors.phone ? 'w-[318px] input_error' : 'w-[318px] input'}
                  placeholder="Téléphone"
                  {...register('phone')}
                />
                <p className="text-xs text-red-500">{errors.phone?.message}</p>
              </div>

              <div className="flex items-center justify-between pt-4 mt-16 border-t border-white-admin">
                <Link
                  to="/suppliers"
                  className="border rounded-lg cursor-pointer ml-14 border-white-admin"
                >
                  <button className="px-5 py-3 text-blue-admin">Annuler</button>
                </Link>
                <button
                  ref={btnSubmit}
                  type="submit"
                  className="px-5 py-3 font-semibold text-white rounded-lg cursor-pointer mr-14 bg-blue-admin"
                >
                  Enregister les Modifications
                </button>
              </div>
            </form>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
        />
      </section>
    </>
  );
};

export default EditSuppliers;
