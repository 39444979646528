import { Auth } from 'aws-amplify';
import { useEffect } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const RequireAuth = () => {
  const { auth } = useAuth();
  const location = useLocation();
  useEffect(() => {
    isConnected();
  }, []);

  const isConnected = () => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        localStorage.setItem('isLogged', true);
      })
      .catch((err) => console.log(err));
  };

  return localStorage.getItem('isLogged') ? (
    <Outlet />
  ) : (
    <Navigate
      to="/login"
      state={{ from: location }}
      replace
    />
  );
};

export default RequireAuth;
